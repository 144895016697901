import { render } from "react-dom";

class ChartManager {
  constructor(name, width, height) {
    this.name = name;
    this.width = width;
    this.height = height;
    this.chart = null;
    this.image = null;
    this.update = null;
    this.loading = false;
  }

  get chart() {
    return this._chart;
  }

  set chart(value) {
    this.loading = true;
    this._chart = value;
    this.export();
  }

  export() {
    const div = document.createElement("div");
    div.setAttribute("id", `${this.name}-wrapper`);
    render(this._chart, div);
  }

  convert = async (ref) => {
    if (ref && ref.container) {
      const svg = ref.container.children[0];
      const png = await this.svgToPng(svg, this.name, this.width, this.height);
      this.image = png;
      this.update(this.name, this.image);
      this.loading = false;
    }
  };

  svgToPng = async (svg, name, width, height) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      canvas.setAttribute("id", `${name}-canvas`);
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      // Set background to white
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, width, height);

      const xml = new XMLSerializer().serializeToString(svg);
      const dataUrl = `data:image/svg+xml;utf8,${  encodeURIComponent(xml)}`;
      const img = new Image(width, height);

      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png", 1.0);
        resolve(imageData);
      };

      img.onerror = () => reject();

      img.src = dataUrl;
    });
  };
}

export default ChartManager;
