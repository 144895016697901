import { Mutation } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButton } from "../../../components";
import { ui } from "../../../Constants";
import { MutationSubmit } from "../../../Utils";

import { PERSON } from "../graphql";

import { DELETE_ADDRESS } from "./graphql";

class DeleteAddressModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;

      return { modal: updated };
    });
  };

  render() {
    const { person, address, children, right } = this.props;

    return (
      <>
        <ActionButton
          onClick={this._toggleModal}
          className={`btn-secondary ${right ? "float-right" : ""}`}
          size="sm"
        >
          {children || <>{ui.icon.plus} New</>}
        </ActionButton>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.classNam}
          size="sm"
        >
          <ModalHeader toggle={this._toggleModal}>Remove Address</ModalHeader>
          <ModalBody>Are you sure you want to remove?</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
            <Mutation
              mutation={DELETE_ADDRESS}
              onCompleted={() => {
                Toasts.success("Address deleted");
                this._toggleModal();
              }}
              onError={(error) =>
                Toasts.danger("Unable to delete address", error)
              }
              variables={{ person: person.id, residence: address.id }}
              refetchQueries={[
                {
                  query: PERSON.ADDRESSES_QUERY,
                  variables: { person: person.id },
                },
              ]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  color="danger"
                  label="Delete"
                  loadingLabel="Deleting..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

DeleteAddressModalForm.propTypes = {
  address: PropTypes.shape.isRequired,
};

DeleteAddressModalForm.defaultProps = {};

export default DeleteAddressModalForm;
