import PropTypes from "prop-types";
import { InMemoryCache } from "@apollo/client/cache";
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { ErrorLink } from "@apollo/client/link/error";
import fetch from "unfetch";
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ClientProvider } from "@selfdetermine/react-hooks";
import { Toaster } from "@selfdetermine/react-toasts";
import { AuthenticationProvider } from "@selfdetermine/react-authentication";

import { PermissionsProvider } from "@selfdetermine/react-permissions";
import { Account } from "./Account";
import { Dashboard } from "./Dashboard";
import Layout from "./Layout";
import CheckEmailPage from "./Layout/CheckEmailPage";
import Departments from "./Departments";
import People from "./People";
import Reports from "./People/Reports";
import Tribes from "./Tribes";
import { PageNotFound } from "./Utils";

import { ReactComponent as Logo } from "./asset/logo-cdatribe.svg";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: "include",
  fetch,
});

const errorLink = new ErrorLink(({ networkError }) => {
  if (networkError) {
    if (networkError.result) {
      if (networkError.result.message === "Signature has expired") {
        window.location.reload();
      }
    }
  }
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, httpLink]),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ClientProvider client={client}>
        <AuthenticationProvider
          passwordResetUrl={`${process.env.REACT_APP_API_URL}/password_resets/new`}
          logo={Logo}
        >
          <PermissionsProvider>
            <>
              <Toaster />
              <BrowserRouter>
                <Switch>
                  <Route exact path="/account" component={Account} />
                  <Route path="/people" component={People} />
                  <Route path="/tribes" component={Tribes} />
                  <Route path="/departments" component={Departments} />
                  <Route path="/reports" component={Reports} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route
                    path="/reset-password-via-email"
                    component={CheckEmailPage}
                  />
                  <Redirect from="/" to="/people" />
                  <Route
                    component={() => (
                      <Layout>
                        <PageNotFound />
                      </Layout>
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </>
          </PermissionsProvider>
        </AuthenticationProvider>
      </ClientProvider>
    </ApolloProvider>
  );
};

PermissionsProvider.propTypes = {
  children: PropTypes.element,
};

export default App;
