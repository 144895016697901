import { gql } from "@selfdetermine/graphql";

export const Note = gql`
  fragment Note on Note {
    id
    body
    createdAt
    modifiedAt
    createdBy {
      id
      email
    }
  }
`;

export const Notes = gql`
  fragment Notes on Person {
    notes {
      ...Note
    }
  }
  ${Note}
`;
