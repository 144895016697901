import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";

import ForceDirected from "../../../Charts/ForceDirected";
import {
  DEFAULT_NEW_WINDOW_FEATURES,
  DEFAULT_QUERY_ERROR_POLICY,
} from "../../../Constants";
import {
  checkForErrors,
  convertObjToKeyValue,
  getPersonName,
} from "../../../Utils";

import { PERSON } from "./graphql";

class RelationshipsChart extends Component {
  formatData = (person) => {
    const data = {
      nodes: [],
      links: [],
    };

    if (person.personal_relationships) {
      // Adding nodes (people)
      data.nodes.push({
        id: person.id,
        sex: person.sex,
        personId: person.id,
        name: getPersonName(person),
        membershipNumber: person.membership_number,
      });

      // person's relatives
      person.personal_relationships.map((relationship) =>
        data.nodes.push({
          id: relationship.related.id,
          sex: relationship.related.sex,
          personId: relationship.related.id,
          name: getPersonName(relationship.related),
          membershipNumber: relationship.related.membership_number,
          role: relationship.relatedRole.name,
        })
      );

      // Adding links (relationships)
      person.personal_relationships.map((relationship) =>
        data.links.push({
          source: person.id,
          target: relationship.related.id,
          distance: 200,
        })
      );
    }

    return data;
  };

  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  _handleClick = (p) => {
    if (p.personId)
      window.open(
        `/people/${p.personId}`,
        "_blank",
        convertObjToKeyValue(DEFAULT_NEW_WINDOW_FEATURES)
      );
  };

  render() {
    return (
      <Query
        query={PERSON.RELATIONSHIPS_CHART_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "relationship chart"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          return (
            <ForceDirected
              id="force-directed"
              data={this.formatData(person)}
              handleClick={this._handleClick}
            />
          );
        }}
      </Query>
    );
  }
}

RelationshipsChart.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

RelationshipsChart.defaultProps = {};

export default RelationshipsChart;
