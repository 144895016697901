import { gql } from "@selfdetermine/graphql";

export const CREATE_CCC_MUTATION = gql`
  mutation($person: ID!, $matter: NewMatterInput!) {
    addMatter(person: $person, matter: $matter) {
      id
    }
  }
`;

export const UPDATE_MATTER_MUTATION = gql`
  mutation UpdateMatter($person: ID!, $matter: UpdateMatterInput!) {
    updateMatter(person: $person, matter: $matter) {
      id
    }
  }
`;

export const CREATE_MATTER_NOTE = gql`
  mutation AddNoteToMatter($case: ID!, $body: String!) {
    addNoteToMatter(case: $case, body: $body) {
      id
    }
  }
`;
// Matter
export const MATTER = {
  DETAILS_QUERY: gql`
    query Case($parent: ID!, $id: ID!) {
      viewer {
        matter(parent: $parent, id: $id) {
          id
          outsideIdentifier
          representation
          category
          adverseParty {
            id
            name
          }
          regardingParty {
            id
            name
          }
          opposingCounsel {
            id
            name
          }
          primaryCounsel {
            id
            name
          }
        }
      }
    }
  `,
  LIST_QUERY: gql`
    query Cases($person: ID!, $sort: SortInput) {
      viewer {
        matters(person: $person, sort: $sort) {
          edges {
            node {
              id
              kind
              referringEntity {
                name
                id
              }
              category
              representation
              status
              description
              selfReferral
              createdAt
            }
          }
        }
      }
    }
  `,
};
