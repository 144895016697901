import PropTypes from "prop-types";
import React from "react";
import { Switch, Route } from "react-router-dom";

import { TabPanel } from "../../../../components";
import { ROLES } from "../../../../Constants";
import { withUserRole } from "../../../../Utils";
import ReferralDetails from "../../../../Referrals/ReferralDetails";

import CaseDetail from "../CaseDetail";
import CasesListing from "../CasesListing";
import { EditCase } from "./Edit";

const ReentryCases = ({ person, ...props }) => (
  <TabPanel>
    <Switch>
      <Route
        exact
        path="/people/:person/reentry"
        component={(routeProps) => (
          <CasesListing person={person} {...props} {...routeProps} />
        )}
      />

      <Route
        path="/people/:person/reentry/:id/edit"
        component={(routeProps) => (
          <EditCase
            person={person.id}
            caseId={routeProps.match.params.id}
            {...props}
            {...routeProps}
          />
        )}
      />
      <Route
        path="/people/:person/reentry/:id/referrals/:rid"
        component={(routeProps) => (
          <ReferralDetails person={person} {...props} {...routeProps} />
        )}
      />
      <Route
        path="/people/:person/reentry/:id"
        component={(routeProps) => (
          <CaseDetail person={person} {...props} {...routeProps} />
        )}
      />
    </Switch>
  </TabPanel>
);

ReentryCases.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

ReentryCases.defaultProps = {};

const wrappedWithUserRole = withUserRole(ReentryCases, [
  ROLES.REENTRY,
  ROLES.REFERRED,
]);

export default wrappedWithUserRole;
