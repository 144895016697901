import { gql } from "@selfdetermine/graphql";

export const CREATE_REFERRAL_MUTATION = gql`
  mutation AddReferral(
    $case: ID!
    $referring: ID!
    $referred: ID!
    $description: String
  ) {
    addReferral(
      case: $case
      referring: $referring
      referred: $referred
      description: $description
    ) {
      id
    }
  }
`;

export const UPDATE_REFERRAL_MUTATION = gql`
  mutation UpdateReferral($referral: ReferralInput!) {
    updateReferral(referral: $referral) {
      id
    }
  }
`;

export const REFERRALS_QUERY = gql`
  query Case($parent: ID!, $id: ID!) {
    viewer {
      case(parent: $parent, id: $id) {
        id
        kind
        person {
          id
        }
        referringEntity {
          id
          name
        }
        referrals {
          description
          status
          referred {
            id
            name
          }
          referring {
            id
            name
          }
          createdBy {
            id
            email
          }
          id
        }
      }
    }
  }
`;
