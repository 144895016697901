import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Media, CardBody } from "reactstrap";
import Moment from "react-moment";

import {
  EmptyListing,
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
} from "../../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES } from "../../../Constants";
import { Can, checkForErrors, withUserRole } from "../../../Utils";

import { CREATE_PERSON_NOTE, NOTES_QUERY } from "./graphql";
import NotesModalForm from "./NotesModalForm";

class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getQueryVariables = this.getQueryVariables.bind(this);
  }

  getQueryVariables() {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  }

  toggle() {
    this.setState((state) => ({
      modal: !state.modal,
    }));
  }

  render() {
    return (
      <Query
        query={NOTES_QUERY}
        variables={this.getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(loading, error, data, "notes");
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;
          const { notes } = person;

          return (
            <Panel id="notes">
              <PanelHeader>
                <PanelTitle>Notes</PanelTitle>
                <Can grantRoles={[ROLES.ENROLLMENT]}>
                  <CardActions>
                    <NotesModalForm
                      title="Add Note"
                      mutation={CREATE_PERSON_NOTE}
                      refetchQuery={NOTES_QUERY}
                      refetchVars={this.getQueryVariables}
                      note={{ person: person.id }}
                    />
                  </CardActions>
                </Can>
              </PanelHeader>
              {notes && notes.length ? (
                notes.map((note) => (
                  <CardBody key={note.id}>
                    <Media key={note.id}>
                      <Media body>
                        <small>
                          <i>
                            Created:{" "}
                            {note ? (
                              <Moment
                                date={new Date(note.createdAt)}
                                format="MM/DD/YYYY h:mma"
                              />
                            ) : (
                              "null"
                            )}{" "}
                            {note.createdBy.email
                              ? `by ${note.createdBy.email}`
                              : ""}
                          </i>
                        </small>
                        <p>{note ? note.body : "null"}</p>
                      </Media>
                    </Media>
                  </CardBody>
                ))
              ) : (
                <EmptyListing>No Notes</EmptyListing>
              )}
            </Panel>
          );
        }}
      </Query>
    );
  }
}

Notes.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
  match: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

Notes.defaultProps = {};

const wrappedWithUserRole = withUserRole(Notes, [ROLES.ENROLLMENT, ROLES.SELF]);

export default wrappedWithUserRole;
