import { Mutation } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButton } from "../../../components";
import { ui } from "../../../Constants";
import { MutationSubmit } from "../../../Utils";

class NotesModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      note: { ...props.note },
    };
  }

  _handleChange = (e, n, v) => {
    const { target } = e;
    let name = n;
    let value = v;

    if (!name) {
      name = target.name;
      value = target.type === "checkbox" ? target.checked : target.value;
    }

    const state = this.state.note;
    state[name] = value;
    this.setState(state);
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;
      const newState = Object.assign(prevState, { modal: updated });

      return { newState };
    });
  };

  render() {
    const { note } = this.state;
    const { title, children, right } = this.props;

    return (
      <>
        <ActionButton
          id="modal-btn"
          onClick={this._toggleModal}
          className={`btn-secondary ${right ? "float-right" : ""}`}
          size="sm"
        >
          {children || <>{ui.icon.plus} New</>}
        </ActionButton>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>
            {title || "Add Note"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="body">Note</Label>
              <Input
                type="textarea"
                id="body"
                name="body"
                defaultValue={note.body}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={this.props.mutation}
              variables={note}
              onCompleted={() => {
                Toasts.success("Note added");
                this._toggleModal();
              }}
              onError={(error) => Toasts.danger("Unable to add note", error)}
              refetchQueries={[
                {
                  query: this.props.refetchQuery,
                  variables: this.props.refetchVars(),
                },
              ]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="submit-btn"
                  color="warning"
                  label="Save"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

NotesModalForm.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.string,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    createdBy: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

NotesModalForm.defaultProps = {};

export default NotesModalForm;
