import { Query } from "@apollo/client/react/components";
import React from "react";
import { Row, Col, FormGroup, Label, CardBody } from "reactstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { usePermission } from "@selfdetermine/react-permissions";

import { Button } from "@selfdetermine/react-elements";

import {
  CardActions,
  CheckNo,
  CheckYes,
  DataPair,
  PanelHeader,
  PanelTitle,
} from "../../../components";
import { DEFAULT_SERVER_DATETIME_FORMAT, EN } from "../../../Constants";
import { checkForErrors } from "../../../Utils";

import { CASE } from "./graphql";

import { REENTRY_CASE } from "./Reentry/graphql";
import { MATTER } from "./Matters/graphql";

const NONE = "None Associated";

const getQueryVars = (props) => {
  return {
    parent: props.match.params.person,
    id: props.match.params.id,
  };
};

const EditButton = ({ kind, person, id }) => {
  const canUpdateReentryCase = usePermission("matter.update");
  if (!canUpdateReentryCase && kind === "reentry") return null;
  return (
    <Button
      as={Link}
      to={`/people/${person}/${
        kind === "matter" ? "matters" : "reentry"
      }/${id}/edit`}
    >
      Edit
    </Button>
  );
};

// overview
const Overview = (props) => (
  <>
    <PanelHeader id="overview">
      <PanelTitle>Overview</PanelTitle>
      <CardActions>
        <EditButton
          person={props.match.params.person}
          kind={props._case.kind}
          id={props.match.params.id}
        />
      </CardActions>
    </PanelHeader>
    <CardBody>
      <SharedDetails {...props} />
      {props._case.kind === "reentry" && <ReentryCaseDetails {...props} />}
      {props._case.kind === "matter" && <MatterDetails {...props} />}
    </CardBody>
  </>
);

export const SharedDetails = (props) => (
  <Query
    query={CASE.SHARED_DETAILS}
    variables={getQueryVars(props)}
    fetchPolicy="network-only"
  >
    {({ loading, error, data }) => {
      const loadingOrError = checkForErrors(loading, error, data, "overview");
      if (loadingOrError) {
        return loadingOrError;
      }

      const _case = data.viewer.case;

      return (
        <>
          <Row>
            <Col sm={8}>
              <FormGroup>
                <Label>Description</Label>
                <strong>{_case.description}</strong>
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label>Referring Entity</Label>
                <strong>
                  {_case.referringEntity && _case.referringEntity.name}
                </strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <DataPair>
                <Label>Date Opened On</Label>
                {_case.openedOn && (
                  <strong>
                    <Moment
                      date={_case.openedOn}
                      format="MM/DD/YYYY"
                      parse={DEFAULT_SERVER_DATETIME_FORMAT}
                    />
                  </strong>
                )}
              </DataPair>
            </Col>
            <Col sm={4}>
              <DataPair>
                <Label>Date Created</Label>
                {_case.createdAt && (
                  <strong>
                    <Moment
                      date={_case.createdAt}
                      format="MM/DD/YYYY"
                      parse={DEFAULT_SERVER_DATETIME_FORMAT}
                    />
                  </strong>
                )}
              </DataPair>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label>Date Closed</Label>
                {_case.closedAt && (
                  <strong>
                    <Moment
                      date={_case.closedAt}
                      format="MM/DD/YYYY"
                      parse={DEFAULT_SERVER_DATETIME_FORMAT}
                    />
                  </strong>
                )}
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label>
                  {_case.selfReferral ? (
                    <CheckYes icon="check" />
                  ) : (
                    <CheckNo icon="times" />
                  )}{" "}
                  Self Referral
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </>
      );
    }}
  </Query>
);

export const ReentryCaseDetails = (props) => (
  <Query
    query={REENTRY_CASE.DETAILS_QUERY}
    variables={getQueryVars(props)}
    fetchPolicy="network-only"
  >
    {({ loading, error, data }) => {
      const loadingOrError = checkForErrors(loading, error, data, "overview");
      if (loadingOrError) {
        return loadingOrError;
      }

      const _case = data.viewer.reentryCase;

      return (
        <Row>
          <Col xs={8}>
            <FormGroup>
              <Label>
                {_case.treatmentPastYear ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Inpatient Treatment in the Last Year
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.treatmentYearPlus ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Inpatient Treatment before Last Year
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.attendedOutpatientTherapy ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Attended Outpatient Therapy
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.needDetox ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Needs Detox Services
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.currentMatPatient ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Current MAT Patient
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.violent ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Violent
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.drugAlcoholRelated ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Drug or alcohol related
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                {_case.mentalHealth ? (
                  <CheckYes icon="check" />
                ) : (
                  <CheckNo icon="times" />
                )}{" "}
                Mental Health
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4}>
            <DataPair>
              <Label>Release Date</Label>
              {_case.releasedAt && (
                <strong>
                  <Moment
                    date={_case.releasedAt}
                    format="MM/DD/YYYY"
                    parse={DEFAULT_SERVER_DATETIME_FORMAT}
                  />
                </strong>
              )}
            </DataPair>
          </Col>
        </Row>
      );
    }}
  </Query>
);

export const MatterDetails = (props) => (
  <Query
    query={MATTER.DETAILS_QUERY}
    variables={getQueryVars(props)}
    fetchPolicy="network-only"
  >
    {({ loading, error, data }) => {
      const loadingOrError = checkForErrors(loading, error, data, "overview");
      if (loadingOrError) {
        return loadingOrError;
      }

      const { matter } = data.viewer;

      return (
        <>
          <Row>
            <Col sm={3}>
              <FormGroup>
                <Label>Adverse Party</Label>
                <strong>
                  {matter.adverseParty ? (
                    <Link to={`/departments/${matter.adverseParty.id}`}>
                      {matter.adverseParty.name}
                    </Link>
                  ) : (
                    NONE
                  )}
                </strong>
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label>Regarding Party</Label>
                <strong>
                  {matter.regardingParty ? (
                    <Link to={`/departments/${matter.regardingParty.id}`}>
                      {matter.regardingParty.name}
                    </Link>
                  ) : (
                    NONE
                  )}
                </strong>
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label>Primary Counsel</Label>
                <strong id="primary-counsel-field-link">
                  {matter.primaryCounsel ? (
                    <Link to={`/departments/${matter.primaryCounsel.id}`}>
                      {matter.primaryCounsel.name}
                    </Link>
                  ) : (
                    NONE
                  )}
                </strong>
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label>Opposing Counsel</Label>
                <strong>
                  {matter.opposingCounsel ? (
                    <Link to={`/departments/${matter.opposingCounsel.id}`}>
                      {matter.opposingCounsel.name}
                    </Link>
                  ) : (
                    NONE
                  )}
                </strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Label>Case Number</Label>
              <strong>
                {matter.outsideIdentifier ? matter.outsideIdentifier : NONE}
              </strong>
            </Col>
            <Col sm={3}>
              <Label>Representation</Label>
              <strong>
                {matter.representation
                  ? EN.MATTER.REPRESENTATION_OPTIONS[matter.representation]
                  : NONE}
              </strong>
            </Col>
            <Col sm={3}>
              <Label>Category</Label>
              <strong>
                {matter.category
                  ? EN.MATTER.CATEGORY_OPTIONS[matter.category]
                  : NONE}
              </strong>
            </Col>
          </Row>
        </>
      );
    }}
  </Query>
);

export default Overview;
