import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, FormGroup, Label, CardBody } from "reactstrap";
import Moment from "react-moment";

import {
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
} from "../../../components";
import {
  DEFAULT_QUERY_ERROR_POLICY,
  DEFAULT_SERVER_DATETIME_FORMAT,
  ROLES,
} from "../../../Constants";
import { Can, checkForErrors } from "../../../Utils";

import { PERSON } from "../graphql";

import MembershipModalForm from "./MembershipModalForm";

class Membership extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.MEMBERSHIP_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "membership info"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          return (
            <Panel id="membership">
              <PanelHeader>
                <PanelTitle>Membership</PanelTitle>
                <Can grantRoles={[ROLES.ENROLLMENT]}>
                  <CardActions>
                    <MembershipModalForm
                      title="Edit Membership Information"
                      person={person}
                    >
                      Edit
                    </MembershipModalForm>
                  </CardActions>
                </Can>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label for="member_status">Membership Status</Label>
                      <strong id="person-membership-status-field">
                        {person.memberStatus}
                      </strong>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label for="membership_number">Enrollment Number</Label>
                      <strong>{person.membershipNumber}</strong>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label for="resolution_number">Resolution Number</Label>
                      <strong>{person.resolutionNumber}</strong>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label for="enrolled_at">Date of Enrollment</Label>
                      {person.enrolledAt ? (
                        <strong>
                          <Moment
                            date={person.enrolledAt}
                            format="MM/DD/YYYY"
                            parse={DEFAULT_SERVER_DATETIME_FORMAT}
                          />
                        </strong>
                      ) : (
                        <strong />
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label for="applied_at">Date of Application</Label>
                      {person.appliedAt ? (
                        <strong>
                          <Moment
                            date={person.appliedAt}
                            format="MM/DD/YYYY"
                            parse={DEFAULT_SERVER_DATETIME_FORMAT}
                          />
                        </strong>
                      ) : (
                        <strong />
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label for="card_issued_at">Card Issued</Label>
                      {person.cardIssuedAt ? (
                        <strong>
                          <Moment
                            date={person.cardIssuedAt}
                            format="MM/DD/YYYY"
                            parse={DEFAULT_SERVER_DATETIME_FORMAT}
                          />
                        </strong>
                      ) : (
                        <strong />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

Membership.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

Membership.defaultProps = {};

export default Membership;
