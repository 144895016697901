import classNames from "classnames";
import compose from "lodash.flowright";
import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";

import AccountDropdown from "../Account/AccountDropdown";
import CustomBarChart from "../Charts/CustomBarChart";
import CustomPieChart from "../Charts/CustomPieChart";
import {
  Icon,
  Page,
  PageHeader,
  PageHeaderSection,
  PageTitle,
  PageContent,
} from "../components";

import { ENROLLEDAGESQUERY, ENROLLEDMALEFEMALEQUERY } from "./graphql";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genderChartType: "bar",
      ageChartType: "bar",
    };
  }

  onGenderClick = (type) => this.setState({ genderChartType: type });

  onAgeClick = (type) => this.setState({ ageChartType: type });

  render() {
    let gender = {};
    let age = {};

    if (this.props.gender) {
      gender = {
        total: this.props.gender.analytics.total,
        items: [
          { label: "Female", value: this.props.gender.analytics.females },
          { label: "Male", value: this.props.gender.analytics.males },
        ],
      };
    }

    if (this.props.age) {
      age = {
        total: this.props.age.analytics.total,
        items: [
          { label: "under 18", value: this.props.age.analytics.under_18 },
          {
            label: "between 18 & 35",
            value: this.props.age.analytics.between_18_35,
          },
          {
            label: "between 35 & 59",
            value: this.props.age.analytics.between_35_59,
          },
          { label: "60+", value: this.props.age.analytics.over_60 },
        ],
      };
    }

    const { ageChartType, genderChartType } = this.state;

    let ageChart;
    let genderChart;
    if (genderChartType === "bar") {
      genderChart = (
        <CustomBarChart
          data={gender.items}
          total={gender.total}
          loading={this.props.ageLoading}
          error={this.props.ageError}
        />
      );
    } else {
      genderChart = (
        <CustomPieChart
          data={gender.items}
          total={gender.total}
          loading={this.props.ageLoading}
          error={this.props.ageError}
        />
      );
    }

    if (ageChartType === "bar") {
      ageChart = (
        <CustomBarChart
          data={age.items}
          total={age.total}
          loading={this.props.ageLoading}
          error={this.props.ageError}
        />
      );
    } else {
      ageChart = (
        <CustomPieChart
          data={age.items}
          total={age.total}
          loading={this.props.ageLoading}
          error={this.props.ageError}
        />
      );
    }

    return (
      <Page>
        <PageHeader>
          <PageHeaderSection />
          <PageHeaderSection>
            <PageTitle>Dashboard</PageTitle>
          </PageHeaderSection>
          <PageHeaderSection>
            <AccountDropdown />
          </PageHeaderSection>
        </PageHeader>
        <PageContent>
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-one-third-desktop is-half-tablet">
                  <div className="card">
                    <header className="card-header">
                      <p className="card-header-title">Enrolled by Gender</p>
                      {/* For styling reasons we need to use an anchor, but we properly tell screen readers to treat it like a button */}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        role="button"
                        tabIndex="0"
                        className={classNames(
                          "card-header-icon",
                          genderChartType === "bar"
                            ? "has-text-primary"
                            : "has-text-muted"
                        )}
                        onClick={() => this.onGenderClick("bar")}
                        onKeyDown={() => this.onGenderClick("bar")}
                        aria-label="Display as bar graph"
                      >
                        <Icon icon="chart-bar" />
                      </a>
                      {/* For styling reasons we need to use an anchor, but we properly tell screen readers to treat it like a button */}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        role="button"
                        tabIndex="0"
                        className={classNames(
                          "card-header-icon",
                          genderChartType === "pie"
                            ? "has-text-primary"
                            : "has-text-muted"
                        )}
                        onClick={() => this.onGenderClick("pie")}
                        onKeyDown={() => this.onGenderClick("pie")}
                        aria-label="Display as pie chart"
                      >
                        <Icon icon="chart-pie" />
                      </a>
                    </header>
                    <div className="card-content">{genderChart}</div>
                  </div>
                </div>
                <div className="column is-one-third-desktop is-half-tablet">
                  <div className="card">
                    <header className="card-header">
                      <p className="card-header-title">
                        Enrolled by Age Groups
                      </p>
                      {/* For styling reasons we need to use an anchor, but we properly tell screen readers to treat it like a button */}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        role="button"
                        className={classNames(
                          "card-header-icon",
                          ageChartType === "bar"
                            ? "has-text-primary"
                            : "has-text-muted"
                        )}
                        onClick={() => this.onAgeClick("bar")}
                        onKeyDown={() => this.onAgeClick("bar")}
                        aria-label="Display as bar graph"
                        tabIndex={0}
                      >
                        <Icon icon="chart-bar" />
                      </a>
                      {/* For styling reasons we need to use an anchor, but we properly tell screen readers to treat it like a button */}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        role="button"
                        className={classNames(
                          "card-header-icon",
                          ageChartType === "pie"
                            ? "has-text-primary"
                            : "has-text-muted"
                        )}
                        onClick={() => this.onAgeClick("pie")}
                        onKeyDown={() => this.onAgeClick("pie")}
                        aria-label="Display as pie chart"
                        tabIndex={0}
                      >
                        <Icon icon="chart-pie" />
                      </a>
                    </header>
                    <div className="card-content">{ageChart}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </PageContent>
      </Page>
    );
  }
}

const EnrolledMaleFemale = graphql(ENROLLEDMALEFEMALEQUERY, {
  props: ({ data }) => ({
    genderloading: data.loading,
    genderError: data.error,
    gender: data.viewer,
  }),
});

const EnrolledAges = graphql(ENROLLEDAGESQUERY, {
  props: ({ data }) => ({
    ageLoading: data.loading,
    ageError: data.error,
    age: data.viewer,
  }),
});

export default compose(EnrolledMaleFemale, EnrolledAges)(Dashboard);
