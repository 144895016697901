import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";

import {
  PageFilter,
  PageActions,
  PageColumn,
  PageContent,
  PageFooter,
  EmptyPeople,
} from "../components";
import { ROLES, DEFAULT_QUERY_ERROR_POLICY } from "../Constants";
import { PEOPLE } from "./graphql";
import { Can, checkForErrors, Glossary, Loading, PageCount } from "../Utils";

import TableList from "./TableList";
import Group from "./Group";
import Grid from "./Grid";
import Filter from "./Filter";

class List extends Component {
  _loadMore = (fetchMore, endCursor) => {
    fetchMore({
      variables: {
        end: endCursor,
      },
      updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
        const previousSearch = previousResult.viewer.people || {};
        const currentSearch = fetchMoreResult.viewer.people || {};
        const previousNodes = previousSearch.edges || [];
        const currentNodes = currentSearch.edges || [];

        const viewer = {
          ...previousResult,
          viewer: {
            __typename: previousResult.viewer.__typename,
            people: {
              ...previousSearch,
              edges: [...previousNodes, ...currentNodes],
              pageInfo: currentSearch.pageInfo,
            },
          },
        };
        return viewer;
      },
    });
  };

  render() {
    const {
      filters,
      listType,
      open,
      handleChangeFilters,
      getQueryVariables,
      sort,
      handleSort,
      handleGlossary,
      setRefetch,
    } = this.props;

    return (
      <>
        <Can
          grantRoles={[
            ROLES.ENROLLMENT,
            ROLES.MEMBERSHIP,
            ROLES.REENTRY,
            ROLES.CIVIL_ATTORNEY,
          ]}
        >
          <PageFilter open={open}>
            <Filter
              {...this.props}
              filters={filters}
              onChangeFilters={handleChangeFilters}
            />
          </PageFilter>
        </Can>
        <PageColumn>
          <Query
            query={PEOPLE.QUERY}
            variables={getQueryVariables()}
            fetchPolicy="network-only"
            errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              setRefetch(refetch);

              const loadingOrError = checkForErrors(
                loading,
                error,
                data,
                "people listing"
              );
              if (loadingOrError) {
                return loadingOrError;
              }

              if (data.viewer.people.totalCount < 1)
                return (
                  <EmptyPeople id="empty-people" {...this.props}>
                    No people
                  </EmptyPeople>
                );

              const people = data.viewer.people.edges.map((n) => n.node);
              const { totalCount, pageInfo } = data.viewer.people;
              const { endCursor, hasNextPage } = pageInfo;

              return (
                <>
                  <PageActions className="justify-content-between">
                    <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                      <PageCount count={totalCount} />
                    </div>
                  </PageActions>
                  <PageContent>
                    <InfiniteScroll
                      hasMore={hasNextPage}
                      loadMore={() => this._loadMore(fetchMore, endCursor)}
                      loader={
                        <Loading key={Math.random().toString(36).substr(2, 5)}>
                          Loading more people...
                        </Loading>
                      }
                      useWindow={false}
                      threshold={2000}
                    >
                      <div>
                        {listType === "table" ? (
                          <TableList
                            People={people}
                            {...this.props}
                            sortBy={sort.column}
                            direction={sort.direction}
                            handleSort={handleSort}
                          />
                        ) : null}
                        {listType === "item" ? (
                          <Group People={people} {...this.props} flush />
                        ) : null}
                        {listType === "grid" ? (
                          <Grid People={people} {...this.props} />
                        ) : null}
                      </div>
                    </InfiniteScroll>
                  </PageContent>
                </>
              );
            }}
          </Query>
          <PageFooter>
            <Glossary
              id="people-glossary"
              activeLetter={filters.glossary}
              handleChange={handleGlossary}
              size="sm"
            />
          </PageFooter>
        </PageColumn>
      </>
    );
  }
}

List.propTypes = {
  listType: PropTypes.string.isRequired,
  sort: PropTypes.shape.isRequired,
  filters: PropTypes.shape.isRequired,
  open: PropTypes.bool.isRequired,
  handleChangeFilters: PropTypes.func.isRequired,
  getQueryVariables: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  handleGlossary: PropTypes.func.isRequired,
  setRefetch: PropTypes.func.isRequired,
};

export default List;
