import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import {
  ROLES,
  DEFAULT_SERVER_DATE_FORMAT,
  DEFAULT_SERVER_DATETIME_FORMAT,
} from "../../../Constants";
import { Can, RequiredField, SocialSecurityInput } from "../../../Utils";

class GeneralInput extends Component {
  _handleChange = (e) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.props.handleChange("person", name, value);
  };

  render() {
    const { person } = this.props;

    const serviceStartedAt = person.serviceStartedAt
      ? moment(person.serviceStartedAt, DEFAULT_SERVER_DATE_FORMAT).format(
          "YYYY-MM-DD"
        )
      : null;

    const serviceEndedAt = person.serviceEndedAt
      ? moment(person.serviceEndedAt, DEFAULT_SERVER_DATE_FORMAT).format(
          "YYYY-MM-DD"
        )
      : null;

    const bornAt = person.bornAt
      ? moment(person.bornAt, DEFAULT_SERVER_DATE_FORMAT).format("YYYY-MM-DD")
      : null;

    const deceasedAt = person.deceasedAt
      ? moment(person.deceasedAt, DEFAULT_SERVER_DATETIME_FORMAT).format(
          "YYYY-MM-DD"
        )
      : null;

    return (
      <>
        <Row>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="firstName">
                  First Name <RequiredField />
                </Label>
                <Input
                  required
                  type="text"
                  name="firstName"
                  id="first_name"
                  placeholder="Enter first name"
                  defaultValue={person.firstName}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="middleName">Middle Name</Label>
                <Input
                  type="text"
                  name="middleName"
                  id="middle_name"
                  placeholder="Enter middle name"
                  defaultValue={person.middleName}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="lastName">
                  Last Name <RequiredField />
                </Label>
                <Input
                  required
                  type="text"
                  name="lastName"
                  id="last_name"
                  placeholder="Enter last name"
                  defaultValue={person.lastName}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
        </Row>
        <Row>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="suffix">Suffix</Label>
                <Input
                  type="text"
                  name="suffix"
                  id="suffix"
                  placeholder="Enter suffix"
                  defaultValue={person.suffix}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="maidenName">Maiden Name</Label>
                <Input
                  type="text"
                  name="maidenName"
                  id="maiden_name"
                  placeholder="Enter maiden name"
                  defaultValue={person.maidenName}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="ssn">Social Security</Label>
                <SocialSecurityInput
                  type="text"
                  name="ssn"
                  id="ssn"
                  defaultValue={person.ssn}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
        </Row>
        <Row>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={4}>
              <FormGroup>
                <Label for="maritalStatus">Marital Status</Label>
                <Input
                  type="select"
                  name="maritalStatus"
                  id="marital_status"
                  placeholder="Enter first name"
                  defaultValue={person.maritalStatus}
                  onChange={(e) => this._handleChange(e)}
                >
                  <option value="">Please Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="separated">Separated</option>
                </Input>
              </FormGroup>
            </Col>
          </Can>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <Col sm={6} md={8}>
              <FormGroup>
                <Label for="headOfHousehold">Head of Household</Label>
                <CustomInput
                  type="checkbox"
                  name="headOfHousehold"
                  id="head_of_household"
                  label="Is Head of Household?"
                  checked={!!person.headOfHousehold}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can>
          {/* Comment out for now, keep dedicated MRN form */}
          {/* <Can grantRoles={[ROLES.REENTRY]}>
            <Col>
              <FormGroup>
                <Label for={"medicalRecordNumber"}>Medical Record Number</Label>
                <Input
                  type="text"
                  name="medicalRecordNumber"
                  id="medicalRecordNumber"
                  placeholder="Enter Medical Record Number"
                  defaultValue={person.medicalRecordNumber}
                  onChange={e => this._handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Can> */}
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="bornAt">
                Birth Date <RequiredField />
              </Label>
              <Input
                type="date"
                name="bornAt"
                id="bornAt"
                placeholder="Enter birth date"
                defaultValue={bornAt}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="birthplace">Birth Place</Label>
              <Input
                type="text"
                name="birthplace"
                id="birthplace"
                placeholder="Enter birth place"
                defaultValue={person.birthplace}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="deceasedAt">Deceased Date</Label>
              <Input
                type="date"
                name="deceasedAt"
                id="deceasedAt"
                placeholder="Enter first name"
                defaultValue={deceasedAt}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label for="height">Height</Label>
              <Input
                type="text"
                name="height"
                id="height"
                placeholder="Enter height"
                defaultValue={person.height}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label for="weight">Weight</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="weight"
                  id="weight"
                  placeholder="Enter weight"
                  defaultValue={person.weight}
                  onChange={(e) => this._handleChange(e)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>lbs.</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label for="sex">
                Sex <RequiredField />
              </Label>
              <Input
                required
                type="select"
                name="sex"
                id="sex"
                defaultValue={person.sex}
                onChange={(e) => this._handleChange(e)}
              >
                <option value="">Please Select</option>
                <option value="female">female</option>
                <option value="male">male</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label for="hairColor">Hair Color</Label>
              <Input
                type="text"
                name="hairColor"
                id="hair_color"
                placeholder="Enter hair color"
                defaultValue={person.hairColor}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label for="eyeColor">Eye Color</Label>
              <Input
                type="text"
                name="eyeColor"
                id="eye_color"
                placeholder="Enter eye color"
                defaultValue={person.eyeColor}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="veteran">Veteran</Label>
              <CustomInput
                type="checkbox"
                name="veteran"
                id="veteran"
                label="Is a Veteran?"
                checked={!!person.veteran}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="serviceStartedAt">Service Started</Label>
              <Input
                type="date"
                name="serviceStartedAt"
                id="service_started_at"
                placeholder="YYYY-MM-DD"
                defaultValue={serviceStartedAt}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="serviceEndedAt">Service Ended</Label>
              <Input
                type="date"
                name="serviceEndedAt"
                id="service_ended_at"
                placeholder="YYYY-MM-DD"
                defaultValue={serviceEndedAt}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="branchOfService">Branch of Service</Label>
              <Input
                type="select"
                name="branchOfService"
                id="branch_of_service"
                defaultValue={person.branchOfService}
                onChange={(e) => this._handleChange(e)}
              >
                <option value="" aria-label="default" />
                <option value="1">Army</option>
                <option value="2">Navy</option>
                <option value="3">Marines</option>
                <option value="4">Coast Guard</option>
                <option value="5">Air Force</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

GeneralInput.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

GeneralInput.defaultProps = {};

export default GeneralInput;
