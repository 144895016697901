import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import logo from "../../../asset/logo-cdatribe.png";
import { TRIBE_NAME } from "../../../Constants";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "stretch",
    padding: 50,
  },
  logoColumn: {
    flexDirection: "column",
    width: "25%",
  },
  detailColumn: {
    flexDirection: "column",
    width: "50%",
    textAlign: "center",
  },
  offsetColumn: {
    flexDirection: "column",
    width: "25%",
  },
  logo: {
    width: 100,
    height: 100,
  },
  tribe: {
    fontSize: 20,
    fontWeight: 700,
    paddingBottom: 10,
  },
  department: {
    fontSize: 13,
    fontWeight: "bold",
    paddingBottom: 10,
  },
  address: {
    fontSize: 10,
  },
});

export default () => (
  <View style={styles.container}>
    <View style={styles.logoColumn}>
      <Image style={styles.logo} src={logo} />
    </View>
    <View style={styles.detailColumn}>
      <Text style={styles.tribe}>{TRIBE_NAME} Tribe</Text>
      <Text style={styles.department}>Enrollment Department</Text>
      <Text style={styles.address}>850 A Street</Text>
      <Text style={styles.address}>PO Box 408</Text>
      <Text style={styles.address}>Plummer, ID 83851</Text>
      <Text style={styles.address}>208-686-0800 FAX 208-686-5323</Text>
    </View>
    <View style={styles.offsetColumn}>
      <Text>&nbsp;</Text>
    </View>
  </View>
);
