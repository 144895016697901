import React from "react";
import { TableSort } from "../../../../Utils/index";
import { StatusHeader } from "../../../../components/index";
import { MattersRow } from "./MattersRow";

export const MattersTable = ({
  matters,
  sortBy,
  direction,
  handleSort,
  ...props
}) => {
  return (
    <div className="listing">
      <div className="listing__header">
        <div className="listing__header-label">
          <TableSort
            label="Case Date"
            name="created_at"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
        <div className="listing__header-label">
          <TableSort
            label="Type of Issue"
            name="category"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
        <div className="listing__header-label">
          <TableSort
            label="Type of Assistance"
            name="representation"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
        <div className="listing__header-label">
          <TableSort
            label="Description"
            name="description"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
        <StatusHeader className="listing__header-label">
          <TableSort
            label="Status"
            name="status"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </StatusHeader>
      </div>
      {matters.map((matter) => (
        <MattersRow key={matter.id} person={props.person} matter={matter} />
      ))}
    </div>
  );
};
