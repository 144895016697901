import React from "react";
import { Cell, PieChart, Pie, ResponsiveContainer } from "recharts";

const COLORS = ["#98abc5", "#8a89a6", "#7b6888", "#6b486b"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  label,
}) => {
  if (percent === 0) {
    return null;
  }

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={x}
        y={y - 18}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${label}:`}
      </text>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

const CustomPieChart = ({ data, total }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      <ResponsiveContainer aspect={1}>
        <PieChart>
          <Pie
            dataKey="value"
            data={data || null}
            labelLine={false}
            label={renderCustomizedLabel}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                fill={COLORS[index % COLORS.length]}
                key={`cell-${entry.id}`}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <h5 className="center-text">{`Total: ${total}`}</h5>
    </>
  );
};

export default CustomPieChart;
