import { gql } from "@selfdetermine/graphql";

export const DEPARTMENT = {
  QUERY: gql`
    query Department($id: ID!) {
      viewer {
        department(id: $id) {
          id
          name
          city
          phoneNumber
          state
          street1
          street2
          street3
          zipCode
        }
      }
    }
  `,
};
