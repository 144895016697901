import { gql } from "@selfdetermine/graphql";

// Analytics
export const ENROLLEDMALEFEMALEQUERY = gql`
  query EnrolledMaleFemale {
    viewer {
      analytics {
        total: nEnrolled
        males: nEnrolledMales
        females: nEnrolledFemales
      }
    }
  }
`;

export const ENROLLEDAGESQUERY = gql`
  query EnrolledAges {
    viewer {
      analytics {
        total: nEnrolled
        under_18: nEnrolledUnder18
        between_18_35: nEnrolledBetween1835
        between_35_59: nEnrolledBetween3559
        over_60: nEnrolledOver60
      }
    }
  }
`;
