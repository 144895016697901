import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Form } from "@selfdetermine/react-forms";
import { Toasts } from "@selfdetermine/react-toasts";
import { usePermission } from "@selfdetermine/react-permissions";
import { useMutation, useQuery } from "@selfdetermine/react-hooks";
import { Columns, Button, Level } from "@selfdetermine/react-elements";
import { UPDATE_REENTRY_CASE_MUTATION, REENTRY_CASE } from "./graphql";
import { DEPARTMENTS } from "../../../../Departments/graphql";

const useUpdateReentryCaseMutation = (person, caseId) => {
  const [mutate, meta] = useMutation(UPDATE_REENTRY_CASE_MUTATION, {
    refetchQueries: [
      {
        query: REENTRY_CASE.DETAILS_QUERY,
        variables: {
          parent: person,
          id: caseId,
        },
      },
    ],
  });
  const updateReentryCase = (body) =>
    mutate({
      variables: {
        person,
        reentryCase: {
          id: caseId,
          ...body,
        },
      },
    });

  return [updateReentryCase, meta];
};

const useReentryCaseDetails = (parent, id) => {
  const { data, loading, error } = useQuery(REENTRY_CASE.DETAILS_QUERY, {
    variables: {
      parent,
      id,
    },
  });
  const {
    viewer: { reentryCase },
  } = data || {
    viewer: {
      reentryCase: null,
    },
  };
  return [reentryCase, { loading, error }];
};

const useDepartmentOptions = () => {
  const { data, loading, error } = useQuery(DEPARTMENTS.SELECT_QUERY);
  const {
    viewer: { departments },
  } = data || { viewer: { departments: [] } };
  return [
    departments,
    {
      loading,
      error,
    },
  ];
};

export const EditCase = (props) => {
  const { person, caseId, history } = props;

  const [updateCase, updateStatus] = useUpdateReentryCaseMutation(
    person,
    caseId
  );
  const [details, detailsStatus] = useReentryCaseDetails(person, caseId);
  const [departmentOptions, departmentOptionsStatus] = useDepartmentOptions();

  const canEdit = usePermission("matter.update");

  const isLoading =
    detailsStatus.loading ||
    updateStatus.loading ||
    departmentOptionsStatus.loading;

  React.useEffect(() => {
    const err =
      detailsStatus.error ||
      updateStatus.error ||
      departmentOptionsStatus.error;
    if (err) {
      Toasts.danger(err.message);
    }
  }, [detailsStatus, updateStatus, departmentOptionsStatus]);

  if (!canEdit) return <Redirect to={`/people/${person}/reentry/${caseId}`} />;
  if (isLoading) return null;
  const referringEntity = details?.referringEntity || {};

  if (updateStatus.data) {
    Toasts.success("Case successfully updated");
    return <Redirect to={`/people/${person}/reentry/${caseId}`} />;
  }
  const defaultValues = {
    attendedOutpatientTherapy: details.attendedOutpatientTherapy,
    currentMatPatient: details.currentMatPatient,
    description: details.description,
    mentalHealth: details.mentalHealth,
    needDetox: details.needDetox,
    drugAlcoholRelated: details.drugAlcoholRelated,
    selfReferral: details.selfReferral,
    violent: details.violent,
    treatmentPastYear: details.treatmentPastYear,
    treatmentYearPlus: details.treatmentYearPlus,
    referringEntity: referringEntity?.id !== "" ? referringEntity.id : "None",
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={updateCase}
      loading={isLoading}
    >
      <Form.Select
        name="referringEntity"
        label="Referring"
        loading={departmentOptionsStatus.loading}
        options={[
          {
            label: "<None>",
            value: "None",
          },
          ...departmentOptions,
        ]}
      />
      <Columns>
        <Columns.Column>
          <Form.Checkbox name="selfReferral" label="Self Referral?" />
          <Form.Checkbox
            name="treatmentPastYear"
            label="Inpatient Treatment in the Last Year"
          />
          <Form.Checkbox
            name="treatmentYearPlus"
            label="Inpatient Treatment before Last Year"
          />
          <Form.Checkbox
            name="attendedOutpatientTherapy"
            label="Attended Outpatient Therapy?"
          />
          <Form.Checkbox name="needDetox" label="Needs Detox Services" />
          <Form.Checkbox
            name="currentMatPatient"
            label="Current MAT Patient?"
          />
          <Form.Checkbox name="violent" label="Violent" />
          <Form.Checkbox
            name="drugAlcoholRelated"
            label="Drug or Alcohol related"
          />
          <Form.Checkbox name="mentalHealth" label="Mental Health" />
        </Columns.Column>
      </Columns>
      <Form.Textarea
        required
        name="description"
        label="Description"
        placeholder="Description"
      />

      <Level>
        <Level.Right>
          <Level.Item>
            <Button
              type="cancel"
              onClick={() =>
                history.push(`/people/${person}/reentry/${caseId}`)
              }
            >
              Cancel
            </Button>
          </Level.Item>
          <Level.Item>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </Level.Item>
        </Level.Right>
      </Level>
    </Form>
  );
};

EditCase.propTypes = {
  person: PropTypes.any,
  caseId: PropTypes.number,
  history: PropTypes.any,
};
