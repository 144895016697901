/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import { Query } from "@apollo/client/react/components";
import bgAncestry from "../../../asset/bg_ancestry_chart.png";
import tribalSeal from "../../../asset/logo-cdatribe.png";
import { PageReports } from "../../components";
import { PERSON } from "../Family/graphql";
import { DEFAULT_QUERY_ERROR_POLICY } from "../../../Constants";
import { checkForErrors } from "../../../Utils";

/*------------------------------------------------------------------------------
 * React-pdf Styles
 *-------------------------------------------------------------------------------
 *
 * https://react-pdf.org/
 *
 * Since we are dealing with print we will use point (pt) units instead of
 * pixels, ems, rems, etc.
 *
 * To place each person at a specific point on the chart a grid is used. This
 * starts from Generation 1 (g1) at the farthest left, and Placement 1 (p1) at
 * the top. This pattern continues, doubling in Placements each generation.
 *
 * Because of the expononentiality this system would probably only work for
 * a few additional generations before an algorithm needs to be used.
 *
 * Examples:
 * g1p1 = Generation 1, Placement 1
 * g3p4 = Generation 3, Placement 4
 *
 * dStyles are for the generated PDF document
 * vStyles are for the generated PDF viewer
 * pStyles are for the person/position component
 *
 */

const dStyles = StyleSheet.create({
  page: { fontSize: "10pt" },
  background: { height: "100vh", width: "100vw", position: "absolute" },
  tribalSeal: { height: "60pt", width: "60pt" },
  branding: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "32pt",
    left: "32pt",
  },
  head: { position: "absolute", top: "40pt", left: "92pt" },
  h1: { fontSize: "12pt", fontWeight: "bold", margin: "2pt 0" },
  h2: { fontSize: "8pt", fontWeight: "bold", margin: "2pt 0" },
  legend: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "740pt",
    left: "32pt",
  },
});

const vStyles = {
  width: "100%",
  height: "100%",
  border: 0,
  maxHeight: "100%",
  position: "absolute",
};

const pStyles = {
  line1: { fontSize: "8pt", fontStyle: "oblique", margin: "1pt 0pt" },
  line2: { fontSize: "6pt", fontStyle: "oblique", margin: "2pt 0pt" },
  line3: { fontSize: "6pt", fontStyle: "oblique", margin: "1pt 0pt" },
  g1p1: { position: "absolute", top: "384pt", left: "28pt" },
  g2p1: { position: "absolute", top: "188pt", left: "28pt" },
  g2p2: { position: "absolute", top: "574pt", left: "28pt" },
  g3p1: { position: "absolute", top: "92pt", left: "174pt" },
  g3p2: { position: "absolute", top: "284pt", left: "174pt" },
  g3p3: { position: "absolute", top: "476pt", left: "174pt" },
  g3p4: { position: "absolute", top: "668pt", left: "174pt" },
  g4p1: { position: "absolute", top: "44pt", left: "320pt" },
  g4p2: { position: "absolute", top: "140pt", left: "320pt" },
  g4p3: { position: "absolute", top: "236pt", left: "320pt" },
  g4p4: { position: "absolute", top: "332pt", left: "320pt" },
  g4p5: { position: "absolute", top: "428pt", left: "320pt" },
  g4p6: { position: "absolute", top: "524pt", left: "320pt" },
  g4p7: { position: "absolute", top: "620pt", left: "320pt" },
  g4p8: { position: "absolute", top: "716pt", left: "320pt" },
  g5p1: { position: "absolute", top: "20pt", left: "466pt" },
  g5p2: { position: "absolute", top: "68pt", left: "466pt" },
  g5p3: { position: "absolute", top: "116pt", left: "466pt" },
  g5p4: { position: "absolute", top: "164pt", left: "466pt" },
  g5p5: { position: "absolute", top: "212pt", left: "466pt" },
  g5p6: { position: "absolute", top: "260pt", left: "466pt" },
  g5p7: { position: "absolute", top: "308pt", left: "466pt" },
  g5p8: { position: "absolute", top: "356pt", left: "466pt" },
  g5p9: { position: "absolute", top: "404pt", left: "466pt" },
  g5p10: { position: "absolute", top: "452pt", left: "466pt" },
  g5p11: { position: "absolute", top: "500pt", left: "466pt" },
  g5p12: { position: "absolute", top: "548pt", left: "466pt" },
  g5p13: { position: "absolute", top: "596pt", left: "466pt" },
  g5p14: { position: "absolute", top: "644pt", left: "466pt" },
  g5p15: { position: "absolute", top: "692pt", left: "466pt" },
  g5p16: { position: "absolute", top: "740pt", left: "466pt" },
};

/*------------------------------------------------------------------------------
 * Person Component
 *-------------------------------------------------------------------------------
 *
 * Build the reusable Person() component to make it easier to pass in
 * unique placement styles and person information.
 *
 */

export const Person = (props) => {
  const { person, placement, relation } = props;

  return (
    <View style={placement}>
      <Link
        src={`https://www.selfdetermine.net/people/${person.id}/ancestry-chart`}
      >
        <Text style={pStyles.line1}>
          {person?.firstName ? person.firstName : null}{" "}
          {person?.lastName ? person.lastName : null}
        </Text>
      </Link>
      <Text style={pStyles.line2}>
        Tribe{" "}
        {person?.enrolledBloodQuantum ? person.enrolledBloodQuantum : null},
        Other {person?.otherBloodQuantum ? person.otherBloodQuantum : null},
        Total {person?.totalBloodQuantum ? person.totalBloodQuantum : null}
      </Text>
      <Text style={pStyles.line3}>{relation}</Text>
    </View>
  );
};

Person.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
  }),
  placement: PropTypes.shape({
    placement: PropTypes.string,
  }),
  relation: PropTypes.string,
};

/*------------------------------------------------------------------------------
 * AncestryDocument
 *-------------------------------------------------------------------------------
 *
 * Build the AncestryDocument and export it as a React component to be
 * used by the render() function
 *
 */

export function AncestryDocument(props) {
  const { person } = props;

  const today = new Date().toLocaleDateString();

  const g1p1 = person;
  const g2p1 = person?.father;
  const g2p2 = person?.mother;
  const g3p1 = person?.father?.father;
  const g3p2 = person?.father?.mother;
  const g3p3 = person?.mother?.father;
  const g3p4 = person?.mother?.mother;
  const g4p1 = person?.father?.father?.father;
  const g4p2 = person?.father?.father?.mother;
  const g4p3 = person?.father?.mother?.father;
  const g4p4 = person?.father?.mother?.mother;
  const g4p5 = person?.mother?.father?.father;
  const g4p6 = person?.mother?.father?.mother;
  const g4p7 = person?.mother?.mother?.father;
  const g4p8 = person?.mother?.mother?.mother;
  const g5p1 = person?.father?.father?.father?.father;
  const g5p2 = person?.father?.father?.father?.mother;
  const g5p3 = person?.father?.father?.mother?.father;
  const g5p4 = person?.father?.father?.mother?.mother;
  const g5p5 = person?.father?.mother?.father?.father;
  const g5p6 = person?.father?.mother?.father?.mother;
  const g5p7 = person?.father?.mother?.mother?.father;
  const g5p8 = person?.father?.mother?.mother?.mother;
  const g5p9 = person?.mother?.father?.father?.father;
  const g5p10 = person?.mother?.father?.father?.mother;
  const g5p11 = person?.mother?.father?.mother?.father;
  const g5p12 = person?.mother?.father?.mother?.mother;
  const g5p13 = person?.mother?.mother?.father?.father;
  const g5p14 = person?.mother?.mother?.father?.mother;
  const g5p15 = person?.mother?.mother?.mother?.father;
  const g5p16 = person?.mother?.mother?.mother?.mother;

  return (
    <Document title="Five-Generation Ancestry Chart">
      <Page size="Letter" style={dStyles.page}>
        <View>
          <View>
            <Image src={bgAncestry} style={dStyles.background} />
            <View style={dStyles.branding}>
              <Image src={tribalSeal} style={dStyles.tribalSeal} />
              <Text style={dStyles.h1}>Coeur d&#39;Alene Tribe</Text>
              <Text style={dStyles.h2}>Five-Generation Ancestry Chart</Text>
              <Text style={dStyles.h2}>
                {g1p1 ? `${g1p1.firstName} ${g1p1.lastName}` : null}
              </Text>
            </View>
          </View>

          {g1p1 ? <Person person={g1p1} placement={pStyles.g1p1} /> : null}
          {g2p1 ? (
            <Person person={g2p1} placement={pStyles.g2p1} relation="Father" />
          ) : null}
          {g2p2 ? (
            <Person person={g2p2} placement={pStyles.g2p2} relation="Mother" />
          ) : null}
          {g3p1 ? (
            <Person
              person={g3p1}
              placement={pStyles.g3p1}
              relation="Grandfather"
            />
          ) : null}
          {g3p2 ? (
            <Person
              person={g3p2}
              placement={pStyles.g3p2}
              relation="Grandmother"
            />
          ) : null}
          {g3p3 ? (
            <Person
              person={g3p3}
              placement={pStyles.g3p3}
              relation="Grandfather"
            />
          ) : null}
          {g3p4 ? (
            <Person
              person={g3p4}
              placement={pStyles.g3p4}
              relation="Grandmother"
            />
          ) : null}
          {g4p1 ? (
            <Person
              person={g4p1}
              placement={pStyles.g4p1}
              relation="Great Grandfather"
            />
          ) : null}
          {g4p2 ? (
            <Person
              person={g4p2}
              placement={pStyles.g4p2}
              relation="Great Grandmother"
            />
          ) : null}
          {g4p3 ? (
            <Person
              person={g4p3}
              placement={pStyles.g4p3}
              relation="Great Grandfather"
            />
          ) : null}
          {g4p4 ? (
            <Person
              person={g4p4}
              placement={pStyles.g4p4}
              relation="Great Grandmother"
            />
          ) : null}
          {g4p5 ? (
            <Person
              person={g4p5}
              placement={pStyles.g4p5}
              relation="Great Grandfather"
            />
          ) : null}
          {g4p6 ? (
            <Person
              person={g4p6}
              placement={pStyles.g4p6}
              relation="Great Grandmother"
            />
          ) : null}
          {g4p7 ? (
            <Person
              person={g4p7}
              placement={pStyles.g4p7}
              relation="Great Grandfather"
            />
          ) : null}
          {g4p8 ? (
            <Person
              person={g4p8}
              placement={pStyles.g4p8}
              relation="Great Grandmother"
            />
          ) : null}
          {g5p1 ? (
            <Person
              person={g5p1}
              placement={pStyles.g5p1}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p2 ? (
            <Person
              person={g5p2}
              placement={pStyles.g5p2}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p3 ? (
            <Person
              person={g5p3}
              placement={pStyles.g5p3}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p4 ? (
            <Person
              person={g5p4}
              placement={pStyles.g5p4}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p5 ? (
            <Person
              person={g5p5}
              placement={pStyles.g5p5}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p6 ? (
            <Person
              person={g5p6}
              placement={pStyles.g5p6}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p7 ? (
            <Person
              person={g5p7}
              placement={pStyles.g5p7}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p8 ? (
            <Person
              person={g5p8}
              placement={pStyles.g5p8}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p9 ? (
            <Person
              person={g5p9}
              placement={pStyles.g5p9}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p10 ? (
            <Person
              person={g5p10}
              placement={pStyles.g5p10}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p11 ? (
            <Person
              person={g5p11}
              placement={pStyles.g5p11}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p12 ? (
            <Person
              person={g5p12}
              placement={pStyles.g5p12}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p13 ? (
            <Person
              person={g5p13}
              placement={pStyles.g5p13}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p14 ? (
            <Person
              person={g5p14}
              placement={pStyles.g5p14}
              relation="Great Great Grandmother"
            />
          ) : null}
          {g5p15 ? (
            <Person
              person={g5p15}
              placement={pStyles.g5p15}
              relation="Great Great Grandfather"
            />
          ) : null}
          {g5p16 ? (
            <Person
              person={g5p16}
              placement={pStyles.g5p16}
              relation="Great Great Grandmother"
            />
          ) : null}
          <View style={dStyles.legend}>
            <Text>Coeur d&#39;Alene Tribal Enrollment Office</Text>
            <Text>This chart was generated {today}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

AncestryDocument.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
  }),
};

/*------------------------------------------------------------------------------
 * AncestryChart Component
 *-------------------------------------------------------------------------------
 *
 * Build the Ancestry Chart as a React Class component
 *
 * https://reactjs.org/docs/components-and-props.html
 *
 */

class AncestryChart extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.FAMILY_TREE_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "family tree"
          );

          if (loadingOrError) return loadingOrError;

          const { person } = data.viewer;

          return (
            <PageReports>
              <PDFViewer style={vStyles}>
                <AncestryDocument person={person} />
              </PDFViewer>
            </PageReports>
          );
        }}
      </Query>
    );
  }
}

AncestryChart.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default AncestryChart;
