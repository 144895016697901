import { gql } from "@selfdetermine/graphql";

import { IdFirstLastName } from "../graphql-fragments";

import {
  AncestorFragment,
  FamilyInformation,
  PersonalRelationships,
} from "./graphql-fragments";

// Person
export const PERSON = {
  ACTIONS_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          sex
          mother {
            id
            firstName
            lastName
          }
          father {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  ANCESTOR_QUERY: gql`
    query GetAncestors($id: ID!) {
      viewer {
        person(id: $id) {
          ...AncestorFragment
          father {
            ...AncestorFragment
            father {
              ...AncestorFragment
              father {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
              mother {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
            }
            mother {
              ...AncestorFragment
              father {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
              mother {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
            }
          }
          mother {
            ...AncestorFragment
            father {
              ...AncestorFragment
              father {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
              mother {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
            }
            mother {
              ...AncestorFragment
              father {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
              mother {
                ...AncestorFragment
                father {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
                mother {
                  ...AncestorFragment
                  father {
                    ...AncestorFragment
                  }
                  mother {
                    ...AncestorFragment
                  }
                }
              }
            }
          }
        }
      }
    }
    ${AncestorFragment}
  `,
  FAMILY_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          sex
          ...FamilyInformation
        }
      }
    }
    ${FamilyInformation}
  `,
  FAMILY_TREE_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          ...IdFirstLastName
          ...FamilyInformation
        }
      }
    }
    ${FamilyInformation}
  `,
  PERSONAL_RELATIONSHIPS_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...PersonalRelationships
        }
      }
    }
    ${PersonalRelationships}
  `,
  RELATIONSHIPS_CHART_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          sex
          membership_number: membershipNumber
          ...IdFirstLastName
          ...PersonalRelationships
        }
      }
    }
    ${IdFirstLastName}
    ${PersonalRelationships}
  `,
};
