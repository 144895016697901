import { Query } from "@apollo/client/react/components";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Table } from "reactstrap";
import Moment from "react-moment";

import {
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
} from "../../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES, ui } from "../../../Constants";
import { AddressFormat, Can, checkForErrors, TableSort } from "../../../Utils";

import { PERSON } from "../graphql";

import AddressModalForm from "./AddressModalForm";
import DeleteAddressModalForm from "./DeleteAddressModalForm";
import InactivateAddressModalForm from "./InactivateAddressModalForm";

class Addresses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: "createdOn",
      direction: "DESC",
    };
  }

  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  _handleSort = (e, sortBy, direction) => {
    this.setState({
      sortBy,
      direction,
    });
  };

  render() {
    const { sortBy, direction } = this.state;

    return (
      <Query
        query={PERSON.ADDRESSES_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "addresses"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          const addresses = orderBy(
            person.addresses,
            [sortBy],
            [direction.toLowerCase()]
          );

          return (
            <Panel id="address">
              <PanelHeader>
                <PanelTitle>Addresses</PanelTitle>
                <Can grantRoles={[ROLES.ENROLLMENT]}>
                  <CardActions>
                    <AddressModalForm
                      title="Add Physical Address"
                      address={{ active: true, kind: "physical" }}
                      person={person}
                      handleChange={this._handleAddressChange}
                    >
                      {ui.icon.plus} Add Physical
                    </AddressModalForm>{" "}
                    <AddressModalForm
                      title="Add Mailing Address"
                      address={{ active: true, kind: "mailing" }}
                      person={person}
                      handleChange={this._handleAddressChange}
                    >
                      {ui.icon.plus} Add Mailing
                    </AddressModalForm>
                  </CardActions>
                </Can>
              </PanelHeader>
              <Table
                className="mb-0 table-addresses"
                fluid="true"
                hover
                striped
                responsive
              >
                <thead className="small">
                  <tr>
                    <th>Address</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>
                      Created{" "}
                      <TableSort
                        name="createdOn"
                        sortBy={sortBy}
                        direction={direction}
                        handleSort={this._handleSort}
                      />
                    </th>
                    <th>
                      Modified{" "}
                      <TableSort
                        name="modifiedOn"
                        sortBy={sortBy}
                        direction={direction}
                        handleSort={this._handleSort}
                      />
                    </th>
                    <th>Last Modified By</th>
                  </tr>
                </thead>
                <tbody>
                  {addresses.map((address) => (
                    <tr key={address.id}>
                      <td className="cell-address">
                        <AddressFormat address={address} />
                      </td>
                      <td>
                        {address.kind.charAt(0).toUpperCase() +
                          address.kind.slice(1)}
                      </td>
                      <td>
                        {address.active ? (
                          <span className="active-address">
                            {ui.icon.circle_check} Active
                          </span>
                        ) : (
                          <span className="inactive-address">
                            {ui.icon.circle_minus} Inactive
                          </span>
                        )}
                      </td>
                      <td>
                        <Moment format="MM/DD/YYYY">{address.createdOn}</Moment>
                      </td>
                      <td>
                        <Moment format="MM/DD/YYYY">
                          {address.modifiedOn}
                        </Moment>
                      </td>
                      <td>
                        {address.lastModifiedBy && address.lastModifiedBy.email
                          ? address.lastModifiedBy.email
                          : null}
                      </td>
                      <td className="cell-actions">
                        <Can grantRoles={[ROLES.ENROLLMENT]}>
                          {address.active && address.kind === "mailing" ? (
                            <InactivateAddressModalForm
                              person={person}
                              address={address}
                            >
                              {ui.icon.circle_minus}
                            </InactivateAddressModalForm>
                          ) : null}
                          <DeleteAddressModalForm
                            person={person}
                            address={address}
                          >
                            {ui.icon.trash}
                          </DeleteAddressModalForm>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

Addresses.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

Addresses.defaultProps = {};

export default Addresses;
