import { gql } from "@selfdetermine/graphql";

// Analytics
export const AGE_BREAKDOWN = gql`
  query AgeBreakdown {
    viewer {
      analytics {
        under_18: nEnrolledUnder18
        between_18_35: nEnrolledBetween1835
        between_35_59: nEnrolledBetween3559
        over_60: nEnrolledOver60
        ageBreakdown {
          ageGroup
          totalMales
          totalFemales
          totalMembers
          onResMailingMales
          onResPhysicalMales
          onResMailingFemales
          onResPhysicalFemales
          offResMailingMales
          offResPhysicalMales
          offResMailingFemales
          offResPhysicalFemales
        }
      }
    }
  }
`;
