import { useQuery } from "@apollo/client";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import {
  ButtonGroup,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";

import _ from "lodash";
import moment from "moment";
import { useAuthenticationContext } from "@selfdetermine/react-authentication";
import portraitFallback from '../asset/portrait-fallback.png';
import {
  DEFAULT_PHONE_NUMBER_FORMAT,
  DEFAULT_PROVINCES,
  DEFAULT_SERVER_DATETIME_FORMAT,
  DEFAULT_SOCIAL_SECURITY_FORMAT,
} from "../Constants";

import {
  AddressLine,
  CircleLoader,
  Count,
  Loader,
  Sort,
  SortRight,
  SortArrow,
} from "../components";

export const routes = {
  root: () => "/",
  login: () => "/login",
  account: () => "/account",
  people: () => "/people",
};


/**
 * I am not sure I like this approach... Basically the server only accepts... what it accepts.
 * So we have to limit what the ui can send to a mutation, I cannot find a definitive approach on
 * how we approach this yet.
 */
export const convertDataToSchema = (data, schema) => {
  return { ...schema, ..._.pick(data, Object.keys(schema)) };
};

/**
 * Convert an object into a comma separated string of key=value
 */
export const convertObjToKeyValue = (object) => {
  return Object.keys(object)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(object[k])}`)
    .join(",");
};

export const filterSelfFromPeople = (person, data) =>
  data.filter((d) => d.value !== person.id);

export const peopleSearchQueryVars = (state, props) => {
  return {
    filters: {
      search: state.inputValue,
      sex: props.sex,
      adult: true,
    },
    sort: {
      column: "last_name",
      direction: "ASC",
    },
  };
};

export const getPersonName = (person) => {
  let name;
  name = person.firstName ? person.firstName : "";
  name = person.middleName ? `${name} ${person.middleName}` : name;
  name = person.lastName ? `${name} ${person.lastName}` : name;
  name = person.suffix ? `${name} ${person.suffix}` : name;
  return name;
};
export const peopleSearchGetOptions = (data, viewer) => {
  const optionsExist =
    data && data.viewer && data.viewer[viewer] && data.viewer[viewer].nodes;

  if (optionsExist) {
    const options = data.viewer[viewer].nodes;
    return options.map((option) => ({
      value: option.value,
      label: `${getPersonName(option)}, ${moment(
        option.bornAt,
        DEFAULT_SERVER_DATETIME_FORMAT
      ).format("MM/DD/YYYY")}`,
    }));
  }

  return [];
};

export const checkForErrors = (loading, error, data, component) => {
  if (loading) {
    return <Loading>Loading {component}...</Loading>;
  }

  if (error && data && !data.viewer) {
    return (
      <ErrorDisplayComponent message="An error occured while loading your data. Try reloading the page" />
    );
  }

  if (!data || (data && !data.viewer)) {
    return (
      <ErrorDisplayComponent message="There was an issue loading your data. Try reloading the page" />
    );
  }

  return null;
};

export const imageToBase64 = (src, callback, outputFormat) => {
  // http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever
  const blankImage =
    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };

  img.onerror = () => {
    callback(blankImage);
  };

  if (img.complete || img.complete === undefined) {
    img.src = blankImage;
    img.src = src;
  }

  img.src = src;
};

export const formatPersonExportFilename = (person, type) => {
  const name = `${person.firstName}${person.lastName}`.replace(
    /[^A-Z0-9]+/gi,
    ""
  );
  const exportDate = moment(new Date(), DEFAULT_SERVER_DATETIME_FORMAT).format(
    "MM-DD-YYYY"
  );
  const filename = `[${exportDate}]${name}-${type}.pdf`;
  return filename;
};

export const formatDate = (string) => {
  return moment(string, DEFAULT_SERVER_DATETIME_FORMAT).format("MM/DD/YYYY");
};

export const formatDateTime = (string) => {
  return moment(string, DEFAULT_SERVER_DATETIME_FORMAT).format(
    "MM/DD/YYYY hh:mm:ss A"
  );
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const withUserRole = (
  Component,
  grantRoles = [],
  { fallbackTo = routes.root() } = {}
) => (props) => {
  const { user } = useAuthenticationContext();

  const userHasAccess =
    user.admin ||
    grantRoles.some((roleName) =>
      user.roles.some((userRole) => userRole.name === roleName)
    );

  if (!userHasAccess) {
    return <Redirect to={fallbackTo} />;
  }

  return <Component {...props} user={user} />;
};

export const Can = ({ grantRoles = [], children }) => {
  const { user } = useAuthenticationContext();

  const userHasAccess =
    user.admin ||
    grantRoles.some((roleName) =>
      user.roles.some((userRole) => userRole.name === roleName)
    );

  return userHasAccess ? children : false;
};

export const MutationSubmit = ({
  loading,
  label,
  loadingLabel,
  id,
  className,
  ...props
}) => {
  let status = label;
  let disabled = false;

  if (loading) {
    status = loadingLabel;
    disabled = true;
  }

  return (
    <button
      id={id}
      className={classNames("button", className)}
      disabled={disabled}
      type="button"
      {...props}
    >
      {status}
    </button>
  );
};

MutationSubmit.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  loadingLabel: PropTypes.string,
};

export const RequiredField = () => {
  return <span className="text-danger">*</span>;
};

export const QuerySelect = (props) => {
  const {
    defaultValue,
    query,
    getQueryVariables,
    handleChange,
    value: fieldIdentifierProp,
    filterFunction,
    getOptions,
    viewer,
    className,
    id,
    ...rest
  } = props;

  const [value, setValue] = React.useState(defaultValue || "");
  const [inputValue, setInputValue] = React.useState("");

  const { loading, data } = useQuery(query, {
    variables:
      typeof getQueryVariables === "function"
        ? getQueryVariables({ value, inputValue }, props)
        : { search: inputValue },
  });

  let options =
    typeof getOptions === "function"
      ? getOptions(data, viewer)
      : (data && data.viewer && data.viewer[viewer]) || [];

  if (typeof filterFunction === "function") {
    options = filterFunction(options);
  }

  // Handles input change events. Stores updated value in local state and triggers the handleChange
  // prop if one is provided.
  const onChange = (newValue) => {
    setValue(newValue);

    if (typeof handleChange === "function") {
      handleChange(fieldIdentifierProp, newValue);
    }
  };

  return (
    <Select
      {...rest}
      inputId={id}
      isLoading={loading}
      options={options}
      onChange={(newValue) => onChange(newValue)}
      value={value}
      onInputChange={(newValue) => setInputValue(newValue)}
      inputValue={inputValue}
      className={classNames("z-index-top", className)}
      styles={{
        // In certain instances, clicking an option in the dropdown causes the click event
        // to apply to the content behind the Select's dropdown menu instead of the Select's
        // option. By increasing the zIndex of the dropdown then we can be sure the click
        // event will go where intended.
        menu: (provided) => ({
          ...provided,
          position: "relative",
          zIndex: 1000,
        }),
      }}
    />
  );
};

QuerySelect.propTypes = {
  name: PropTypes.string.isRequired,
  query: PropTypes.objectOf(PropTypes.any).isRequired,
  viewer: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  getQueryVariables: PropTypes.func,
  filterFunction: PropTypes.func,
  getOptions: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

export const Loading = ({ children }) => {
  return (
    <Loader>
      <CircleLoader />
      {children}
    </Loader>
  );
};

Loading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const TableSort = ({
  label,
  name,
  sortBy,
  direction,
  handleSort,
  float = "left",
}) => {
  let icon = "sort-down";
  if (name !== sortBy) icon = "sort";
  else if (direction === "ASC") icon = "sort-up";

  const sortDirection = direction === "ASC" ? "DESC" : "ASC";
  const SortContainer = float === "left" ? Sort : SortRight;

  return (
    <SortContainer onClick={(e) => handleSort(e, name, sortDirection)}>
      {label}
      <SortArrow icon={icon} />
    </SortContainer>
  );
};

TableSort.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  direction: PropTypes.string,
  float: PropTypes.string,
  handleSort: PropTypes.func,
  sortBy: PropTypes.string,
};

export const BASE_URL = "https://www.google.com/maps/search/?api=1&query=";

export const generateGoogleMapsLink = ({
  street1,
  street2,
  city,
  state,
  zipCode,
}) => {
  // return an empty string if value is null
  const check = (value) => value || "";

  return `${BASE_URL}${check(street1)}+${check(street2)}+${check(city)}+${check(
    state
  )}+${check(zipCode)}`;
};

export const AddressFormat = ({ address, styles }) => {
  // creating a copy of the address object with all strings trimmed
  const trimmedAddress = Object.keys(address).reduce((accum, curr) => {
    if (address[curr]) {
      // convert to string in case any of the values aren't type string
      // eslint-disable-next-line no-param-reassign
      accum[curr] = address[curr].toString().trim();
    }

    return accum;
  }, {});

  return (
    <a style={styles} target="_blank" rel="noopener noreferrer" href={generateGoogleMapsLink(trimmedAddress)}>
      <AddressLine>{trimmedAddress.street1}</AddressLine>
      <AddressLine>{trimmedAddress.street2}</AddressLine>
      <AddressLine>
        {trimmedAddress.city}
        {trimmedAddress.city && ","}{" "}
        <abbr title={DEFAULT_PROVINCES[trimmedAddress.state]}>
          {trimmedAddress.state}
        </abbr>{" "}
        {trimmedAddress.zipCode}
      </AddressLine>
    </a>
  );
};

AddressFormat.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  styles: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])),
};

export const Avatar = ({ id, styles }) => {
  const timestamp = Date.now();
  const portrait = `${process.env.REACT_APP_IMAGE_URL}/${id}.jpg?v=${timestamp}`;

  const wrapperStyles = {
    position: "relative",
    borderRadius: "100%",
    width: "64px",
    height: "64px",
    backgroundImage: `url(${portraitFallback})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "hidden"
  };

  const imageStyles = {
    display: "block",
    borderRadius: "100%",
    border: "2px solid #fff",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  };

  const mergedStyles = { ...wrapperStyles, ...styles };

  const handleImageError = (e) => {
    e.target.style.display = 'none';
  };

  return (
    <div style={mergedStyles}>
      <img style={imageStyles} src={portrait} alt="" onError={handleImageError} />
    </div>
  );
};

Avatar.propTypes = {
  id: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])),
};

export const FieldGroup = ({ id, name, label, help, labelProps, ...props }) => {
  return (
    <div className="field">
      <label
        {...labelProps}
        className={classNames("label", labelProps.className)}
        htmlFor={id}
      >
        {label}
      </label>
      <div className="control">
        <input className="input" {...props} name={name} id={id} />
      </div>
      {help && <p className="help">{help}</p>}
    </div>
  );
};

FieldGroup.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  labelProps: PropTypes.shape({
    className: PropTypes.string,
  }),
};

FieldGroup.defaultProps = {
  labelProps: {},
};

export const PageNotFound = () => (
  <ErrorMessage code={404} message="Page not found." />
);

export const ErrorMessage = ({ code, message }) => (
  <div className="error-container">
    <div className="error-wrapper">
      <span className="error-text-large">{code}</span>
      <span className="error-text-mid">{message}</span>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  code: PropTypes.string,
  message: PropTypes.string,
};

export const AddressInput = ({ address, handleChange, requiredFields }) => {
  return (
    <>
      <FormGroup>
        <Label for="street1">
          Street Address 1 {requiredFields ? <RequiredField /> : null}
        </Label>
        <Input
          required
          type="text"
          id="street1"
          name="street1"
          defaultValue={address.street1}
          placeholder="Street address, P.O. box, company name, c/o"
          onChange={(e) => handleChange(e)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="street2">Street Address 2</Label>
        <Input
          type="text"
          id="street2"
          name="street2"
          defaultValue={address.street2}
          placeholder="Apartment, suite, unit, building, floor, etc."
          onChange={(e) => handleChange(e)}
        />
      </FormGroup>
      <Row form>
        <Col sm="5">
          <FormGroup>
            <Label for="city" className="control-label">
              City {requiredFields ? <RequiredField /> : null}
            </Label>
            <Input
              required
              type="text"
              id="city"
              name="city"
              defaultValue={address.city}
              placeholder="Smallville"
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup>
            <Label for="state">
              Region {requiredFields ? <RequiredField /> : null}
            </Label>
            <Input
              required
              type="select"
              id="state"
              name="state"
              defaultValue={address.state}
              onChange={(e) => handleChange(e)}
            >
              <option value=""> </option>
              {Object.keys(DEFAULT_PROVINCES).map((province) => (
                <option key={province} value={province}>
                  {DEFAULT_PROVINCES[province]}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup>
            <Label for="zipCode">
              Zip Code {requiredFields ? <RequiredField /> : null}
            </Label>
            <Input
              required
              type="text"
              id="zipCode"
              name="zipCode"
              defaultValue={address.zipCode}
              placeholder="#####"
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

AddressInput.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.bool,
};

export const PageCount = ({ count, ...rest }) => {
  return <Count {...rest}>{count} results</Count>;
};

PageCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export const EmailAddress = ({ email, showLink, ...rest }) => {
  if (showLink) {
    return (
      <a href={`mailto:${email}`} {...rest}>
        {email}
      </a>
    );
  }
  return <span {...rest}>{email}</span>;
};

EmailAddress.propTypes = {
  email: PropTypes.string,
  showLink: PropTypes.bool,
};

export const PhoneNumber = ({ phone, number, showLink, ...rest }) => {
  if (showLink) {
    return (
      <a href={`tel:${phone}`} {...rest}>
        <NumberFormat
          value={phone}
          displayType="text"
          format={DEFAULT_PHONE_NUMBER_FORMAT}
          {...number}
        />
      </a>
    );
  }
  return (
    <NumberFormat
      value={phone}
      displayType="text"
      format={DEFAULT_PHONE_NUMBER_FORMAT}
      {...number}
    />
  );
};

PhoneNumber.propTypes = {
  phone: PropTypes.string,
  number: PropTypes.string,
  showLink: PropTypes.bool,
};

export const PhoneNumberInput = (props) => {
  return (
    <NumberFormat
      customInput={Input}
      format={DEFAULT_PHONE_NUMBER_FORMAT}
      mask="_"
      {...props}
    />
  );
};

PhoneNumberInput.propTypes = {};

export const SocialSecurity = ({ social }) => {
  return (
    <NumberFormat
      value={social}
      displayType="text"
      format={DEFAULT_SOCIAL_SECURITY_FORMAT}
    />
  );
};

SocialSecurity.propTypes = {
  social: PropTypes.string,
};

export const SocialSecurityInput = (props) => {
  return (
    <NumberFormat
      customInput={Input}
      format={DEFAULT_SOCIAL_SECURITY_FORMAT}
      mask="_"
      {...props}
    />
  );
};

SocialSecurityInput.propTypes = {};

export const Glossary = ({ id, handleChange, activeLetter, ...props }) => {
  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <ButtonGroup id={id} {...props}>
      <Button onClick={(e) => handleChange(e, null)} active={!activeLetter}>
        All
      </Button>
      {alphabet.map((letter) => {
        const active = activeLetter === letter;
        return (
          <Button
            key={letter}
            onClick={(e) => handleChange(e, letter)}
            active={active}
          >
            {letter}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

Glossary.propTypes = {
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  activeLetter: PropTypes.string,
};

// Internal use only
const ErrorDisplayComponent = ({ message }) => (
  <div style={{ padding: "3rem" }}>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h2 style={{ fontWeight: 200, fontSize: "4rem" }}>error</h2>
    </div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <p>{message}</p>
    </div>
  </div>
);

ErrorDisplayComponent.propTypes = {
  message: PropTypes.string,
};
