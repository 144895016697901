import { Mutation } from "@apollo/client/react/components";
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButtonUser, ButtonIcn } from "../components";
import { ui } from "../Constants";
import {
  AddressInput,
  MutationSubmit,
  RequiredField,
  capitalize,
} from "../Utils";

import { CREATE_DEPARTMENT_MUTATION, DEPARTMENTS } from "./graphql";

const { QUERY } = DEPARTMENTS;
const address = {
  street1: "",
  street2: "",
  city: "",
  state: "",
  zipCode: "",
};

class NewDepartment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      typeName: "",
    };
  }

  _handleChange = (e, type) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    const newState = this.state || {};
    newState.typeName = type;

    (newState[type] || (newState[type] = {}))[name] = value;
    this.setState(newState);
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;

      return { modal: updated };
    });
  };

  _getQueryVariables = () => {
    const { typeName } = this.state;
    const department = this.state[typeName] || {};
    return {
      [typeName]: {
        name: department.name,
        phoneNumber: department.phoneNumber,
        street1: department.street1,
        street2: department.street2,
        street3: department.street3,
        city: department.city,
        state: department.state,
        postalCode: department.zipCode,
      },
    };
  };

  _handleOnComplete = () => {
    const { typeName } = this.state;
    this.setState({
      [typeName]: {},
    });
  };

  render() {
    const typeName = "department";
    const query = QUERY;
    const mutation = CREATE_DEPARTMENT_MUTATION;

    return (
      <>
        <ActionButtonUser onClick={this._toggleModal} size="sm">
          <ButtonIcn>{ui.icon.plus}</ButtonIcn>New
        </ActionButtonUser>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>
            Add a {capitalize(typeName)}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="name">
                    Name <RequiredField />
                  </Label>
                  <Input
                    required
                    type="text"
                    name="name"
                    id="name"
                    placeholder="department name"
                    onChange={(e) => this._handleChange(e, typeName)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="phoneNumber">Phone Number</Label>
                  <Input
                    required
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="phone number"
                    onChange={(e) => this._handleChange(e, typeName)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <AddressInput
              address={address}
              handleChange={(e) => this._handleChange(e, typeName)}
            />
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={mutation}
              variables={this._getQueryVariables()}
              onCompleted={(data) => {
                Toasts.success(`${capitalize(typeName)} added`);
                this._toggleModal();
                this._handleOnComplete(data);
              }}
              onError={(error) =>
                Toasts.danger(`Unable to add ${typeName}`, error)
              }
              refetchQueries={[{ query }]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  color="warning"
                  label="Add Department"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default NewDepartment;
