import { gql } from "@selfdetermine/graphql";
import { Notes } from "./graphql-fragments";

export const CREATE_PERSON_NOTE = gql`
  mutation AddNoteToPerson($person: ID!, $body: String!) {
    addNoteToPerson(person: $person, body: $body) {
      id
    }
  }
`;

export const NOTES_QUERY = gql`
  query Person($person: ID!) {
    viewer {
      person(id: $person) {
        id
        ...Notes
      }
    }
  }
  ${Notes}
`;
