import { Mutation } from "@apollo/client/react/components";
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButtonUser, ButtonIcn } from "../components";
import { ui } from "../Constants";
import { TRIBES, CREATE_TRIBE_MUTATION } from "./graphql";
import { MutationSubmit, RequiredField } from "../Utils";

class NewTribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tribe: {},
      modal: false,
    };
  }

  handleChange = (e, stateKey) => {
    const { name, value, checked, type } = e.target;

    this.setState((currentState) => ({
      [stateKey]: {
        ...currentState[stateKey],
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  _toggleModal = () => {
    this.setState((currentState) => ({ modal: !currentState.modal }));
  };

  _getQueryVariables = () => {
    const { tribe } = this.state;
    return {
      tribe: {
        name: tribe.name,
      },
    };
  };

  _handleOnComplete = () => {
    this.setState({
      tribe: {},
    });
  };

  render() {
    const { modal } = this.state;
    const { className } = this.props;
    return (
      <>
        <ActionButtonUser
          id="modal-btn"
          onClick={this._toggleModal}
          size="sm"
          aria-label="Create New Tribe"
        >
          <ButtonIcn>{ui.icon.plus}</ButtonIcn>New
        </ActionButtonUser>
        <Modal isOpen={modal} toggle={this._toggleModal} className={className}>
          <ModalHeader toggle={this._toggleModal}>Add a Tribe</ModalHeader>
          <ModalBody>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="name">
                    Name of the tribe to save... <RequiredField />
                  </Label>
                  <Input
                    required
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => this.handleChange(e, "tribe")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={CREATE_TRIBE_MUTATION}
              variables={this._getQueryVariables()}
              onCompleted={(data) => {
                Toasts.success("Tribe created");
                this._toggleModal();
                this._handleOnComplete(data);
              }}
              onError={(error) =>
                Toasts.danger("Unable to create new tribe", error)
              }
              refetchQueries={[{ query: TRIBES.QUERY }]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="submit-btn"
                  color="warning"
                  label="Save Tribe"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default NewTribe;
