import React, { Component } from "react";
import {
  CustomInput,
  Button,
  FormGroup,
  Label,
  InputGroupAddon,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { AuthenticationContext } from "@selfdetermine/react-authentication";
import {
  FilterGroup,
  FilterGroupItemHeader,
  FilterForm,
  SearchGroup,
  StatusSelect,
} from "../components";
import { DEFAULT_MEMBER_STATUSES, ROLES, ui } from "../Constants";
import { TRIBES } from "../Tribes/graphql";
import { Can, QuerySelect } from "../Utils";

class Filter extends Component {
  constructor(props) {
    super(props);

    const filters = { ...props.filters };
    const { memberStatus } = props.filters;

    if (memberStatus !== "") {
      filters.memberStatus = {
        label: DEFAULT_MEMBER_STATUSES[memberStatus],
        value: memberStatus,
      };
    }

    filters.hasTribe = false;
    filters.tribeName = "coeur";

    filters.hasTribe2 = false;
    filters.tribeName2 = "con";

    this.state = {
      filters,
      filterModalOpen: false,
    };
  }

  _toggleFilterModal = () => {
    this.setState((prevState) => {
      return { filterModalOpen: !prevState.filterModalOpen };
    });
  };

  _handleClear = () => {
    const filters = { ...Filter.defaultProps.filters };
    this.setState({ filters });
    this.props.onChangeFilters(filters);

    // Ensure url bar get's updated.
    this.props.history.push({
      pathname: "/people",
    });
  };

  _handleSubmit = async () => {
    const { filters } = this.state;

    let { REACT_APP_CON_TRIBE_ID, REACT_APP_ENROLLABLE_TRIBE_ID } = process.env;
    REACT_APP_CON_TRIBE_ID = REACT_APP_CON_TRIBE_ID
      ? parseInt(REACT_APP_CON_TRIBE_ID, 10)
      : null;
    REACT_APP_ENROLLABLE_TRIBE_ID = REACT_APP_ENROLLABLE_TRIBE_ID
      ? parseInt(REACT_APP_ENROLLABLE_TRIBE_ID, 10)
      : null;

    let hasNoBloodQuantaByTribe;
    if (filters.hasNoBloodQuantaByTribe) {
      hasNoBloodQuantaByTribe = parseInt(
        filters.hasNoBloodQuantaByTribe.value,
        10
      );
    }

    if (filters.tribeName === "coeur") {
      hasNoBloodQuantaByTribe = REACT_APP_ENROLLABLE_TRIBE_ID;
    }

    let tribe;
    if (filters.tribe) {
      tribe = parseInt(filters.tribe.value, 10);
    }

    if (filters.tribeName2 === "con") {
      tribe = REACT_APP_CON_TRIBE_ID;
    }

    const filtersQueryVar = {
      search: filters.search,
      memberStatus: filters.memberStatus ? filters.memberStatus.value : null,
      sex: filters.sex,
      onRez: filters.onRez,
      offRez: filters.offRez,
      birthdateStart: filters.birthdateStart,
      birthdateEnd: filters.birthdateEnd,
      hasReentryCases: filters.hasReentryCases,
      hasActiveReentryCases: filters.hasActiveReentryCases,
      hasMatters: filters.hasMatters,
      hasNilTribe: filters.hasNilTribe,
      hasNoBloodQuanta: filters.hasNoBloodQuanta,
      noParents: filters.noParents,
      hasNotes: filters.hasNotes,
      hasNoBloodQuantaByTribe: filters.hasTribe
        ? hasNoBloodQuantaByTribe
        : null,
      tribe: filters.hasTribe2 ? tribe : null,
      hasNoActiveMailingResidence: filters.hasNoActiveMailingResidence,
      hasNoActivePhysicalResidence: filters.hasNoActivePhysicalResidence,
      adult: filters.adult,
    };

    this.props.onChangeFilters(filtersQueryVar);
  };

  _handleChange = (e, n, v) => {
    const { target } = e;
    let name = n;
    let value = v;

    if (!name) {
      name = target.name;
      value = target.type === "checkbox" ? target.checked : target.value;
    }

    this.setState(
      (prevState) => {
        const state = prevState.filters;
        state[name] = value;

        return { filters: state };
      },
      () => this._handleSubmit()
    );
  };

  _handleSelectChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;

    this.setState({ filters }, () => this._handleSubmit());
  };

  _optionsMemberStatus = (user) => {
    /*
      making a copy of DEFAULT_MEMBER_STATUSES...

      Reason for using a copy: if you modify the DEFAULT_MEMBER_STATUSES for admin user,
      log out, and log back in as an enrollment user, the modified DEFAULT_MEMBER_STATUSES
      will still be available and will be used;
    */
    const statusOptions = { ...DEFAULT_MEMBER_STATUSES };
    if (user.admin) {
      // modifying the copy if user is admin
      statusOptions.inactive = "Inactive";
    }

    return Object.keys(statusOptions).map((status) => {
      return { value: status, label: statusOptions[status] };
    });
  };

  render() {
    const {
      adult = false,
      search,
      memberStatus,
      sex,
      onRez,
      offRez,
      birthdateStart,
      birthdateEnd,
      hasReentryCases,
      hasActiveReentryCases,
      hasMatters = false,
      hasNilTribe,
      hasNoBloodQuanta,
      noParents,
      hasTribe = false,
      hasTribe2 = false,
      tribeName,
      tribeName2,
      hasNoBloodQuantaByTribe,
      tribe,
      hasNotes = false,
      hasNoActiveMailingResidence = false,
      hasNoActivePhysicalResidence = false,
    } = this.state.filters;

    return (
      <FilterForm>
        <SearchGroup>
          <Input
            type="text"
            name="search"
            id="searchPeople"
            placeholder="Search People..."
            value={search != null ? search : ""}
            onChange={(e) => this._handleChange(e)}
          />
          <InputGroupAddon addonType="append">
            <Button type="submit" onClick={(e) => this._handleSubmit(e)}>
              {ui.icon.search}
            </Button>
          </InputGroupAddon>
        </SearchGroup>

        <Can grantRoles={[ROLES.ENROLLMENT]}>
          <AuthenticationContext.Consumer>
            {({ user }) => (
              <StatusSelect
                name="memberStatus"
                placeholder="Select member status"
                isClearable
                options={this._optionsMemberStatus(user)}
                onChange={(e) => this._handleSelectChange("memberStatus", e)}
                value={memberStatus}
              />
            )}
          </AuthenticationContext.Consumer>
        </Can>

        <Button onClick={this._toggleFilterModal}>Advanced Filters</Button>

        <Modal
          isOpen={this.state.filterModalOpen}
          toggle={this._toggleFilterModal}
        >
          <ModalHeader>Filters</ModalHeader>
          <Can grantRoles={[ROLES.ENROLLMENT]}>
            <FilterGroup>
              <CustomInput
                id="adult"
                type="checkbox"
                name="adult"
                checked={adult}
                label="Is Adult"
                onChange={(e) => this._handleChange(e)}
              />
            </FilterGroup>

            <FilterGroup>
              <FilterGroupItemHeader>Sex</FilterGroupItemHeader>
              <FormGroup check inline>
                <Label check>
                  <CustomInput
                    id="sexMale"
                    label="Male"
                    type="radio"
                    name="sex"
                    checked={sex === "male"}
                    value="male"
                    onChange={(e) => this._handleChange(e)}
                  />
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <CustomInput
                    id="sexFemale"
                    label="Female"
                    type="radio"
                    name="sex"
                    checked={sex === "female"}
                    value="female"
                    onChange={(e) => this._handleChange(e)}
                  />
                </Label>
              </FormGroup>
            </FilterGroup>
            <FilterGroup>
              <FilterGroupItemHeader>Location</FilterGroupItemHeader>
              <CustomInput
                id="onRez"
                type="checkbox"
                name="onRez"
                value={onRez}
                checked={onRez}
                label="On-reservation *"
                onChange={(e) => this._handleChange(e)}
              />
              <CustomInput
                id="offRez"
                type="checkbox"
                name="offRez"
                value={offRez}
                checked={offRez}
                label="Off-reservation"
                onChange={(e) => this._handleChange(e)}
              />
              <div style={{ fontSize: ".75rem", color: "#6f6f6f" }}>
                <i>
                  * active mailing address ZIP code is in one of 83876, 83810,
                  83861, 83842, 83851, 83870, 83824, or 83855
                </i>
              </div>
            </FilterGroup>
            <FilterGroup>
              <FilterGroupItemHeader>Birth date range</FilterGroupItemHeader>
              <FormGroup>
                <Label for="birthdate-start-date">Start</Label>
                <Input
                  type="date"
                  id="birthdateStart"
                  name="birthdateStart"
                  placeholder="YYYY-MM-DD"
                  value={birthdateStart != null ? birthdateStart : ""}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="birthdate-end-date">End</Label>
                <Input
                  type="date"
                  id="birthdateEnd"
                  name="birthdateEnd"
                  placeholder="YYYY-MM-DD"
                  value={birthdateEnd != null ? birthdateEnd : ""}
                  onChange={(e) => this._handleChange(e)}
                />
              </FormGroup>
            </FilterGroup>

            <FilterGroup>
              <FilterGroupItemHeader>Missing Information</FilterGroupItemHeader>
              <CustomInput
                id="hasNilTribe"
                type="checkbox"
                name="hasNilTribe"
                value={hasNilTribe}
                checked={hasNilTribe}
                label="Blood Quanta with Missing Tribe"
                onChange={(e) => this._handleChange(e)}
              />
              <CustomInput
                id="hasNoBloodQuanta"
                type="checkbox"
                name="hasNoBloodQuanta"
                value={hasNoBloodQuanta}
                checked={hasNoBloodQuanta}
                label="No Blood Quanta"
                onChange={(e) => this._handleChange(e)}
              />
              <CustomInput
                id="noParents"
                type="checkbox"
                name="noParents"
                value={noParents}
                checked={noParents}
                label="No Parents"
                onChange={(e) => this._handleChange(e)}
              />
              <CustomInput
                id="hasNoActiveMailingResidence"
                type="checkbox"
                name="hasNoActiveMailingResidence"
                value={hasNoActiveMailingResidence}
                checked={hasNoActiveMailingResidence}
                label="No Active Mailing Address"
                onChange={(e) => this._handleChange(e)}
              />
              <CustomInput
                id="hasNoActivePhysicalResidence"
                type="checkbox"
                name="hasNoActivePhysicalResidence"
                value={hasNoActivePhysicalResidence}
                checked={hasNoActivePhysicalResidence}
                label="No Active Physical Address"
                onChange={(e) => this._handleChange(e)}
              />
            </FilterGroup>

            <FilterGroup>
              <FilterGroupItemHeader>Tribal Information</FilterGroupItemHeader>
              <CustomInput
                id="hasTribe"
                type="checkbox"
                name="hasTribe"
                value={hasTribe}
                checked={hasTribe}
                label="No Blood Quanta By Tribe..."
                onChange={(e) => this._handleChange(e)}
              />
              {hasTribe && (
                <div style={{ marginBottom: "1rem" }}>
                  <FormGroup check>
                    <CustomInput
                      id="tribeNameCoeur"
                      label={`Coeur d'Alene`}
                      type="radio"
                      name="tribeName"
                      checked={tribeName === "coeur"}
                      value="coeur"
                      onChange={(e) => this._handleChange(e)}
                    />
                  </FormGroup>
                  <FormGroup check>
                    <CustomInput
                      id="tribeNameOther"
                      label="Other..."
                      type="radio"
                      name="tribeName"
                      checked={tribeName === "other"}
                      value="other"
                      onChange={(e) => this._handleChange(e)}
                    />
                  </FormGroup>
                  {tribeName === "other" && (
                    <QuerySelect
                      value="hasNoBloodQuantaByTribe"
                      defaultValue={hasNoBloodQuantaByTribe}
                      query={TRIBES.SELECTQUERY}
                      viewer="tribes"
                      isSearchable
                      isClearable
                      placeholder="Select Tribe..."
                      handleChange={this._handleSelectChange}
                      menuPlacement="top"
                    />
                  )}
                </div>
              )}

              <CustomInput
                id="hasTribe2"
                type="checkbox"
                name="hasTribe2"
                value={hasTribe2}
                checked={hasTribe2}
                label="Has Blood Quanta By Tribe..."
                onChange={(e) => this._handleChange(e)}
              />
              {hasTribe2 && (
                <div style={{ marginBottom: "1rem" }}>
                  <FormGroup check>
                    <CustomInput
                      id="tribeNameCon"
                      label="Con"
                      type="radio"
                      name="tribeName2"
                      checked={tribeName2 === "con"}
                      value="con"
                      onChange={(e) => this._handleChange(e)}
                    />
                  </FormGroup>
                  <FormGroup check>
                    <CustomInput
                      id="tribeNameOther2"
                      label="Other..."
                      type="radio"
                      name="tribeName2"
                      checked={tribeName2 === "other"}
                      value="other"
                      onChange={(e) => this._handleChange(e)}
                    />
                  </FormGroup>
                  {tribeName2 === "other" && (
                    <QuerySelect
                      value="tribe"
                      defaultValue={tribe}
                      query={TRIBES.SELECTQUERY}
                      viewer="tribes"
                      isSearchable
                      isClearable
                      placeholder="Select Tribe..."
                      handleChange={this._handleSelectChange}
                      menuPlacement="top"
                    />
                  )}
                </div>
              )}
            </FilterGroup>

            <FilterGroup>
              <FilterGroupItemHeader>Notes</FilterGroupItemHeader>
              <CustomInput
                id="hasNotes"
                type="checkbox"
                name="hasNotes"
                value={hasNotes}
                checked={hasNotes}
                label="Has Notes"
                onChange={(e) => this._handleChange(e)}
              />
            </FilterGroup>
          </Can>
          <Can grantRoles={[ROLES.REENTRY]}>
            <FilterGroup>
              <CustomInput
                id="hasReentryCases"
                type="checkbox"
                name="hasReentryCases"
                value={hasReentryCases}
                checked={hasReentryCases}
                label="Has Reentry Cases"
                onChange={(e) => this._handleChange(e)}
              />
            </FilterGroup>
          </Can>
          <Can grantRoles={[ROLES.REENTRY]}>
            <FilterGroup>
              <CustomInput
                id="hasActiveReentryCases"
                type="checkbox"
                name="hasActiveReentryCases"
                value={hasActiveReentryCases}
                checked={hasActiveReentryCases}
                label="Has Active Reentry Cases"
                onChange={(e) => this._handleChange(e)}
              />
            </FilterGroup>
          </Can>
          <Can grantRoles={[ROLES.CIVIL_ATTORNEY]}>
            <FilterGroup>
              <CustomInput
                id="hasMatters"
                type="checkbox"
                name="hasMatters"
                value={hasMatters}
                checked={hasMatters}
                label="Has Matters"
                onChange={(e) => this._handleChange(e)}
              />
            </FilterGroup>
          </Can>

          <ModalFooter>
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => this._handleClear()}
            >
              Reset Filters
            </Button>
            <Button onClick={this._toggleFilterModal}>Close</Button>
          </ModalFooter>
        </Modal>
        <Button
          color="secondary"
          className="ml-2"
          onClick={() => this._handleClear()}
        >
          Reset Filters
        </Button>
      </FilterForm>
    );
  }
}

Filter.defaultProps = {
  filters: {
    search: null,
    memberStatus: null,
    sex: null,
    onRez: false,
    offRez: false,
    birthdateStart: null,
    birthdateEnd: null,
    hasReentryCases: false,
    hasActiveReentryCases: false,
    hasNilTribe: false,
    hasNoBloodQuanta: false,
    noParents: false,
    tribe: null,
    hasNotes: false,
    hasNoActiveMailingResidence: false,
  },
};

export default Filter;
