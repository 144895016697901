import React from "react";
import { useAuthenticationContext } from "@selfdetermine/react-authentication";
import Layout from "../Layout";
import DashboardContents from "./Dashboard";

export const Dashboard = () => {
  const { user } = useAuthenticationContext();

  return (
    <Layout>
      <DashboardContents viewer={user} />
    </Layout>
  );
};
