import React from "react";
import PropTypes from "prop-types";
import {
  PersonDetailsEnrollment,
  PersonDetailsContact,
  PersonDetailsContactItem,
  PersonDetailsLabel,
  PersonDetailsValue,
} from "../../components";
import { NO_ACTIVE_MAILING_ADDRESS } from "../../Constants";
import { AddressFormat, EmailAddress, PhoneNumber } from "../../Utils";

const DetailsInfoEnrollment = ({ person }) => {
  let phoneNumber;

  if (person.phone1) {
    phoneNumber = person.phone1;
  } else if (person.phone2) {
    phoneNumber = person.phone2;
  } else if (person.mobile) {
    phoneNumber = person.mobile;
  } else {
    phoneNumber = "No number";
  }

  return (
    <>
      <PersonDetailsEnrollment>
        {person.member_status}: {person.membership_number}
      </PersonDetailsEnrollment>

      <PersonDetailsContact>
        <PersonDetailsContactItem>
          <PersonDetailsLabel>Phone</PersonDetailsLabel>
          <PersonDetailsValue>
            <PhoneNumber phone={phoneNumber} />
          </PersonDetailsValue>
        </PersonDetailsContactItem>

        <PersonDetailsContactItem>
          <PersonDetailsLabel>Email</PersonDetailsLabel>
          <PersonDetailsValue>
            {person.email ? <EmailAddress email={person.email} /> : "No email"}
          </PersonDetailsValue>
        </PersonDetailsContactItem>

        <PersonDetailsContactItem>
          <PersonDetailsLabel>Mailing Address</PersonDetailsLabel>
          <PersonDetailsValue>
            {person.activeMailingAddress ? (
              <AddressFormat
                address={person.activeMailingAddress}
                styles={{
                  color: "white",
                }}
              />
            ) : (
              NO_ACTIVE_MAILING_ADDRESS
            )}
          </PersonDetailsValue>
        </PersonDetailsContactItem>
      </PersonDetailsContact>
    </>
  );
};

DetailsInfoEnrollment.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default DetailsInfoEnrollment;
