import React from "react";
import {
  useAuthenticationContext,
  useLogout,
} from "@selfdetermine/react-authentication";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { UserDropdownToggle } from "../components";

const AccountDropdown = () => {
  const { user } = useAuthenticationContext();
  const [logout, logoutMeta] = useLogout();

  // After successfully logging out we want to just refresh the app
  React.useEffect(() => {
    if (!logoutMeta.loading && !!logoutMeta.data) {
      window.location.reload();
    }
  }, [logoutMeta.data, logoutMeta.loading]);

  return (
    <UncontrolledButtonDropdown>
      <UserDropdownToggle size="sm" caret>
        {user.email}
      </UserDropdownToggle>
      <DropdownMenu>
        <NavLink to="/account" href="/account" activeClassName="active">
          <DropdownItem>Account</DropdownItem>
        </NavLink>
        <DropdownItem onClick={logout}>Logout</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default AccountDropdown;
