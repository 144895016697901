import { useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Moment from "react-moment";

import {
  HistoryGroup,
  HistoryItem,
  HistoryHeader,
  HistoryKey,
  HistoryValue,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
} from "../../../components";
import {
  ROLES,
  DEFAULT_QUERY_ERROR_POLICY,
  DEFAULT_SERVER_DATETIME_FORMAT,
} from "../../../Constants";
import { checkForErrors, withUserRole } from "../../../Utils";

import { HISTORY_QUERY } from "./graphql";

const parseJSON = (json) => {
  let parsedChanges = [];
  try {
    parsedChanges = JSON.parse(json) || [];
  } catch (e) {
    // failed to parse JSON
  }

  return parsedChanges;
};

const History = (props) => {
  const { match } = props;
  const { loading, error, data } = useQuery(HISTORY_QUERY, {
    variables: {
      id: match.params.person,
    },
    errorPolicy: DEFAULT_QUERY_ERROR_POLICY,
    fetchPolicy: "network-only",
  });

  const loadingOrError = checkForErrors(loading, error, data, "overview");
  if (loadingOrError) {
    return loadingOrError;
  }

  const history = data.viewer.person.history
    .filter((change) => change !== null)
    .sort((change1, change2) => {
      const date1 = moment(change1.createdAt);
      const date2 = moment(change2.createdAt);

      if (date1.isBefore(date2)) {
        return 1;
      }

      if (date1.isAfter(date2)) {
        return -1;
      }

      return 0;
    });

  return (
    <Panel id="history">
      <PanelHeader>
        <PanelTitle>Change History</PanelTitle>
      </PanelHeader>
      <PanelBody>
        {history.map((change) => {
          const { user, event, changes, createdAt } = change;
          const parsedChanges = parseJSON(changes);

          return (
            <HistoryGroup
              className="history_item"
              key={createdAt}
              id="history-fields"
            >
              <ChangeHeader user={user} event={event} createdAt={createdAt} />

              {parsedChanges.length === 0 && (
                <p>no changes recorded at this time</p>
              )}

              {Object.keys(parsedChanges).map((key) => (
                <HistoryItem key={key}>
                  <HistoryKey>{key}</HistoryKey>
                  <HistoryValue>
                    <span>from:</span>
                    <span>
                      {parsedChanges[key][0] ? parsedChanges[key][0] : "null"}
                    </span>
                  </HistoryValue>
                  <HistoryValue>
                    <span>to:</span>
                    <span>{parsedChanges[key][1]}</span>
                  </HistoryValue>
                </HistoryItem>
              ))}
            </HistoryGroup>
          );
        })}
      </PanelBody>
    </Panel>
  );
};

const ChangeHeader = ({ user, event, createdAt }) => (
  <HistoryHeader className="history-header">
    {user ? user.email : "null"}
    {" – "}
    {event === "update" ? "updated" : "created"} at{" "}
    <Moment
      date={createdAt}
      format="H:MM:SS, MM/DD/YYYY"
      parse={DEFAULT_SERVER_DATETIME_FORMAT}
    />
  </HistoryHeader>
);

ChangeHeader.propTypes = {
  createdAt: PropTypes.string,
  event: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

History.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      person: PropTypes.string,
    }),
  }),
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

History.defaultProps = {};

const wrappedWithUserRole = withUserRole(History, [ROLES.ENROLLMENT]);

export default wrappedWithUserRole;
