import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Row, Col, FormGroup, Label, Input } from "reactstrap";

import {
  DEFAULT_MEMBER_STATUSES_WITH_INACTIVE,
  DEFAULT_SERVER_DATETIME_FORMAT,
} from "../../../Constants";

class MembershipInput extends Component {
  _handleChange = (e) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.props.handleChange("person", name, value);
  };

  _optionsMemberStatus = () => {
    return Object.entries(DEFAULT_MEMBER_STATUSES_WITH_INACTIVE).map(
      ([k, v]) => {
        return (
          <option key={`option-${k}`} value={k}>
            {v}
          </option>
        );
      }
    );
  };

  render() {
    const { person } = this.props;

    const enrolledAt = person.enrolledAt
      ? moment(person.enrolledAt, DEFAULT_SERVER_DATETIME_FORMAT).format(
          "YYYY-MM-DD"
        )
      : null;
    const appliedAt = person.appliedAt
      ? moment(person.appliedAt, DEFAULT_SERVER_DATETIME_FORMAT).format(
          "YYYY-MM-DD"
        )
      : null;
    const cardIssuedAt = person.cardIssuedAt
      ? moment(person.cardIssuedAt, DEFAULT_SERVER_DATETIME_FORMAT).format(
          "YYYY-MM-DD"
        )
      : null;

    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <Label for="memberStatus">Membership Status</Label>
              <Input
                type="select"
                name="memberStatus"
                id="member_status"
                defaultValue={person.memberStatus}
                onChange={(e) => this._handleChange(e)}
              >
                <option value="">Member Status</option>
                {this._optionsMemberStatus()}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {person.memberStatus === "deceased" ? (
          <Alert color="danger">
            Warning! This person will no longer be editable after membership
            status is changed to Deceased.
          </Alert>
        ) : (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="membershipNumber">Membership Number</Label>
                  <Input
                    type="text"
                    name="membershipNumber"
                    id="membership_number"
                    placeholder="Enter membership number"
                    defaultValue={person.membershipNumber}
                    onChange={(e) => this._handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="resolutionNumber">Resolution Number</Label>
                  <Input
                    type="text"
                    name="resolutionNumber"
                    id="resolution_number"
                    placeholder="Enter resolution number"
                    defaultValue={person.resolutionNumber}
                    onChange={(e) => this._handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="enrolledAt">Date of Enrollment</Label>
                  <Input
                    type="date"
                    name="enrolledAt"
                    id="enrolled_at"
                    defaultValue={enrolledAt}
                    onChange={(e) => this._handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="appliedAt">Date of Application</Label>
                  <Input
                    type="date"
                    name="appliedAt"
                    id="applied_at"
                    defaultValue={appliedAt}
                    onChange={(e) => this._handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="cardIssuedAt">Card Issued</Label>
                  <Input
                    type="date"
                    name="cardIssuedAt"
                    id="card_issued_at"
                    defaultValue={cardIssuedAt}
                    onChange={(e) => this._handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

MembershipInput.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

MembershipInput.defaultProps = {};

export default MembershipInput;
