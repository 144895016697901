import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  Navbar,
  Table,
  Nav,
  NavItem,
} from "reactstrap";
import Select from "react-select";
import styled, { keyframes } from "styled-components";

import { ui } from "../Constants";

export { default as ConfirmationModal } from "./ConfirmationModal";

/**
 * ****************************************************************************
 * Display Table
 * ****************************************************************************
 *
 * This is a responsive table for displaying tabular data
 *
 */

export const DisplayTable = styled.div`
  display: table;
  width: 100%;
`;

export const DisplayTableRow = styled.div`
  display: table-row;
  width: 100%;
  background-color: #edeff3;
  &:nth-of-type(odd) {
    background-color: #f6f6f9; // Or any other styling you want to apply to odd rows
  }
`;

export const DisplayTableHead = styled.div`
  display: table-header-group;
  > ${DisplayTableRow} {
    background-color: #ffffff; // Or any other styling you want to apply to odd rows
  }
`;

export const DisplayTableBody = styled.div`
  display: table-row-group;
`;

export const DisplayTableCell = styled.div`
  display: table-cell;
  border-bottom: 1px solid #e4e8ea;
  vertical-align: top;
  padding: 0.5rem 1rem;
  &:last-of-type {
    width: 100%;
  }
`;

export const DisplayTableCaption = styled.div`
  display: table-caption;
`;

// ----------------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------------

export const Icon = (props) => (
  <span className="icon">
    <FontAwesomeIcon {...props} />
  </span>
);

// Globally available components
export const Error = styled.div`
  background-color: rgb(245, 147, 156, 0.7);
  color: red;
  border: 1 solid red;
  margin: 10px;
  padding: 10px;
`;

export const EmptyPeople = styled.div`
  background-color: rgb(252, 243, 207, 0.7);
  color: black;
  border: 1 solid black;
  margin: 10px;
  padding: 10px;
`;

export const EmptyListing = styled.div`
  background-color: rgb(252, 243, 207, 0.7);
  color: black;
  border: 1 solid black;
  margin: 10px;
  padding: 10px;
`;

// Page components
export const Page = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
`;

export const PageHeader = styled.header`
  background: #ffffff;
  box-shadow: ${ui.shadow.low};
  flex: 0 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

export const PageHeaderSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 .5rem;
    &&:first-of-type {
      justify-content: flex-start;
    }
    &&:last-of-type {
      justify-content: flex-end;
    }
  }
`;

export const PageTitle = styled.h1`
  font-size: 1rem;
  margin: 0;
`;

export const PageRow = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
`;

export const PageColumn = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
`;

export const PageFilter = styled.div`
  display: flex;
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #e9e9e9;
`;

export const PageActions = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px 5px;
  margin: 0;
  border-bottom: 1px solid #e9e9e9;
`;

export const PageContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

export const PageFooter = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px;
  margin: 0;
  border-top: 1px solid #e9e9e9;
  text-align: center;
  vertical-align: middle;
`;

export const ButtonIcn = styled.span`
  margin-right: 0.25rem;
`;

// Buttons to be removed and replaced with Button component
export const ActionsCtrls = styled.div``;

export const ActionLink = styled.a``;

export const BackLink = styled.div``;

export const ActionButton = styled(Button)``;

export const ActionButtonUser = styled(Button)``;

export const ListActionButton = styled(Button)``;

export const ActionDropdownToggle = styled(DropdownToggle)``;

export const UserDropdownToggle = styled(DropdownToggle)``;

export const HeaderDropdownToggle = styled(DropdownToggle)``;

export const VerticalDivider = styled.div`
  display: inline-block;
  margin: 6px 15px;
  width: 1px;
  height: 30px;
  border-right: 1px solid #e1e1e1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
`;

// Panels
export const Panel = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const PanelSingle = styled.div`
  max-width: 800px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
`;

export const PanelHeader = styled.div`
  border-top: 1px solid ${ui.color.shade_400};
  padding: 0.5rem 0rem;
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  &:first-of-type {
    padding: 0.5rem 1rem;
    margin: 0;
    border-top: none;
    border-bottom: 1px solid ${ui.color.shade_400};
  }
  &:nth-of-type(2) {
    border-top: none;
  }
`;

export const PanelTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
`;

export const PanelBody = styled.div`
  padding: 1rem;
`;

export const PanelActions = styled.div``;

// Old panel stuff
export const PanelNavbar = styled(Navbar)`
  && {
    overflow: hidden;
    min-height: 34px;
    font-size: 0.75em;
    margin: 0px;
    padding: 0px;
    background: ${ui.color.shade_200};
    .nav-link {
      background-color: ${ui.color.shade_500};
      margin: 0.25rem 0 0 0.25rem;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100px;
      color: #404040;
      font-size: 0.75rem;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      border: none;
      overflow: hidden;
      padding: 6px 16px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &.active {
        border-bottom: none;
        background-color: ${ui.color.shade_400};
      }
    }
  }
`;

export const PanelNavbarTitle = styled.h2`
  font-size: 1rem;
  margin: 0px 0px 0px 16px;
`;

export const TabPanel = styled.div`
  border-top: none;
`;

export const DataPair = styled.div`
  margin: 0 0 20px 0px;
`;

export const SearchForm = styled(Form)`
  display: inline-block;
  position: relative;
  margin-right: auto;
`;

export const SearchInput = styled(Input)`
  margin: 0.125rem 0 0.375rem;
`;

export const Address = styled.div``;
export const AddressType = styled.div`
  font-style: italic;
`;
export const AddressName = styled.div``;
export const AddressStreet = styled.span`
  display: block;
`;
export const AddressLocality = styled.span``;
export const AddressRegion = styled.abbr``;
export const AddressPostalCode = styled.span``;
export const AddressCountry = styled.abbr``;

// Person page stuff
export const PersonContent = styled.div`
  position: relative;
  overflow-y: scroll;
  height: 100%;
`;

export const Scroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
`;

export const PersonTabBarActions = styled.div``;

export const EditPersonForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardActions = styled.div`
  margin-left: 8px;
`;

export const CardTable = styled(Table)`
  &&td:first-child {
    padding-left: 0px;
  }
`;

export const TableRow = styled.a`
  display: table-row;
  cursor: pointer;
  text-decoration: none;
  color: black;
  &&:hover {
    background-color: rgba(0, 0, 0, 0.075);
    text-decoration: none;
  }

  &&td:last-of-type {
    text-align: right;
  }
`;

export const AppNavGroup = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const AppNavHeading = styled.span`
  margin: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: ${ui.color.shade_500};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const AppNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const AppNavListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  a {
    width: 100%; // Style the RRNavLink child to be full-width
    text-decoration: none !important;
    /* Add other styles here */
  }
`;

export const AppNavItem = styled.div`
  && {
    font-size: 0.875rem;
    text-decoration: none;
    color: ${ui.color.shade_400};
    font-weight: 500;
    padding: 0.5rem 0.25rem;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:hover {
    background-color: #5d6a75;
    text-decoration: none;
  }
`;

export const AppNavIcn = styled.span`
  width: 1.5rem;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-block;
`;

// From Layout/index.js
export const AppWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${ui.color.shade_200};
  @media only screen and (min-width: 600px) {
    display: flex;
    flex-direction: row;
  }
`;

export const AppHeader = styled.div`
  background-color: ${ui.color.shade_800};
  @media only screen and (min-width: 600px) {
    flex: 0 0 260px;
    display: flex;
    flex-direction: column;
  }
`;

export const AppBranding = styled.div`
  height: 100px;
  flex: 0 0 100px;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const AppBrandingImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  padding: 1rem;
`;

export const AppScroll = styled.div`
  position: relative;
  overflow-y: auto;
`;

export const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const AppContentScroll = styled.div`
  @media only screen and (min-width: 600px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

// From NewPerson.js
export const ButtonIcnNew = styled(ButtonIcn)`
  && {
    margin-left: 0.25rem;
    margin-right: 0px;
  }
`;

// From Details.jsx
export const PersonHeader = styled.div`
  position: relative;
  color: #ffffff;
  margin: 1rem;
  border-radius: 12px;
  overflow: hidden;
`;

export const PersonHeaderContain = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
`;

export const PersonDetails = styled.div`
  padding: 2rem 1rem;
  color: #f1f5f8;
  @media only screen and (min-width: 650px) {
    display: flex;
  }
`;

export const PersonDetailsSection = styled.div`
  flex: 1 1 auto;
  @media only screen and (min-width: 650px) {
    &&:first-of-type {
      flex: 0 0 220px;
      display: flex;
      align-items: center;
    }
  }
`;

export const PersonDetailsName = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 2.25rem;
`;

export const DetailsTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 32px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
`;

export const PersonDetailsEnrollment = styled.p`
  margin-top: 0;
  font-size: 1.125rem;
  font-weight: 700;
  color: #dae1e7;
  text-transform: Capitalize;
`;

export const PersonDetailsContact = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 700;
  @media only screen and (min-width: 1200px) {
    display: flex;
  }
`;

export const PersonDetailsContactItem = styled.li`
  margin: 1rem 0 0 0;
  @media only screen and (min-width: 1200px) {
    margin: 0 2rem 0 0;
  }
`;

export const PersonDetailsLabel = styled.span`
  display: block;
  font-weight: 400;
  font-size: 0.875rem;
`;

export const PersonDetailsValue = styled.span``;

export const HistoryGroup = styled.div`
  padding: 1rem;
  &:nth-of-type(even) {
    background: ${ui.color.shd_300};
  }
`;

export const HistoryItem = styled.div`
  margin: 1rem 0;
`;

export const HistoryHeader = styled.h2`
  font-size: 1rem;
  font-weight: 700;
`;

export const HistoryKey = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const HistoryValue = styled.div`
  font-size: 0.875rem;
  && span:first-of-type {
    font-weight: 700;
    display: block;
  }
  @media only screen and (min-width: 600px) {
    display: flex;
    margin-left: 1rem;
    && span:first-of-type {
      flex: 0 0 48px;
      margin-right: 1rem;
    }
  }
`;

export const StatusHeader = styled.div`
  text-align: right;
`;

export const StatusData = styled.div`
  text-align: right;
`;

export const Description = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Status = styled.span`
  background-color: #ddd;
  text-align: center;
  border-radius: 2px;
  padding: 0.5rem;
  min-width: 5rem;
  display: inline-block;
  font-weight: 700;
`;

export const CheckYes = styled(FontAwesomeIcon)`
  color: #74d67d;
  width: 1rem;
  margin-right: 0.5rem;
  font-size: 0.9rem;
`;

export const CheckNo = styled(FontAwesomeIcon)`
  color: #d67474;
  width: 1rem;
  margin-right: 0.5rem;
`;

// Duplicate from
// export const TableRow = styled.a`
//   display: table-row;
//   cursor: pointer;
//   text-decoration: none;
//   color: black;
//   &&:hover {
//     background-color: rgba(0, 0, 0, 0.075);
//     text-decoration: none;
//   }

//   &&td:last-of-type {
//     text-align: right;
//   }
// `;

export const PeopleSummaryArticle = styled.tr``;

export const PeopleSummaryTitle = styled.h2``;

export const PeopleSummaryPublished = styled.p`
  font-size: 0.8em;
  color: #aaa;
`;

export const PeopleSummaryExcerpt = styled.p`
  font-style: italic;
`;

export const PersonMain = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  @media only screen and (min-width: 600px) {
    display: flex;
  }
`;

export const PersonTabBar = styled.div`
  flex: 0 0 220px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PersonTabs = styled(Nav)`
  display: flex;
  flex-direction: column;
  border-bottom: 0px !important;
  width: 100%;
`;

export const PersonTabContent = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
`;

export const PersonNavItem = styled(NavItem)`
  margin-bottom: 0px !important;
`;

export const PersonNavLink = styled.div`
  && {
    display: block;
    padding: 0.25rem 0.5rem;
    border: 0 !important;
    border-radius: 2px !important;
    font-weight: 700;
    font-size: 0.875rem;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

export const FilterGroupHeader = styled.h5`
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: -1px;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const FilterGroup = styled.ul`
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 0;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const FilterGroupItemHeader = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
`;

export const FormLegend = styled.legend`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.3rem 0 0;
  margin: 0.3rem 0;
`;

export const FilterForm = styled(Form)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SearchGroup = styled(InputGroup)`
  && {
    width: 300px;
    margin: 1rem 0.5rem;
  }
`;

export const StatusSelect = styled(Select)`
  && {
    width: 200px;
    margin: 1rem 0.5rem;
  }
`;

export const FilterButton = styled(Button)`
  && {
    background: none;
    border: none;
    color: #555;
    margin-right: 0.5rem;
  }
`;

// duplicate from personmapfilter
// export const FilterGroup = styled.ul`
//   width: 100%;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-flex-direction: column;
//   flex-direction: column;
//   padding-left: 0;
//   margin-bottom: 0;
// `;

// duplicate from personmapfilter
// export const FilterGroupHeader = styled.h5`
//   position: relative;
//   display: block;
//   padding: 0.5rem 0.75rem;
//   margin-bottom: -1px;
//   background-color: #fff;
//   border: 1px solid rgba(0, 0, 0, 0.125);
//   font-size: 1.25rem;
// `;

export const FilterGroupItem = styled.li`
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;

// Using an anchor as a table row may be bad practice.
// We will see if it causes issues.
// We are doing this so the entire row shows the link info.
export const Tribe = styled.a`
  display: table-row;
  cursor: pointer;
  text-decoration: none;
  color: black;
  &&:hover {
    background-color: #1e848a;
    text-decoration: none;
    color: #fff;
  }

  &&td:last-of-type {
    text-align: right;
  }
`;

export const AddressLine = styled.span`
  display: block;
`;

export const Items = styled(Input)`
  && {
    font-size: 0.75em;
  }
`;

// Create the keyframes
const load = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

// Here we create a component that will rotate everything we pass in over two seconds
export const CircleLoader = styled.div`
  border-radius: 50%;
  width: 4em;
  height: 4em;
  margin: 5px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${load} 1.1s infinite linear;
  animation: ${load} 1.1s infinite linear;
  &&:after {
    border-radius: 50%;
    width: 4em;
    height: 4em;
  }
`;

export const Loader = styled.div`
  text-align: center;
  margin: 60px auto;
`;

export const Count = styled.div`
  padding: 0.375rem 0;
  margin: 2px 0;
  font-size: 0.75em;
`;

export const Paginator = styled.div`
  padding: 0.375rem 0;
  margin: 2px 0;
`;

export const PageOf = styled.span`
  padding: 0.375rem 0;
  margin: 2px 0;
  font-size: 0.75em;
`;

export const Sort = styled.span`
  cursor: pointer;
  background: #eee;
  padding: 2px 5px;
  border-radius: 4px;
  float: left;
  margin-right: 0.5rem;
`;

export const SortRight = styled.span`
  cursor: pointer;
  background: #eee;
  padding: 2px 5px;
  border-radius: 4px;
  float: right;
  margin-left: 0.5rem;
`;

export const SortArrow = styled(FontAwesomeIcon)`
  display: none;
  @media only screen and (min-width: 1800px) {
    display: inline;
    margin-left: 0.5rem;
  }
`;
