import React from "react";
import { Query } from "@apollo/client/react/components";
import { Card, CardBody, Col, FormGroup, Label, Row, Button } from "reactstrap";
import AccountDropdown from "../../Account/AccountDropdown";
import {
  ButtonIcn,
  Page,
  PageHeader,
  PageHeaderSection,
  PageTitle,
} from "../../components/index";
import { ui } from "../../Constants";
import { checkForErrors, capitalize } from "../../Utils";
import { DEPARTMENT } from "./graphql";

const { QUERY } = DEPARTMENT;

const valueOrPlaceholder = (value) => value || "-";

export const DepartmentDetails = ({ match, history }) => {
  const departmentType = "departments";
  const viewerFieldName = "department";
  const query = QUERY;
  return (
    <Page>
      <PageHeader>
        <PageHeaderSection>
          <Button type="button" onClick={() => history.goBack()}>
            <ButtonIcn>{ui.icon.chevron_left}</ButtonIcn>Go Back
          </Button>
        </PageHeaderSection>
        <PageHeaderSection>
          <PageTitle>
            <strong>{capitalize(viewerFieldName)}</strong>
          </PageTitle>
        </PageHeaderSection>
        <PageHeaderSection>
          <AccountDropdown />
        </PageHeaderSection>
      </PageHeader>

      <Query query={query} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            `${departmentType} list`
          );
          if (loadingOrError) {
            return loadingOrError;
          }
          const department = data.viewer[viewerFieldName];

          return (
            <Card>
              <CardBody>
                <Row>
                  <Column
                    label={`${viewerFieldName} Name`}
                    value={valueOrPlaceholder(department.name)}
                    hideNull
                  />
                  <Column
                    label="Phone Number"
                    value={valueOrPlaceholder(department.phoneNumber)}
                    hideNull
                  />
                </Row>
                <Row>
                  <Column
                    label="Street 1"
                    value={valueOrPlaceholder(department.street1)}
                    hideNull
                    width={12}
                  />
                </Row>
                <Row>
                  <Column
                    label="Street 2"
                    value={valueOrPlaceholder(department.street2)}
                    hideNull
                    width={12}
                  />
                </Row>
                <Row>
                  <Column
                    label="Street 3"
                    value={valueOrPlaceholder(department.street3)}
                    hideNull
                    width={12}
                  />
                </Row>
                <Row>
                  <Column
                    label="City"
                    value={valueOrPlaceholder(department.city)}
                  />
                  <Column
                    label="State"
                    value={valueOrPlaceholder(department.state)}
                  />
                  <Column
                    label="Zip Code"
                    value={valueOrPlaceholder(department.zipCode)}
                  />
                </Row>
              </CardBody>
            </Card>
          );
        }}
      </Query>
    </Page>
  );
};

const Column = ({ label, value, hideNull, width = 4 }) => {
  if (hideNull && !value) return null;
  return (
    <Col sm={width}>
      <FormGroup>
        <Label>{label}</Label>
        <strong>{value}</strong>
      </FormGroup>
    </Col>
  );
};
