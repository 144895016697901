import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

library.add(fas);

const DepartmentTableRow = ({ departmentType, department, history }) => (
  <tr onClick={() => history.push(`/${departmentType}/${department.id}`)}>
    <td>{department.id}</td>
    <td>{department.name}</td>
    <td>{department.phoneNumber}</td>
    <td>
      {department.street1} {department.street2} {department.city}{" "}
      {department.state} {department.zipCode}
    </td>
  </tr>
);

DepartmentTableRow.propTypes = {
  department: PropTypes.shape.isRequired,
};

export default withRouter(DepartmentTableRow);
