import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import GridItem from "./GridItem";

const Grid = (props) => {
  const { People, flush, ...rest } = props;

  const PeopleGrid = People.map((person, i) => {
    return i % 4 === 0 ? People.slice(i, i + 4) : null;
  }).filter((x) => x != null);
  return (
    <Container fluid>
      {PeopleGrid.map((grid, index) => {
        return (
          /* eslint-disable-next-line react/no-array-index-key */
          <Row key={index}>
            {grid.map((person) => (
              <Col key={person.id} sm={3} className="mb-4">
                <GridItem
                  key={`person-grid-item-${person.id}`}
                  {...rest}
                  person={person}
                />
              </Col>
            ))}
          </Row>
        );
      })}
    </Container>
  );
};

Grid.propTypes = {
  People: PropTypes.array.isRequired,
};

export default Grid;
