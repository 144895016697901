import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "@apollo/client/react/components";
import {
  PersonHeader,
  PersonHeaderContain,
  PersonDetails,
  PersonDetailsSection,
  PersonDetailsName,
} from "../../components";
import { PERSON } from "./graphql";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES } from "../../Constants";
import DetailsInfoEnrollment from "./DetailsInfoEnrollment";
import { Avatar, Can, checkForErrors } from "../../Utils";

class Details extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "person details"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          return (
            <PersonHeader className="person-header">
              <PersonHeaderContain>
                <PersonDetails>
                  <PersonDetailsSection>
                    <Avatar
                      id={person.id}
                      sex={person.sex}
                      firstName={person.firstName}
                      lastName={person.lastName}
                      styles={{
                        display: "block",
                        borderRadius: "100%",
                        border: "2px solid #fff",
                        margin: "0px",
                        width: "140px",
                        height: "140px",
                        objectFit: "cover",
                      }}
                    />
                  </PersonDetailsSection>

                  <PersonDetailsSection>
                    <PersonDetailsName>
                      {person.first_name} {person.last_name}, {person.age}
                    </PersonDetailsName>
                    <Can grantRoles={[ROLES.ENROLLMENT, ROLES.REFERRED]}>
                      <DetailsInfoEnrollment person={person} />
                    </Can>
                  </PersonDetailsSection>
                </PersonDetails>
              </PersonHeaderContain>
            </PersonHeader>
          );
        }}
      </Query>
    );
  }
}

Details.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

Details.defaultProps = {};

export default Details;
