import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "reactstrap";
import GroupItem from "./GroupItem";

const Group = (props) => {
  const { People, flush, ...rest } = props;

  return (
    <ListGroup flush={flush}>
      {People.map((person) => (
        <GroupItem
          key={`person-summary-${person.id}`}
          {...rest}
          person={person}
        />
      ))}
    </ListGroup>
  );
};

Group.propTypes = {
  People: PropTypes.array.isRequired,
};

export default Group;
