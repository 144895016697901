import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
} from "../../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES } from "../../../Constants";
import { Can, checkForErrors } from "../../../Utils";

import New from "../../New";

import FamilyModalForm from "./FamilyModalForm";
import { PERSON } from "./graphql";

class Actions extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.ACTIONS_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "family actions"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          const child = {};

          child.mother = person.sex === "female" ? person.id : null;
          child.father = person.sex === "male" ? person.id : null;
          child.memberStatus = "applying";

          return (
            <Can grantRoles={[ROLES.ENROLLMENT]}>
              <Panel id="family-actions">
                <PanelHeader>
                  <PanelTitle>Actions</PanelTitle>
                  <CardActions>
                    <New person={child}>Enroll Child</New>{" "}
                    <FamilyModalForm title="Edit Parents" person={person}>
                      Edit Parents
                    </FamilyModalForm>
                  </CardActions>
                </PanelHeader>
              </Panel>
            </Can>
          );
        }}
      </Query>
    );
  }
}

Actions.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

Actions.defaultProps = {};

export default Actions;
