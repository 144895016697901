import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

import logo from "../../asset/logo-cdatribe.png";
import { TRIBE_NAME } from "../../Constants";
import { formatDate } from "../../Utils";

import { common, layout, p } from "../styles";

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  logo: {
    width: 60,
    height: 60,
  },
  pager: {
    alignItems: "flex-end",
    flexGrow: 1,
    paddingTop: 10,
    fontSize: 10,
  },
});

export default ({ title, ...props }) => (
  <View style={styles.container} {...props}>
    <View style={layout.row}>
      <View style={layout.col}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={[layout.col, p[10]]}>
        <Text style={common.large}>
          {TRIBE_NAME} Tribe {title}
        </Text>
        <Text style={common.small}>As of {formatDate(new Date())}</Text>
      </View>
      <View style={[layout.col, styles.pager]}>
        <Text
          render={({ pageNumber, totalPages }) => {
            return `Page ${pageNumber} of ${totalPages}`;
          }}
          fixed
        />
      </View>
    </View>
  </View>
);
