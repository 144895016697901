import { Mutation } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButton } from "../../../components";
import { ui } from "../../../Constants";
import { TRIBES } from "../../../Tribes/graphql";
import {
  convertDataToSchema,
  MutationSubmit,
  QuerySelect,
} from "../../../Utils";

import { PERSON } from "../graphql";

import { DEFAULT_BLOOD_QUANTUM_INPUT } from "./constants";
import { CREATE_BLOOD_QUANTUM } from "./graphql";

class BloodQuantumModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      blood_quantum: { ...props.blood_quantum },
    };
  }

  _handleNumberChange = (e) => {
    const { blood_quantum: bloodQuantum } = this.state;
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;

    // API is expecting numerator and denominator to be int
    bloodQuantum[target.name] = parseInt(value, 10);
    this.setState(bloodQuantum);
  };

  _handleSelectChange = (name, select) => {
    const { blood_quantum: bloodQuantum } = this.state;
    bloodQuantum[name] = select ? select.value : select;
    this.setState(bloodQuantum);
  };

  _toggleModal = () => {
    const { blood_quantum: bloodQuantum } = this.props;

    this.setState((state) => ({
      modal: !state.modal,
      blood_quantum: { ...bloodQuantum },
    }));
  };

  render() {
    const { blood_quantum: bloodQuantum, modal } = this.state;
    const { children, right, className } = this.props;

    const bloodQuantumInput = convertDataToSchema(
      bloodQuantum,
      DEFAULT_BLOOD_QUANTUM_INPUT
    );

    const defaultTribe =
      bloodQuantum.id && bloodQuantum.tribe
        ? {
            value: bloodQuantum.id,
            label: bloodQuantum.tribe.name,
          }
        : null;

    return (
      <>
        <ActionButton
          id="modal-btn"
          onClick={this._toggleModal}
          className={`btn-secondary ${right ? "float-right" : ""}`}
          size="sm"
        >
          {children || <>{ui.icon.plus} New</>}
        </ActionButton>
        <Modal isOpen={modal} toggle={this._toggleModal} className={className}>
          <ModalHeader toggle={this.toggle}>Add Blood Quantum</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="tribe">Tribe</Label>
              <QuerySelect
                name="tribe"
                id="tribe"
                value="tribe"
                defaultValue={defaultTribe}
                query={TRIBES.SELECTQUERY}
                viewer="tribes"
                isSearchable
                isClearable
                placeholder="Select Tribe..."
                handleChange={this._handleSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="numerator">Blood Quantum</Label>
              <InputGroup>
                <Input
                  type="number"
                  id="numerator"
                  name="numerator"
                  defaultValue={bloodQuantum.numerator}
                  placeholder="Numerator"
                  onChange={(e) => this._handleNumberChange(e)}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>/</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="denominator"
                  defaultValue={bloodQuantum.denominator}
                  placeholder="Denominator"
                  onChange={(e) => this._handleNumberChange(e)}
                />
              </InputGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={CREATE_BLOOD_QUANTUM}
              variables={{ blood_quantum: bloodQuantumInput }}
              onCompleted={() => {
                Toasts.success("Blood quantum added");
                this._toggleModal();
              }}
              onError={(error) =>
                Toasts.danger("Unable to add blood quantum", error)
              }
              refetchQueries={[
                {
                  query: PERSON.BLOOD_QUANTA_QUERY,
                  variables: { person: bloodQuantum.person },
                },
              ]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="submit-btn"
                  color="warning"
                  label="Save"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

BloodQuantumModalForm.propTypes = {
  blood_quantum: PropTypes.shape({
    id: PropTypes.string,
    numerator: PropTypes.number,
    denominator: PropTypes.number,
    tribe: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  right: PropTypes.bool,
};

BloodQuantumModalForm.defaultProps = {};

export default BloodQuantumModalForm;
