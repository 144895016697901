import { gql } from "@selfdetermine/graphql";

// Create mutation
export const CREATE_PERSON_MUTATION = gql`
  mutation CreatePerson($person: PersonInput) {
    createPerson(person: $person) {
      id
    }
  }
`;

// People
export const PEOPLE = {
  SELECT_QUERY: gql`
    query PeopleSelect($filters: PeopleFiltersInput, $sort: SortInput) {
      viewer {
        people(filters: $filters, sort: $sort) {
          nodes {
            value: id
            firstName
            lastName
            bornAt
          }
        }
      }
    }
  `,
  MAP_QUERY: gql`
    query PeopleMap {
      viewer {
        residences(isGeocoded: true) {
          id
          latitude
          longitude
          personId
        }
      }
    }
  `,
  QUERY: gql`
    query People(
      $first: Int!
      $end: String
      $filters: PeopleFiltersInput
      $sort: SortInput
    ) {
      viewer {
        people(first: $first, filters: $filters, sort: $sort, after: $end) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              member_status: memberStatus
              first_name: firstName
              middle_name: middleName
              last_name: lastName
              sex
              suffix
              membership_number: membershipNumber
              born_at: bornAt
              deceasedAt
            }
            cursor
          }
        }
      }
    }
  `,
};
