import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, FormGroup, Label, CardBody } from "reactstrap";
import Moment from "react-moment";

import { PanelHeader, PanelTitle, PanelActions } from "../../../components";
import {
  DEFAULT_QUERY_ERROR_POLICY,
  DEFAULT_SERVER_DATETIME_FORMAT,
  DEFAULT_SERVICE_BRANCH,
  ROLES,
} from "../../../Constants";
import { Can, checkForErrors, SocialSecurity } from "../../../Utils";

import { PERSON } from "../graphql";

import GeneralModalForm from "./GeneralModalForm";
import MedicalRecordNumberModal from "./MedicalRecordNumberModal";

class General extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.GENERAL_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "general"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          return (
            <>
              <PanelHeader>
                <PanelTitle>General</PanelTitle>
                <Can grantRoles={[ROLES.ENROLLMENT]}>
                  <PanelActions>
                    <GeneralModalForm
                      title="Edit Identification"
                      person={person}
                    >
                      Edit
                    </GeneralModalForm>
                  </PanelActions>
                </Can>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.REENTRY,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>First Name</Label>
                        <strong id="person-first-name">
                          {person.firstName}
                        </strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Middle Name</Label>
                        <strong>{person.middleName}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.REENTRY,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <strong>{person.lastName}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Suffix</Label>
                        <strong>{person.suffix}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Maiden Name</Label>
                        <strong>{person.maidenName}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Social Security Number</Label>
                        <SocialSecurity social={person.ssn} />
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Marital Status</Label>
                        <strong>{person.maritalStatus}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Head of Household</Label>
                        <strong>{person.headOfHousehold ? "Yes" : "No"}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Minor?</Label>
                        <strong>{person.isMinor ? "Yes" : "No"}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                <Row>
                  <Can grantRoles={[ROLES.REENTRY]}>
                    <Query
                      query={PERSON.MEDICAL_RECORD_NUMBER_QUERY}
                      variables={this._getQueryVariables()}
                      errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
                    >
                      {(medicalRecord) => {
                        const medicalRecordError = checkForErrors(
                          medicalRecord.loading,
                          medicalRecord.error,
                          medicalRecord.data,
                          "medical record number"
                        );
                        if (medicalRecordError) {
                          return medicalRecordError;
                        }

                        return (
                          <Col sm={6} md={4}>
                            <FormGroup>
                              <Label>
                                Medical Record Number
                                <MedicalRecordNumberModal
                                  title="Edit Medical Record Number"
                                  person={data.viewer.person}
                                >
                                  Edit
                                </MedicalRecordNumberModal>
                              </Label>
                              <strong>{person.medicalRecordNumber}</strong>
                            </FormGroup>
                          </Col>
                        );
                      }}
                    </Query>
                  </Can>
                </Row>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.REENTRY,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Birth Date</Label>
                        <strong>
                          <Moment
                            date={person.bornAt}
                            format="MM/DD/YYYY"
                            parse={DEFAULT_SERVER_DATETIME_FORMAT}
                          />
                        </strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Birth Place</Label>
                        <strong>{person.birthplace}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Deceased Date</Label>
                        {person.deceasedAt && (
                          <strong>
                            <Moment
                              date={person.deceasedAt}
                              format="MM/DD/YYYY"
                              parse={DEFAULT_SERVER_DATETIME_FORMAT}
                            />
                          </strong>
                        )}
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                {person.pre1900_born_at && (
                  <Row>
                    <Can
                      grantRoles={[
                        ROLES.ENROLLMENT,
                        ROLES.SELF,
                        ROLES.CIVIL_ATTORNEY,
                      ]}
                    >
                      <Col sm={6} md={4}>
                        <FormGroup>
                          <Label>Birth Date (Pre-1900)</Label>
                          {person.pre1900BornAt && (
                            <strong>
                              <Moment
                                date={person.pre1900BornAt}
                                format="MM/DD/YYYY"
                                parse={DEFAULT_SERVER_DATETIME_FORMAT}
                              />
                            </strong>
                          )}
                        </FormGroup>
                      </Col>
                    </Can>
                  </Row>
                )}
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Height</Label>
                        <strong>{person.height}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Weight</Label>
                        <strong>{person.weight}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.REENTRY,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Sex</Label>
                        <strong>
                          {person.sex.charAt(0).toUpperCase() +
                            person.sex.slice(1)}
                        </strong>
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Hair Color</Label>
                        <strong>{person.hairColor}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label>Eye Color</Label>
                        <strong>{person.eyeColor}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label for="veteran">Veteran</Label>
                        <strong>{person.veteran ? "Yes" : "No"}</strong>
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label for="service_start">Service Started</Label>
                        {person.veteran && person.serviceStartedAt ? (
                          <strong>
                            <Moment
                              date={person.serviceStartedAt}
                              format="MM/DD/YYYY"
                              parse={DEFAULT_SERVER_DATETIME_FORMAT}
                            />
                          </strong>
                        ) : (
                          <strong />
                        )}
                      </FormGroup>
                    </Col>
                  </Can>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label for="service_end">Service Ended</Label>
                        {person.veteran && person.serviceEndedAt ? (
                          <strong>
                            <Moment
                              date={person.serviceEndedAt}
                              format="MM/DD/YYYY"
                              parse={DEFAULT_SERVER_DATETIME_FORMAT}
                            />
                          </strong>
                        ) : (
                          <strong />
                        )}
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
                <Row>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.SELF,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <Col sm={6} md={4}>
                      <FormGroup>
                        <Label for="branch_of_service">Branch of Service</Label>
                        <strong>
                          {DEFAULT_SERVICE_BRANCH[person.branchOfService]}
                        </strong>
                      </FormGroup>
                    </Col>
                  </Can>
                </Row>
              </CardBody>
            </>
          );
        }}
      </Query>
    );
  }
}

General.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

General.defaultProps = {};

export default General;
