import React, { Component } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { Error } from "../components";
import { getPersonName, Loading } from "../Utils";

class Tree extends Component {
  // Toggle children on click.
  _handleClick = (d) => {
    this.props.handleClick(d);
  };

  _renderTree() {
    const { id, data } = this.props;

    const diagonal = (s, d) => {
      const path = `M ${s.y} ${s.x}
                  C ${(s.y + d.y) / 2} ${s.x},
                    ${(s.y + d.y) / 2} ${d.x},
                    ${d.y} ${d.x}`;

      return path;
    };

    // Set the dimensions and margins of the diagram
    const margin = { top: 0, right: 50, bottom: 50, left: 200 };
    const width = 800;
    const height = 350;

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    d3.select(`#${id}`).selectAll("svg").remove();
    d3.select(`#${id}`).selectAll("g").remove();

    const svg = d3
      .select(`#${id}`)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMinYMin")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const i = 0;
    const duration = 750;
    // declares a tree layout and assigns the size
    const treemap = d3.tree().size([height, width]);

    // Assigns parent, children, height, depth
    const root = d3.hierarchy(data, (d) => {
      return d.children;
    });
    root.x0 = height / 2;
    root.y0 = 0;

    // Assigns the x and y position for the nodes
    const treeData = treemap(root);

    // Compute the new tree layout.
    const nodes = treeData.descendants();
    const links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach((d) => {
      /* Wasn't able to determine what the best way to do this is; so this seems like an ok exception to the rule. */
      /* eslint-disable-next-line no-param-reassign */
      d.y = d.depth * 180;
    });

    // ****************** Nodes section ***************************

    // Update the nodes...
    const node = svg.selectAll("g.node").data(nodes, (d) => d.id || i);

    // Enter any new modes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", () => {
        return `translate(${root.y0},${root.x0})`;
      })
      .on("click", this._handleClick);

    // Add Circle for the nodes
    nodeEnter
      .append("circle")
      .attr("class", "node")
      .attr("r", 1e-6)
      .style("fill", function (d) {
        return d._children ? "lightsteelblue" : "#fff";
      });

    // Add labels for the nodes
    nodeEnter
      .append("text")
      .attr("dy", ".35em")
      .attr("x", function (d) {
        return d.children || d._children ? -13 : 13;
      })
      .attr("text-anchor", function (d) {
        return d.children || d._children ? "end" : "start";
      })
      .text(function (d) {
        return getPersonName(d.data);
      });

    // UPDATE
    const nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        return `translate(${d.y},${d.x})`;
      });

    // Update the node attributes and style
    nodeUpdate
      .select("circle.node")
      .attr("r", 10)
      .style("fill", function (d) {
        return d._children ? "lightsteelblue" : "#fff";
      })
      .attr("cursor", "pointer");

    // Remove any exiting nodes
    const nodeExit = node
      .exit()
      .transition()
      .duration(duration)
      .attr("transform", function () {
        return `translate(${root.y},${root.x})`;
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select("circle").attr("r", 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select("text").style("fill-opacity", 1e-6);

    // ****************** links section ***************************

    // Update the links...
    const link = svg.selectAll("path.link").data(links, function (d) {
      return d.id;
    });

    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .attr("d", function () {
        const o = { x: root.x0, y: root.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    const linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(duration)
      .attr("d", function (d) {
        return diagonal(d, d.parent);
      });

    // Store the old positions for transition.
    nodes.forEach(function (d) {
      /* eslint-disable-next-line no-param-reassign */
      d.x0 = d.x;
      /* eslint-disable-next-line no-param-reassign */
      d.y0 = d.y;
    });
  }

  render() {
    const { id, data, error, loading } = this.props;

    setTimeout(() => {
      if (data) {
        this._renderTree();
      }
    }, 500);

    return (
      <div id={id} className="tree-chart">
        {loading && <Loading>Loading chart data...</Loading>}

        {error && (
          <Error>
            <h3>Error:</h3>
            <p>{error}</p>
          </Error>
        )}
      </div>
    );
  }
}

Tree.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Tree;
