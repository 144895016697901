import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, Label } from "reactstrap";

import {
  filterSelfFromPeople,
  getPersonName,
  peopleSearchGetOptions,
  peopleSearchQueryVars,
  QuerySelect,
} from "../../../Utils";

import { PEOPLE } from "../../graphql";

class FamilyInput extends Component {
  _handleChange = (e) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.props.handleChange("person", name, value);
  };

  _handleSelectChange = (name, select) => {
    const value = select ? select.value : select;
    this.props.handleChange("person", name, value);
  };

  render() {
    const { person } = this.props;

    const father = person.father
      ? { value: person.father.id, label: getPersonName(person.father) }
      : null;
    const mother = person.mother
      ? { value: person.mother.id, label: getPersonName(person.mother) }
      : null;

    return (
      <>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>Father</Label>
              <QuerySelect
                name="father"
                getQueryVariables={peopleSearchQueryVars}
                sex="male"
                getOptions={peopleSearchGetOptions}
                value="father"
                defaultValue={father}
                query={PEOPLE.SELECT_QUERY}
                viewer="people"
                handleChange={this._handleSelectChange}
                placeholder="Search People..."
                isSearchable
                isClearable
                filterFunction={(data) => filterSelfFromPeople(person, data)}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>Mother</Label>
              <QuerySelect
                name="mother"
                getQueryVariables={peopleSearchQueryVars}
                sex="female"
                getOptions={peopleSearchGetOptions}
                value="mother"
                defaultValue={mother}
                query={PEOPLE.SELECT_QUERY}
                viewer="people"
                handleChange={this._handleSelectChange}
                placeholder="Search People..."
                isSearchable
                isClearable
                filterFunction={(data) => filterSelfFromPeople(person, data)}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

FamilyInput.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

FamilyInput.defaultProps = {};

export default FamilyInput;
