import { Query } from "@apollo/client/react/components";
import React, { Component } from "react";

import {
  Panel,
  PanelHeader,
  PanelTitle,
  EmptyListing,
} from "../../../../components";
import { checkForErrors } from "../../../../Utils";

import NewCase from "../New";

import { MATTER } from "./graphql";
import { MattersTable } from "./MattersTable";

class MattersListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {
        column: "created_at",
        direction: "ASC",
      },
    };
    this.refetch = null;
  }

  _handleSort = (e, sortBy, direction) => {
    this._updateQuery({
      sort: {
        column: sortBy,
        direction,
      },
    });
  };

  _updateQuery = (state) => {
    this.setState(state, () => {
      if (this.refetch) {
        this.refetch();
      }
    });
  };

  _setRefetch = (refetch) => {
    this.refetch = refetch;
  };

  _getQueryVariables = () => {
    const { sort } = this.state;
    return {
      person: this.props.match.params.person,
      sort,
    };
  };

  render() {
    return (
      <Query query={MATTER.LIST_QUERY} variables={this._getQueryVariables()}>
        {({ loading, error, data, refetch }) => {
          this._setRefetch(refetch);

          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "matters"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const matters = data.viewer.matters.edges.map(({ node }) => node);
          const { sort } = this.state;

          return (
            <Panel id="overview">
              <PanelHeader>
                <PanelTitle>Civil Matters</PanelTitle>
                <NewCase
                  caseType="Civil Court"
                  person={this.props.person}
                  getQueryVars={this._getQueryVariables}
                />
              </PanelHeader>
              <MattersTable
                matters={matters}
                {...this.props}
                sortBy={sort.column}
                direction={sort.direction}
                handleSort={this._handleSort}
              />
              {!matters.length && (
                <EmptyListing {...this.props}>No Matters</EmptyListing>
              )}
            </Panel>
          );
        }}
      </Query>
    );
  }
}

export default MattersListing;
