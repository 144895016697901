import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardImg, CardBody, CardTitle, CardSubtitle } from "reactstrap";

class GridItem extends Component {
  _handleClick = (e, person) => {
    window.open(`/people/${person.id}`, "_blank");
  };

  render() {
    const { person } = this.props;
    return (
      <Card
        onClick={(e) => this._handleClick(e, person)}
        className="clickable"
        action="true"
      >
        <CardImg
          className="img-fluid person__photo"
          alt={`${person.first_name} ${person.middle_name} ${person.last_name}`}
          src="https://via.placeholder.com/220x220"
        />

        <CardBody>
          <CardTitle>{person.full_name}</CardTitle>
          <CardSubtitle>{person.membership_number}</CardSubtitle>
        </CardBody>
      </Card>
    );
  }
}

GridItem.propTypes = {
  person: PropTypes.shape.isRequired,
};

export default GridItem;
