import PropTypes from "prop-types";
import React from "react";

import { Panel } from "../../../components";
import { ROLES } from "../../../Constants";
import { withUserRole } from "../../../Utils";

import General from "./General";

const Information = ({ handleChange, handleSubmit, ...props }) => {
  return (
    <Panel id="information">
      <General {...props} />
    </Panel>
  );
};

Information.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

Information.defaultProps = {};

const wrappedWithUserRole = withUserRole(Information, [
  ROLES.CIVIL_ATTORNEY,
  ROLES.ENROLLMENT,
  ROLES.REENTRY,
  ROLES.SELF,
  ROLES.FINANCE,
]);

export default wrappedWithUserRole;
