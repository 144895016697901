import { gql } from "@selfdetermine/graphql";

export const CREATE_BLOOD_QUANTUM = gql`
  mutation CreatePersonBloodQuantum($blood_quantum: BloodQuantumInput) {
    createPersonBloodQuantum(bloodQuantum: $blood_quantum) {
      id
    }
  }
`;

export const DELETE_BLOOD_QUANTUM = gql`
  mutation DeleteBloodQuantum($person: ID!, $bloodQuantum: ID!) {
    deleteBloodQuantum(person: $person, bloodQuantum: $bloodQuantum) {
      id
    }
  }
`;
