import React from "react";
import {
  AppWrapper,
  AppHeader,
  AppBranding,
  AppBrandingImg,
  AppScroll,
  AppContent,
  AppContentScroll,
} from "../components";
import { AppNav } from "./AppNav";
import logo from "../asset/logo-cdatribe.png";

const Layout = (props) => {
  return (
    <AppWrapper>
      <AppHeader>
        <AppBranding>
          <AppBrandingImg src={logo} />
        </AppBranding>
        <AppScroll>
          <AppNav />
        </AppScroll>
      </AppHeader>
      <AppContent>
        <AppContentScroll>{props.children}</AppContentScroll>
      </AppContent>
    </AppWrapper>
  );
};

export default Layout;
