import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";

import Tree from "../../../Charts/Tree";
import { DEFAULT_QUERY_ERROR_POLICY } from "../../../Constants";
import { checkForErrors } from "../../../Utils";

import { PERSON } from "./graphql";

class FamilyTree extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  _handleClick = (d) => {
    if (d.data.id) {
      this.props.history.push(`/people/${d.data.id}/enrollment/overview`);
    }
  };

  render() {
    return (
      <Query
        query={PERSON.FAMILY_TREE_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "family tree"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          const tree = {
            firstName: person.firstName,
            lastName: person.lastName,
            children: person.children,
          };

          return (
            <Tree
              id="family-tree"
              data={tree}
              handleClick={this._handleClick}
            />
          );
        }}
      </Query>
    );
  }
}

FamilyTree.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

FamilyTree.defaultProps = {};

export default FamilyTree;
