import { gql } from "@selfdetermine/graphql";

// Create mutation
export const CREATE_TRIBE_MUTATION = gql`
  mutation addTribe($tribe: NewTribeInput!) {
    addTribe(tribe: $tribe) {
      id
    }
  }
`;

// Tribes
export const TRIBES = {
  SELECTQUERY: gql`
    query TribeSelect($search: String) {
      viewer {
        tribes(search: $search) {
          value: id
          label: name
        }
      }
    }
  `,
  QUERY: gql`
    query Tribes {
      viewer {
        tribes {
          id
          name
        }
      }
    }
  `,
};
