import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

class GetNotesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  createPDF = () => {
    window.location.href = `/people/${this.props.person}/enrollment-verification?note=${this.state.value}`;
    this.setState({
      value: "",
    });
    this.props.toggle();
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className={this.props.classNam}
          size="md"
        >
          <ModalHeader toggle={this.props.toggle}>
            Create Enrollment Verification
          </ModalHeader>
          <ModalBody>
            <Label for="descendent_name">
              Append a short note (social security, verification reason)
            </Label>
            <Input
              value={this.state.value}
              onChange={this.onChange}
              type="text"
              name="note"
              id="note_name"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
            <Button color="success" onClick={this.createPDF}>
              Create PDF
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

GetNotesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  person: PropTypes.string.isRequired,
};

GetNotesModal.defaultProps = {};

export default GetNotesModal;
