import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image as RenderImage,
} from "@react-pdf/renderer";
import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Bar, BarChart, Legend, XAxis, YAxis } from "recharts";

import { Loading } from "../../Utils";

import { PageReports } from "../components";
import { common, layout, table, p, w } from "../styles";

import { AGE_BREAKDOWN } from "./graphql";
import ReportsHeader from "./ReportsHeader";
import ChartManager from "./ChartManager";

// Create styles
const styles = StyleSheet.create({
  image: {
    width: "100%",
  },
  ageHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
});

// Create Document Component
const AgeBreakdownDocument = ({ data, charts }) => {
  let totalMembers = 0;
  let totalFemales = 0;
  let totalMales = 0;
  let onResMailingFemales = 0;
  let onResMailingMales = 0;
  let onResPhysicalFemales = 0;
  let onResPhysicalMales = 0;
  let offResMailingFemales = 0;
  let offResMailingMales = 0;
  let offResPhysicalFemales = 0;
  let offResPhysicalMales = 0;

  console.log({ data });

  return (
    <Document title="Enrollment Age Breakdown">
      <Page size="A4" style={[common.page, p.bottom35]} wrap>
        <ReportsHeader title="Enrollment Age Breakdown" fixed />
        <View style={[layout.colMd12, p.x20]}>
          <View>
            <View style={[common.textCenter, common.h4]}>
              <Text>Enrolled by Age Group</Text>
            </View>
            <RenderImage style={styles.image} src={charts.ages} />
          </View>
          <View>
            <View style={[common.textCenter, common.h4]}>
              <Text>Enrolled Males by Age</Text>
            </View>
            <RenderImage style={styles.image} src={charts.males} />
          </View>
          <View>
            <View style={[common.textCenter, common.h4]}>
              <Text>Enrolled Females by Age</Text>
            </View>
            <RenderImage style={styles.image} src={charts.females} />
          </View>
          <View>
            <View style={[common.textCenter, common.h4]}>
              <Text>Enrolled On Reservation by Age</Text>
            </View>
            <RenderImage style={styles.image} src={charts.onRes} />
          </View>
          <View>
            <View style={[common.textCenter, common.h4]}>
              <Text>Enrolled Off Reservation by Age</Text>
            </View>
            <RenderImage style={styles.image} src={charts.offRes} />
          </View>
          <View style={[common.textCenter, common.h4]} break>
            <Text>(P/M) = Physical/Mailing Address</Text>
          </View>
          <View style={[table.table]}>
            <View style={table.headerRow}>
              <View style={[w["10%"], table.headerCol, styles.ageHeader]}>
                <Text style={[table.headerCell]}>Age</Text>
              </View>
              <View style={[w["30%"], table.headerCol]}>
                <View style={[table.headerRow, common.textCenter]}>
                  <Text style={[table.headerCell]}>Totals</Text>
                </View>
                <View style={[table.headerRow, common.textCenter]}>
                  <Text style={[w["33%"], table.headerCell]}>Members</Text>
                  <Text style={[w["33%"], table.headerCell]}>Females</Text>
                  <Text style={[w["33%"], table.headerCell]}>Males</Text>
                </View>
              </View>
              <View style={[w["30%"], table.headerCol, table.highlight]}>
                <View style={[table.headerRow, common.textCenter]}>
                  <Text style={[table.headerCell]}>On Reservation</Text>
                </View>
                <View style={[table.headerRow, common.textCenter]}>
                  <Text style={[w["50%"], table.headerCell]}>
                    Females (P/M)
                  </Text>
                  <Text style={[w["50%"], table.headerCell]}>Males (P/M)</Text>
                </View>
              </View>
              <View style={[w["30%"], table.headerCol]}>
                <View style={[table.headerRow, common.textCenter]}>
                  <Text style={[table.headerCell]}>Off Reservation</Text>
                </View>
                <View style={[table.headerRow, common.textCenter]}>
                  <Text style={[w["50%"], table.headerCell]}>
                    Females (P/M)
                  </Text>
                  <Text style={[w["50%"], table.headerCell]}>Males (P/M)</Text>
                </View>
              </View>
            </View>
            {data.map((g, index) => {
              let rowStyle = table.row;
              if (index % 2 === 0) {
                rowStyle = [table.row, table.zebra];
              }
              totalMembers += g.totalMembers;
              totalFemales += g.totalFemales;
              totalMales += g.totalMales;
              onResPhysicalFemales += g.onResPhysicalFemales;
              onResMailingFemales += g.onResMailingFemales;
              onResPhysicalMales += g.onResPhysicalMales;
              onResMailingMales += g.onResMailingMales;
              offResPhysicalFemales += g.offResPhysicalFemales;
              offResMailingFemales += g.offResMailingFemales;
              offResPhysicalMales += g.offResPhysicalMales;
              offResMailingMales += g.offResMailingMales;
              return (
                <View key={g.ageGroup} style={rowStyle}>
                  <View style={[w["10%"], table.col, common.textLeft]}>
                    <Text style={table.cell}>{g.ageGroup}</Text>
                  </View>
                  <View style={[w["10%"], table.col, common.textCenter]}>
                    <Text style={table.cell}>{g.totalMembers}</Text>
                  </View>
                  <View style={[w["10%"], table.col, common.textCenter]}>
                    <Text style={table.cell}>{g.totalFemales}</Text>
                  </View>
                  <View style={[w["10%"], table.col, common.textCenter]}>
                    <Text style={table.cell}>{g.totalMales}</Text>
                  </View>
                  <View
                    style={[
                      w["15%"],
                      table.col,
                      common.textCenter,
                      table.highlight,
                    ]}
                  >
                    <Text style={table.cell}>
                      {g.onResPhysicalFemales}/{g.onResMailingFemales}
                    </Text>
                  </View>
                  <View
                    style={[
                      w["15%"],
                      table.col,
                      common.textCenter,
                      table.highlight,
                    ]}
                  >
                    <Text style={table.cell}>
                      {g.onResPhysicalMales}/{g.onResMailingMales}
                    </Text>
                  </View>
                  <View style={[w["15%"], table.col, common.textCenter]}>
                    <Text style={table.cell}>
                      {g.offResPhysicalFemales}/{g.offResMailingFemales}
                    </Text>
                  </View>
                  <View style={[w["15%"], table.col, common.textCenter]}>
                    <Text style={table.cell}>
                      {g.offResPhysicalMales}/{g.offResMailingMales}
                    </Text>
                  </View>
                </View>
              );
            })}
            <View style={table.footerRow}>
              <View style={[w["10%"], table.footerCol, common.textLeft]}>
                <Text style={table.footerCell}>Totals:</Text>
              </View>
              <View style={[w["10%"], table.footerCol, common.textCenter]}>
                <Text style={table.footerCell}>{totalMembers}</Text>
              </View>
              <View style={[w["10%"], table.footerCol, common.textCenter]}>
                <Text style={table.footerCell}>{totalFemales}</Text>
              </View>
              <View style={[w["10%"], table.footerCol, common.textCenter]}>
                <Text style={table.footerCell}>{totalMales}</Text>
              </View>
              <View
                style={[
                  w["15%"],
                  table.footerCol,
                  common.textCenter,
                  table.highlight,
                ]}
              >
                <Text style={table.footerCell}>
                  {onResPhysicalFemales}/{onResMailingFemales}
                </Text>
              </View>
              <View
                style={[
                  w["15%"],
                  table.footerCol,
                  common.textCenter,
                  table.highlight,
                ]}
              >
                <Text style={table.footerCell}>
                  {onResPhysicalMales}/{onResMailingMales}
                </Text>
              </View>
              <View style={[w["15%"], table.footerCol, common.textCenter]}>
                <Text style={table.footerCell}>
                  {offResPhysicalFemales}/{offResMailingFemales}
                </Text>
              </View>
              <View style={[w["15%"], table.footerCol, common.textCenter]}>
                <Text style={table.footerCell}>
                  {offResPhysicalMales}/{offResMailingMales}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

class AgeBreakdown extends Component {
  constructor(props) {
    super(props);

    this.agesChart = new ChartManager("ages", 800, 170);
    this.agesChart.update = this.setChartImage.bind(this);

    this.malesChart = new ChartManager("males", 800, 170);
    this.malesChart.update = this.setChartImage.bind(this);

    this.femalesChart = new ChartManager("females", 800, 170);
    this.femalesChart.update = this.setChartImage.bind(this);

    this.onResChart = new ChartManager("onRes", 800, 170);
    this.onResChart.update = this.setChartImage.bind(this);

    this.offResChart = new ChartManager("offRes", 800, 170);
    this.offResChart.update = this.setChartImage.bind(this);

    this.state = {
      charts: {},
      loading: false,
    };
  }

  componentDidUpdate() {
    const { charts } = this.state;
    const {
      viewer: { analytics },
    } = this.props;
    const chartMargins = {
      top: 0,
      right: 50,
      left: 0,
      bottom: 10,
    };

    if (analytics) {
      if (!charts.ages && !this.agesChart.loading) {
        const ages = [
          { label: "under 18", value: analytics.under_18 },
          { label: "between 18 & 35", value: analytics.between_18_35 },
          { label: "between 35 & 59", value: analytics.between_35_59 },
          { label: "60+", value: analytics.over_60 },
        ];
        this.agesChart.chart = (
          <BarChart
            id={this.agesChart.name}
            width={this.agesChart.width}
            height={this.agesChart.height}
            data={ages}
            margin={chartMargins}
            ref={(ref) => this.agesChart.convert(ref)}
          >
            <XAxis dataKey="label" />
            <YAxis />
            <Legend />
            <Bar dataKey="value" fill="#6b486b" isAnimationActive={false} />
          </BarChart>
        );
      }

      if (!charts.males && !this.malesChart.loading) {
        this.malesChart.chart = (
          <BarChart
            id={this.malesChart.name}
            width={this.malesChart.width}
            height={this.malesChart.height}
            data={analytics.ageBreakdown}
            margin={chartMargins}
            ref={(ref) => this.malesChart.convert(ref)}
          >
            <XAxis dataKey="ageGroup" />
            <YAxis />
            <Legend />
            <Bar
              dataKey="totalMales"
              stackId="a"
              fill="#89cff0"
              isAnimationActive={false}
            />
          </BarChart>
        );
      }

      if (!charts.females && !this.femalesChart.loading) {
        this.femalesChart.chart = (
          <BarChart
            id={this.femalesChart.name}
            width={this.femalesChart.width}
            height={this.femalesChart.height}
            data={analytics.ageBreakdown}
            margin={chartMargins}
            ref={(ref) => this.femalesChart.convert(ref)}
          >
            <XAxis dataKey="ageGroup" />
            <YAxis />
            <Legend />
            <Bar
              dataKey="totalFemales"
              fill="#f4c2c2"
              isAnimationActive={false}
            />
          </BarChart>
        );
      }

      if (!charts.onRes && !this.onResChart.loading) {
        this.onResChart.chart = (
          <BarChart
            id={this.onResChart.name}
            width={this.onResChart.width}
            height={this.onResChart.height}
            data={analytics.ageBreakdown}
            margin={chartMargins}
            ref={(ref) => this.onResChart.convert(ref)}
          >
            <XAxis dataKey="ageGroup" />
            <YAxis />
            <Legend />
            <Bar
              dataKey="onResMailingMales"
              stackId="a"
              fill="#89cff0"
              isAnimationActive={false}
            />
            <Bar
              dataKey="onResMailingFemales"
              stackId="a"
              fill="#f4c2c2"
              isAnimationActive={false}
            />
          </BarChart>
        );
      }

      if (!charts.offRes && !this.offResChart.loading) {
        this.offResChart.chart = (
          <BarChart
            id={this.offResChart.name}
            width={this.offResChart.width}
            height={this.offResChart.height}
            data={analytics.ageBreakdown}
            margin={chartMargins}
            ref={(ref) => this.offResChart.convert(ref)}
          >
            <XAxis dataKey="ageGroup" />
            <YAxis />
            <Legend />
            <Bar
              dataKey="offResMailingMales"
              stackId="a"
              fill="#89cff0"
              isAnimationActive={false}
            />
            <Bar
              dataKey="offResMailingFemales"
              stackId="a"
              fill="#f4c2c2"
              isAnimationActive={false}
            />
          </BarChart>
        );
      }
    }
  }

  setChartImage(name, image) {
    const state = { ...this.state };
    state.charts[name] = image;
    this.setState(state);
  }

  render() {
    const { ages, females, males, onRes, offRes } = this.state.charts;

    if (
      this.props.loading ||
      !ages ||
      !females ||
      !males ||
      !onRes ||
      !offRes
    ) {
      return <Loading>Loading Enrollment Age Breakdown...</Loading>;
    }

    const { ageBreakdown } = this.props.viewer.analytics;

    return (
      <PageReports>
        <PDFViewer
          style={{
            width: "100%",
            height: "100%",
            border: 0,
            maxHeight: "100%",
            position: "absolute",
          }}
        >
          <AgeBreakdownDocument
            data={ageBreakdown}
            charts={this.state.charts}
          />
        </PDFViewer>
      </PageReports>
    );
  }
}

export default graphql(AGE_BREAKDOWN, {
  props: ({ data: { loading, error, viewer } }) => ({
    loading,
    error,
    viewer,
  }),
})(AgeBreakdown);
