import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout";
import DepartmentListing from "./DepartmentListing";
import { DepartmentDetails } from "./Department/DepartmentDetails";

const DepartmentContainer = (props) => (
  <Layout>
    <Switch>
      <Route
        exact
        path="/:departmentType"
        render={(routeProps) => (
          <DepartmentListing {...props} {...routeProps} />
        )}
      />
      <Route
        exact
        path="/:departmentType/:id"
        render={(routeProps) => (
          <DepartmentDetails {...props} {...routeProps} />
        )}
      />
    </Switch>
  </Layout>
);

export default DepartmentContainer;
