import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Nav } from "reactstrap";
import {
  NavLink as RRNavLink,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

import { TabPanel, PanelNavbar } from "../../../components";
import { ROLES } from "../../../Constants";
import { Can, PageNotFound, withUserRole } from "../../../Utils";

import FamilyOverview from "./FamilyOverview";
import FamilyTree from "./FamilyTree";
import FanChart from "./FanChart";
import RelationshipsChart from "./RelationshipsChart";

const FamilyTab = (props) => {
  const { person, ...rest } = props;
  const { location } = props;

  if (location.pathname === `/people/${person.id}/family`) {
    return <Redirect to={`/people/${person.id}/family/overview`} />;
  }

  const tabs = [
    {
      id: "overview",
      label: "Overview",
      icon: "users",
      component: <FamilyOverview person={person} {...rest} />,
    },
    {
      id: "tree",
      label: "Family Tree",
      icon: "tree",
      component: <FamilyTree person={person} {...rest} />,
    },
    {
      id: "star-chart",
      label: "Star Chart",
      icon: "star",
      component: <RelationshipsChart person={person} {...rest} />,
    },
    {
      id: "fan-chart",
      label: "Fan Chart",
      icon: "star",
      component: <FanChart person={person} {...rest} />,
    },
  ];

  return (
    <TabPanel id="family" className="chart-full-height">
      <Can grantRoles={[ROLES.ENROLLMENT]}>
        <PanelNavbar expand="md">
          <Nav navbar>
            {tabs.map((tab) => (
              <div key={`tab-item-${tab.id}`}>
                <RRNavLink
                  to={`/people/${person.id}/family/${tab.id}`}
                  href={`/people/${person.id}/family/${tab.id}`}
                  activeClassName="active"
                >
                  <div
                    style={{
                      background: "#e9eef2",
                      margin: "0.5rem 0.5rem 0.5rem 0",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                    }}
                  >
                    <FontAwesomeIcon icon={tab.icon} /> {tab.label}
                  </div>
                </RRNavLink>
              </div>
            ))}
          </Nav>
        </PanelNavbar>
      </Can>
      <div className="enrollment-content">
        <Switch>
          {tabs.map((tab) => (
            <Route
              key={`tab-pane-${tab.id}`}
              path={`/people/${person.id}/family/${tab.id}`}
              component={() => tab.component}
            />
          ))}
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </TabPanel>
  );
};

FamilyTab.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

FamilyTab.defaultProps = {};

const wrappedWithUserRole = withUserRole(FamilyTab, [
  ROLES.ENROLLMENT,
  ROLES.CIVIL_ATTORNEY,
]);

export default wrappedWithUserRole;
