import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { RequiredField } from "../../Utils";

class GetDescendantNameModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  createPDF = () => {
    window.location.href = `/people/${this.props.person}/letter-of-descendancy?descendant=${this.state.value}`;
    this.setState({
      value: "",
    });
    this.props.toggle();
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className={this.props.classNam}
          size="md"
        >
          <ModalHeader toggle={this.props.toggle}>
            Create Letter of Descendancy
          </ModalHeader>
          <ModalBody>
            <Label for="descendant_name">
              Enter Descendant Information <RequiredField />
            </Label>
            <Input
              value={this.state.value}
              onChange={this.onChange}
              type="text"
              name="descendant"
              id="descendant_name"
              placeholder="enter name to continue"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
            <Button
              color="success"
              onClick={this.createPDF}
              disabled={this.state.value.trim() === ""}
            >
              Create PDF
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

GetDescendantNameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  person: PropTypes.string.isRequired,
};

GetDescendantNameModal.defaultProps = {};

export default GetDescendantNameModal;
