import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, FormGroup, Label, CardBody } from "reactstrap";

import {
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
  DataPair,
} from "../../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES } from "../../../Constants";
import { Can, checkForErrors, EmailAddress, PhoneNumber } from "../../../Utils";

import { PERSON } from "../graphql";

import ContactModalForm from "./ContactModalForm";

class ContactInfo extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.CONTACT_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "contact"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          return (
            <Panel>
              <PanelHeader>
                <PanelTitle>Contact</PanelTitle>
                <Can grantRoles={[ROLES.ENROLLMENT]}>
                  <CardActions>
                    <ContactModalForm title="Edit Contact" person={person}>
                      Edit
                    </ContactModalForm>
                  </CardActions>
                </Can>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>Email</Label>
                      <strong id="person-email-field">
                        <EmailAddress email={person.email} />
                      </strong>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <DataPair>
                      <Label>Mobile</Label>
                      <strong>
                        <PhoneNumber phone={person.mobile} />
                      </strong>
                    </DataPair>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>Personal Phone</Label>
                      <strong>
                        <PhoneNumber phone={person.phone1} />
                      </strong>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>Business Phone</Label>
                      <strong>
                        <PhoneNumber phone={person.phone2} />
                      </strong>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>Fax</Label>
                      <strong>
                        <PhoneNumber phone={person.fax} />
                      </strong>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

ContactInfo.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

ContactInfo.defaultProps = {};

export default ContactInfo;
