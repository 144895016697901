import { gql } from "@selfdetermine/graphql";

import { IdFirstLastName, IdFirstLastNameQuantum } from "../graphql-fragments";

export const AncestorFragment = gql`
  fragment AncestorFragment on Person {
    id
    firstName
    lastName
    enrolledBloodQuantum
    otherBloodQuantum
    totalBloodQuantum
  }
`;

export const Ancestors = gql`
  fragment Ancestors on Person {
    #g2p1
    father {
      ...IdFirstLastNameQuantum
      #g3p1
      father {
        ...IdFirstLastNameQuantum
        #g4p1
        father {
          ...IdFirstLastNameQuantum
          #g5p1
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p2
          mother {
            ...IdFirstLastNameQuantum
          }
        }
        #g4p2
        mother {
          ...IdFirstLastNameQuantum
          #g5p3
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p4
          mother {
            ...IdFirstLastNameQuantum
          }
        }
      }
      #g3p2
      mother {
        ...IdFirstLastNameQuantum
        #g4p3
        father {
          ...IdFirstLastNameQuantum
          #g5p5
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p6
          mother {
            ...IdFirstLastNameQuantum
          }
        }
        #g4p4
        mother {
          ...IdFirstLastNameQuantum
          #g5p7
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p8
          mother {
            ...IdFirstLastNameQuantum
          }
        }
      }
    }
    #g2p2
    mother {
      ...IdFirstLastNameQuantum
      #g3p3
      father {
        ...IdFirstLastNameQuantum
        #g4p5
        father {
          ...IdFirstLastNameQuantum
          #g5p9
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p10
          mother {
            ...IdFirstLastNameQuantum
          }
        }
        #g4p6
        mother {
          ...IdFirstLastNameQuantum
          #g5p11
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p12
          mother {
            ...IdFirstLastNameQuantum
          }
        }
      }
      #g3p4
      mother {
        ...IdFirstLastNameQuantum
        #g4p7
        father {
          ...IdFirstLastNameQuantum
          #g5p13
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p14
          mother {
            ...IdFirstLastNameQuantum
          }
        }
        #g4p8
        mother {
          ...IdFirstLastNameQuantum
          #g5p15
          father {
            ...IdFirstLastNameQuantum
          }
          #g5p16
          mother {
            ...IdFirstLastNameQuantum
          }
        }
      }
    }
  }
`;

export const Children = gql`
  fragment Children on Person {
    children {
      ...IdFirstLastName
    }
  }
`;

export const Cousins = gql`
  fragment Cousins on Person {
    firstCousins {
      id
      firstName
      lastName
    }
  }
`;

export const GrandChildren = gql`
  fragment GrandChildren on Person {
    children {
      children {
        id
        firstName
        lastName
      }
    }
  }
`;

export const Parents = gql`
  fragment Parents on Person {
    mother {
      ...IdFirstLastName
    }
    father {
      ...IdFirstLastName
    }
  }
`;

export const PersonalRelationships = gql`
  fragment PersonalRelationships on Person {
    personal_relationships: personalRelationships {
      personRole {
        name
      }
      relatedRole {
        name
      }
      related {
        id
        firstName
        lastName
        sex
        membership_number: membershipNumber
      }
    }
  }
`;

export const Siblings = gql`
  fragment Siblings on Person {
    full_siblings: fullSiblings {
      ...IdFirstLastName
    }
    father_only_siblings: fatherOnlySiblings {
      ...IdFirstLastName
    }
    mother_only_siblings: motherOnlySiblings {
      ...IdFirstLastName
    }
  }
`;

export const FamilyInformation = gql`
  fragment FamilyInformation on Person {
    ...Ancestors
    ...Parents
    ...Siblings
    ...Children
    ...GrandChildren
    ...Cousins
  }
  ${IdFirstLastName}
  ${IdFirstLastNameQuantum}
  ${Ancestors}
  ${Parents}
  ${Siblings}
  ${Children}
  ${GrandChildren}
  ${Cousins}
`;
