import { Mutation } from "@apollo/client/react/components";
import React, { Component } from "react";
import {
  CustomInput,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButton } from "../../../components";
import { ui } from "../../../Constants";
import { DEPARTMENTS } from "../../../Departments/graphql";
import { MutationSubmit, QuerySelect } from "../../../Utils";

import { MatterFields } from "./Matters/Fields";
import { CREATE_CCC_MUTATION, MATTER } from "./Matters/graphql";
import { CASES_QUERY } from "./graphql";
import { CREATE_CASE_MUTATION } from "./Reentry/graphql";

class NewCase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      kase: {},
    };
  }

  _handleChange = (e, n, v) => {
    const { target } = e;
    let name = n;
    let value = v;

    if (!name) {
      name = target.name;
      value = target.type === "checkbox" ? target.checked : target.value;
    }

    this.setState((prevState) => {
      const state = prevState.kase;
      state[name] = value;

      return { kase: state };
    });
  };

  _handleSelectChange = (name, select) => {
    this.setState((prevState) => {
      const state = prevState.kase;
      state[name] = select ? select.value : select;

      return { kase: state };
    });
  };

  _handleOnComplete = (data) => {
    const caseId =
      this.props.caseType === "Reentry"
        ? data.addReentryCase.id
        : data.addMatter.id;

    this.setState({
      caseId,
    });
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;

      return { modal: updated };
    });
  };

  _getQueryVariables = () => {
    const { kase } = this.state;

    if (this.props.caseType === "Reentry") {
      return {
        person: this.props.person.id,
        reentryCase: { ...kase },
      };
    }
    return {
      // person: this.props.person.id,
      matter: { ...kase },
    };
  };

  render() {
    if (this.state.caseId)
      return (
        <Redirect
          to={`/people/${this.props.person.id}/${
            this.props.caseType === "Reentry" ? "reentry" : "matters"
          }/${this.state.caseId}`}
        />
      );

    const { caseType } = this.props;

    return (
      <>
        <ActionButton
          onClick={this._toggleModal}
          size="sm"
          id="new-case-button"
        >
          <>{ui.icon.plus} New</>
        </ActionButton>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>
            {this.props.caseType === "Reentry"
              ? "Add a New Reentry Case"
              : "Add a New Civil Matter"}
          </ModalHeader>
          <ModalBody>
            <SharedFields
              handleChange={this._handleChange}
              handleSelectChange={this._handleSelectChange}
            />
            {this.props.caseType === "Reentry" && (
              <ReentryFields handleChange={this._handleChange} />
            )}
            {this.props.caseType === "Civil Court" && (
              <MatterFields
                handleChange={this._handleChange}
                handleSelectChange={this._handleSelectChange}
                person={this.props.person}
                kase={this.state.kase}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={
                this.props.caseType === "Reentry"
                  ? CREATE_CASE_MUTATION
                  : CREATE_CCC_MUTATION
              }
              variables={this._getQueryVariables()}
              refetchQueries={[
                {
                  query:
                    caseType === "Reentry" ? CASES_QUERY : MATTER.LIST_QUERY,
                  variables: this.props.getQueryVars(),
                },
              ]}
              onCompleted={(data) => {
                Toasts.success("Created new case");
                this._toggleModal();
                this._handleOnComplete(data);
              }}
              onError={(error) =>
                Toasts.danger("Unable to create new case", error)
              }
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="new-case-submit-button"
                  color="warning"
                  label={`Create ${
                    caseType === "Reentry" ? "Reentry Case" : "Matter"
                  }`}
                  loadingLabel="Creating..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export const SharedFields = ({ handleChange, handleSelectChange }) => (
  <>
    <Row form>
      <Col sm={12}>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            id="description"
            style={{ height: "6rem" }}
            required
            type="textarea"
            name="description"
            placeholder="Description"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row form>
      <Col sm={12}>
        <FormGroup>
          <Label for="openedOn">Opened On</Label>
          <Input
            required
            type="date"
            name="openedOn"
            id="openedOn"
            placeholder="Opened On"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row form>
      <Col sm={12}>
        <FormGroup>
          <Label for="referringEntity">Referring Entity</Label>
          <QuerySelect
            id="referringEntity"
            value="referringEntity"
            query={DEPARTMENTS.SELECT_QUERY}
            viewer="departments"
            isSearchable
            isClearable
            placeholder="Search Departments"
            handleChange={handleSelectChange}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row form>
      <Col sm={12}>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="selfReferral"
            name="selfReferral"
            label="Self Referral?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
  </>
);

export const ReentryFields = ({ handleChange }) => (
  <>
    <Row form>
      <Col sm={12}>
        <FormGroup>
          <Label for="releasedAt">Released At</Label>
          <Input
            required
            type="date"
            name="releasedAt"
            placeholder="Released At"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col sm={8}>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="timeOfPreviousInpatientTrue"
            name="treatmentPastYear"
            label="Treatment in the last year"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col sm={8}>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="timeOfPreviousInpatientFalse"
            name="treatmentYearPlus"
            label="Treatment over a year ago"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="attendedOutpatientTherapy"
            name="attendedOutpatientTherapy"
            label="Previously attended outpatient treatment?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="currentMatPatient"
            name="currentMatPatient"
            label="Current MAT patient?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="needDetox"
            name="needDetox"
            label="Need detox?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="drugAlcoholRelated"
            name="drugAlcoholRelated"
            label="Drug or alcohol related?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="mentalHealth"
            name="mentalHealth"
            label="Mental health?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="violent"
            name="violent"
            label="Violent?"
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
  </>
);

export default NewCase;
