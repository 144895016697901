import { Mutation } from "@apollo/client/react/components";
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import { ButtonIcnNew, ActionButtonUser } from "../components";
import { ui } from "../Constants";
import { MutationSubmit, RequiredField } from "../Utils";

import { CREATE_PERSON_MUTATION } from "./graphql";

class NewPerson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: { ...props.person },
      createdPerson: false,
      modal: false,
    };
  }

  _handleChange = (e, type) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    const state = this.state[type];

    state[name] = value;

    this.setState(state);
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;
      const newState = Object.assign(prevState, { modal: updated });

      return { newState };
    });
  };

  _handleOnComplete = (data) => {
    this.setState({
      createdPerson: true,
      person: data.createPerson ? data.createPerson : {},
    });
  };

  render() {
    const { children, right } = this.props;
    const { person } = this.state;
    if (this.state.createdPerson)
      return <Redirect to={`/people/${person.id}`} />;

    return (
      <>
        <ActionButtonUser
          id="modal-btn"
          onClick={this._toggleModal}
          className={`${
            right ? "float-right match-card-buttons" : "match-card-buttons"
          }`}
          size="sm"
        >
          {children || (
            <>
              New
              <ButtonIcnNew>{ui.icon.plus}</ButtonIcnNew>
            </>
          )}
        </ActionButtonUser>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>Add Person</ModalHeader>
          <ModalBody>
            <Row form>
              <Col sm={4}>
                <FormGroup>
                  <Label for="firstName">
                    First Name <RequiredField />
                  </Label>
                  <Input
                    required
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First name"
                    defaultValue={person.first_name}
                    onChange={(e) => this._handleChange(e, "person")}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="middleName">Middle Name</Label>
                  <Input
                    type="text"
                    name="middleName"
                    id="middleName"
                    placeholder="Middle name"
                    defaultValue={person.middle_name}
                    onChange={(e) => this._handleChange(e, "person")}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="lastName">
                    Last Name <RequiredField />
                  </Label>
                  <Input
                    required
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last name"
                    defaultValue={person.last_name}
                    onChange={(e) => this._handleChange(e, "person")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={4}>
                <FormGroup>
                  <Label for="sex">
                    Gender <RequiredField />
                  </Label>
                  <Input
                    required
                    type="select"
                    name="sex"
                    id="sex"
                    defaultValue={person.sex}
                    onChange={(e) => this._handleChange(e, "person")}
                  >
                    <option value="">Please Select</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="bornAt">
                    Birth Date <RequiredField />
                  </Label>
                  <Input
                    type="date"
                    name="bornAt"
                    id="bornAt"
                    placeholder="Enter birth date"
                    defaultValue={person.born_at}
                    onChange={(e) => this._handleChange(e, "person")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={CREATE_PERSON_MUTATION}
              variables={{ person }}
              onCompleted={(data) => {
                Toasts.success("Created new person");
                this._toggleModal();
                this._handleOnComplete(data);
              }}
              onError={(error) =>
                Toasts.danger("Unable to create new person", error)
              }
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="submit-btn"
                  color="warning"
                  label="Save"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

NewPerson.defaultProps = {};

export default NewPerson;
