import React from "react";
import { APP_NAME, PASSWORD_RESET_MESSAGE } from "../Constants";

const CheckEmailPage = () => (
  <div className="checkoutPage">
    <div className="checkoutPage__card">
      <h1 className="checkoutPage__card-header">{APP_NAME}</h1>
      <p className="checkoutPage__card-text">{PASSWORD_RESET_MESSAGE}</p>
    </div>
  </div>
);

export default CheckEmailPage;
