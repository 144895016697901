import { Query } from "@apollo/client/react/components";
import React from "react";
import { Row, Col, FormGroup, Label, CardBody } from "reactstrap";

import {
  ConfirmationModal,
  Panel,
  PanelHeader,
  PanelTitle,
} from "../../../components";
import { CASE_STATUS_CHANGE_MESSAGE } from "../../../Constants";
import { checkForErrors } from "../../../Utils";

import { ACCEPT_CASE_MUTATION, CASE, CLOSE_CASE_MUTATION } from "./graphql";

import {
  FIRST24_CASE_MUTATION,
  PHASE1_CASE_MUTATION,
  PHASE2_CASE_MUTATION,
  PHASE3_CASE_MUTATION,
  PHASE4_CASE_MUTATION,
  PHASE5_CASE_MUTATION,
  PHASE6_CASE_MUTATION,
  WRAPUP_CASE_MUTATION,
  GRADUATE_CASE_MUTATION,
} from "./Reentry/graphql";

const getQueryVars = (props) => {
  return {
    parent: props.match.params.person,
    id: props.match.params.id,
  };
};

const CaseStatus = (props) => (
  <Query query={CASE.STATUS_QUERY} variables={getQueryVars(props)}>
    {({ loading, error, data }) => {
      const loadingOrError = checkForErrors(loading, error, data, "overview");
      if (loadingOrError) {
        return loadingOrError;
      }

      const _case = data.viewer.case;

      return (
        <Panel id="case-status">
          <PanelHeader>
            <PanelTitle>Status</PanelTitle>
          </PanelHeader>
          <CardBody>
            <Row>
              <Col xs={4}>
                <FormGroup>
                  <Label>Current Status</Label>
                  <strong>{_case.status}</strong>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {_case.permittedStates.includes("accepted") && (
                  <AcceptButton
                    id="accept"
                    title="Accept"
                    message="Are you sure you want to accept the case?"
                    buttonText="Accept"
                    mutation={ACCEPT_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("first_24") && (
                  <First24Button
                    title="First 24 Status Change"
                    message="Are you sure you want to promote the case to First 24?"
                    buttonText="First 24"
                    mutation={FIRST24_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("phase_1") && (
                  <Phase1Button
                    title="Phase 1 Status Change"
                    message="Are you sure you want to promote the case to Phase 1?"
                    buttonText="Phase 1"
                    mutation={PHASE1_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("phase_2") && (
                  <Phase2Button
                    title="Phase 2 Status Change"
                    message="Are you sure you want to promote the case to Phase 2?"
                    buttonText="Phase 2"
                    mutation={PHASE2_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("phase_3") && (
                  <Phase3Button
                    title="Phase 3 Status Change"
                    message="Are you sure you want to promote the case to Phase 3?"
                    buttonText="Phase 3"
                    mutation={PHASE3_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("phase_4") && (
                  <Phase4Button
                    title="Phase 4 Status Change"
                    message="Are you sure you want to promote the case to Phase 4?"
                    buttonText="Phase 4"
                    mutation={PHASE4_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("phase_5") && (
                  <Phase5Button
                    title="Phase 5 Status Change"
                    message="Are you sure you want to promote the case to Phase 5?"
                    buttonText="Phase 5"
                    mutation={PHASE5_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("phase_6") && (
                  <Phase6Button
                    title="Phase 6 Status Change"
                    message="Are you sure you want to promote the case to Phase 6?"
                    buttonText="Phase 6"
                    mutation={PHASE6_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("wrapped_up") && (
                  <WrapUpButton
                    title="Wrap Up"
                    message="Are you sure you want to wrap up the case?"
                    buttonText="Wrap Up"
                    mutation={WRAPUP_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("graduated") && (
                  <GraduateButton
                    title="Graduate Case"
                    message="Are you sure you want to wrap up the case?"
                    buttonText="Graduate"
                    mutation={GRADUATE_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
                {_case.permittedStates.includes("closed") && (
                  <CloseButton
                    title="Close"
                    message="Are you sure you want to close the case?"
                    buttonText="Close"
                    mutation={CLOSE_CASE_MUTATION}
                    mutationVars={{
                      person: _case.person.id,
                      case: _case.id,
                    }}
                    successMessage={CASE_STATUS_CHANGE_MESSAGE}
                    refetchQuery={CASE.STATUS_QUERY}
                    refetchVars={getQueryVars(props)}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Panel>
      );
    }}
  </Query>
);

export const AcceptButton = (props) => <ConfirmationModal {...props} />;
export const First24Button = (props) => <ConfirmationModal {...props} />;
export const Phase1Button = (props) => <ConfirmationModal {...props} />;
export const Phase2Button = (props) => <ConfirmationModal {...props} />;
export const Phase3Button = (props) => <ConfirmationModal {...props} />;
export const Phase4Button = (props) => <ConfirmationModal {...props} />;
export const Phase5Button = (props) => <ConfirmationModal {...props} />;
export const Phase6Button = (props) => <ConfirmationModal {...props} />;
export const WrapUpButton = (props) => <ConfirmationModal {...props} />;
export const CloseButton = (props) => <ConfirmationModal {...props} />;
export const GraduateButton = (props) => <ConfirmationModal {...props} />;

export default CaseStatus;
