import { Query } from "@apollo/client/react/components";
import React from "react";
import { Media, CardBody } from "reactstrap";
import Moment from "react-moment";

import {
  EmptyListing,
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
} from "../../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES } from "../../../Constants";
import { Can, checkForErrors } from "../../../Utils";

import NotesModalForm from "../Notes/NotesModalForm";

import { CASE } from "./graphql";
import { CREATE_REENTRY_NOTE } from "./Reentry/graphql";

const getQueryVars = (props) => {
  return {
    parent: props.match.params.person,
    id: props.match.params.id,
  };
};

const CaseNotes = (props) => (
  <Query
    query={CASE.NOTES_QUERY}
    variables={getQueryVars(props)}
    errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
  >
    {({ loading, error, data }) => {
      const loadingOrError = checkForErrors(loading, error, data, "case notes");
      if (loadingOrError) {
        return loadingOrError;
      }

      const kase = data.viewer.case;
      const { notes } = kase;
      const noteMutation = CREATE_REENTRY_NOTE;

      return (
        <Panel id="case-notes">
          <PanelHeader>
            <PanelTitle>Notes</PanelTitle>
            <Can grantRoles={[ROLES.REENTRY, ROLES.CIVIL_ATTORNEY]}>
              <CardActions>
                <NotesModalForm
                  title="Add Note"
                  mutation={noteMutation}
                  refetchQuery={CASE.NOTES_QUERY}
                  refetchVars={() => getQueryVars(props)}
                  note={{ case: kase.id }}
                />
              </CardActions>
            </Can>
          </PanelHeader>
          {notes && notes.length ? (
            notes.map((note) => (
              <CardBody>
                <Media key={note.id}>
                  <Media body>
                    <small>
                      <i>
                        Created on:{" "}
                        {note ? (
                          <Moment
                            date={new Date(note.createdAt)}
                            format="MM/DD/YYYY h:mma"
                          />
                        ) : (
                          "null"
                        )}{" "}
                        {note.createdBy.email
                          ? `by ${note.createdBy.email}`
                          : ""}
                      </i>
                    </small>
                    <p>{note ? note.body : "null"}</p>
                  </Media>
                </Media>
              </CardBody>
            ))
          ) : (
            <EmptyListing>No Notes</EmptyListing>
          )}
        </Panel>
      );
    }}
  </Query>
);

CaseNotes.defaultProps = {};

export default CaseNotes;
