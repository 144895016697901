import { Mutation } from "@apollo/client/react/components";
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButton } from "../components";
import { ui } from "../Constants";
import { DEPARTMENTS } from "../Departments/graphql";
import { MutationSubmit, QuerySelect, RequiredField } from "../Utils";

import { UPDATE_REFERRAL_MUTATION } from "./graphql";

class EditReferralModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      need: this.props.need ? this.props.need : {},
      modal: false,
    };
  }

  _handleChange = (e, type) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState((prevState) => {
      const state = prevState[type];
      state[name] = value;

      return { need: state };
    });
  };

  _handleSelectChange = (name, select) => {
    this.setState((prevState) => {
      const state = prevState.need;
      state[name] = select.value ? select.value : select;

      return { need: state };
    });
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;

      return { modal: updated };
    });
  };

  _getQueryVariables = () => {
    const { need } = this.state;

    return {
      referral: {
        person: this.props.person,
        case: this.props.caseId,
        id: this.props.referral.id,
        referred: need.referred,
        description: need.description,
      },
    };
  };

  render() {
    const { children, right } = this.props;
    const { need } = this.state;

    return (
      <>
        <ActionButton
          onClick={this._toggleModal}
          className={`${right ? "float-right" : ""}`}
          size="sm"
        >
          {children || <>{ui.icon.plus} New</>}
        </ActionButton>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>Edit Need</ModalHeader>
          <ModalBody>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="description">
                    Need <RequiredField />
                  </Label>
                  <Input
                    required
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Description"
                    defaultValue={need.description}
                    onChange={(e) => this._handleChange(e, "need")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="type">Referred</Label>
                  <QuerySelect
                    value="referred"
                    name="referredName"
                    query={DEPARTMENTS.SELECT_QUERY}
                    viewer="departments"
                    isSearchable
                    isClearable
                    placeholder="Search Department..."
                    handleChange={this._handleSelectChange}
                    defaultValue={{
                      label: need.referredName,
                      value: need.referred,
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={UPDATE_REFERRAL_MUTATION}
              variables={this._getQueryVariables()}
              onCompleted={() => {
                Toasts.success("Referral updated");
                this._toggleModal();
              }}
              onError={(error) =>
                Toasts.danger("Unable to update referral", error)
              }
              refetchQueries={[
                {
                  query: this.props.refetchQuery,
                  variables: this.props.refetchVars(),
                },
              ]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  color="warning"
                  label="Update Referral"
                  loadingLabel="Updating..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

EditReferralModalForm.defaultProps = {};

export default EditReferralModalForm;
