import styled from "styled-components";

export const PageReports = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 800px;
  position: relative;
`;
