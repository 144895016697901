import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

import { PhoneNumberInput } from "../../../Utils";

class AgeInput extends Component {
  _handleChange = (e) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.props.handleChange("person", name, value);
  };

  render() {
    const { person } = this.props;

    return (
      <>
        <Row>
          <Col sm={4}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter email address"
                defaultValue={person.email}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label for="mobile">Mobile</Label>
              <PhoneNumberInput
                type="text"
                name="mobile"
                id="mobile"
                placeholder="Enter mobile phone"
                defaultValue={person.mobile}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label for="phone1">Personal Phone</Label>
              <PhoneNumberInput
                type="text"
                name="phone1"
                id="phone1"
                placeholder="Enter home phone"
                defaultValue={person.phone1}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <FormGroup>
              <Label for="phone2">Business Phone</Label>
              <PhoneNumberInput
                type="text"
                name="phone2"
                id="phone2"
                placeholder="Enter work phone"
                defaultValue={person.phone2}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label for="fax">Fax</Label>
              <PhoneNumberInput
                type="text"
                name="fax"
                id="fax"
                placeholder="Enter fax number"
                defaultValue={person.fax}
                onChange={(e) => this._handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

AgeInput.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

AgeInput.defaultProps = {};

export default AgeInput;
