import { Mutation } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";

import { ActionButton } from "../../../components";
import { ui } from "../../../Constants";
import {
  AddressInput,
  convertDataToSchema,
  MutationSubmit,
} from "../../../Utils";

import { PERSON } from "../graphql";

import { CREATE_PERSON_ADDRESS_MUTATION } from "./graphql";
import { DEFAULT_ADDRESS_INPUT } from "./constants";

class AddressModalForm extends Component {
  constructor(props) {
    super(props);

    const { id, ...address } = props.address;

    address.person = props.person ? props.person.id : null;

    this.state = {
      modal: false,
      address: { ...address },
    };
  }

  _handleChange = (e, n, v) => {
    const { target } = e;
    let name = n;
    let value = v;

    if (!name) {
      name = target.name;
      value = target.type === "checkbox" ? target.checked : target.value;
    }

    const state = this.state.address;
    state[name] = value;
    this.setState(state);
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const { ...address } = this.props.address;
      address.person = this.props.person ? this.props.person.id : null;
      const updated = Object.assign(prevState, address);

      return { updated };
    });
  };

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;
      const newState = Object.assign(prevState, { modal: updated });

      return { newState };
    });
  };

  render() {
    const { address } = this.state;
    const { title, children, right } = this.props;

    const addressInput = convertDataToSchema(address, DEFAULT_ADDRESS_INPUT);

    return (
      <>
        <ActionButton
          id="modal-btn"
          onClick={this._toggleModal}
          className={`btn-secondary ${right ? "float-right" : ""}`}
          size="sm"
        >
          {children || <>{ui.icon.plus} New</>}
        </ActionButton>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>
            {title || "Add Address"}
          </ModalHeader>
          <ModalBody>
            <AddressInput
              address={address}
              handleChange={this._handleChange}
              requiredFields
            />
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={CREATE_PERSON_ADDRESS_MUTATION}
              variables={{ address: addressInput }}
              onCompleted={() => {
                Toasts.success("Address added");
                this._toggleModal();
              }}
              onError={(error) => Toasts.danger("Unable to add address", error)}
              refetchQueries={[
                {
                  query: PERSON.ADDRESSES_QUERY,
                  variables: { person: address.person },
                },
              ]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="submit-btn"
                  color="warning"
                  label="Save"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

AddressModalForm.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

AddressModalForm.defaultProps = {};

export default AddressModalForm;
