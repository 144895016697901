import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Moment from "react-moment";
import {
  DEFAULT_NEW_WINDOW_FEATURES,
  DEFAULT_SERVER_DATETIME_FORMAT,
  ROLES,
  ui,
} from "../Constants";
import { ButtonIcn } from "../components";
import { Can, convertObjToKeyValue } from "../Utils";

const _handleWindowClick = (e, person) => {
  e.preventDefault();
  window.open(
    `/people/${person.id}`,
    "_blank",
    convertObjToKeyValue(DEFAULT_NEW_WINDOW_FEATURES)
  );
};

const TableRow = ({ person }) => (
  <NavLink
    to={`/people/${person.id}`}
    href={`/people/${person.id}`}
    className="listing__item"
    id="listing__item"
  >
    <div className="listing__item-data" id="first-name">
      {person.first_name}
    </div>
    <Can grantRoles={[ROLES.ENROLLMENT]}>
      <div className="listing__item-data">{person.middle_name}</div>
    </Can>
    <div className="listing__item-data">{person.last_name}</div>
    <Can grantRoles={[ROLES.ENROLLMENT, ROLES.CIVIL_ATTORNEY]}>
      <div className="listing__item-data">{person.suffix}</div>
      <div className="listing__item-data">{person.membership_number}</div>
    </Can>
    <Can grantRoles={[ROLES.REENTRY]}>
      <div className="listing__item-data">{person.sex}</div>
    </Can>
    <div className="listing__item-data">
      <Moment
        date={person.born_at}
        format="MM/DD/YYYY"
        parse={DEFAULT_SERVER_DATETIME_FORMAT}
      />
    </div>
    <Can grantRoles={[ROLES.ENROLLMENT]}>
      <div className="listing__item-data">
        {person.deceasedAt && (
          <Moment
            date={person.deceasedAt}
            format="MM/DD/YYYY"
            parse={DEFAULT_SERVER_DATETIME_FORMAT}
          />
        )}
      </div>
    </Can>
    <Can grantRoles={[ROLES.ENROLLMENT]}>
      <div className="listing__item-data table-actions">
        <ButtonIcn onClick={(e) => _handleWindowClick(e, person)}>
          {ui.icon.popup}
        </ButtonIcn>
      </div>
    </Can>
  </NavLink>
);

TableRow.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    member_status: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    sex: PropTypes.string,
    suffix: PropTypes.string,
    membership_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    born_at: PropTypes.string,
    deceasedAt: PropTypes.string,
  }).isRequired,
};

export default TableRow;

// Using an anchor as a table row may be bad practice.
// We will see if it causes issues.
// We are doing this so the entire row shows the link info.
// const Person = styled.Link`
//   display: table-row;
//   cursor: pointer;
//   text-decoration: none;
//   color: black;
//   &&:hover {
//     background-color: #1e848a;
//     text-decoration: none;
//     color: #fff;
//   }

//   &&td:last-of-type {
//     text-align: right;
//   }
// `;
