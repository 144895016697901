import React, { Component } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  BlobProvider,
} from "@react-pdf/renderer";
import { Query } from "@apollo/client/react/components";
import EnrollmentHeader from "./EnrollmentHeader";
import { PageReports } from "../../components";
import { ViewSeal } from "./ViewPartials";
import { common, layout, p } from "../../styles";
import { PERSON } from "../graphql";
import {
  DEFAULT_QUERY_ERROR_POLICY,
  TRIBE_NAME,
  ENROLLMENT_DIRECTOR,
  PDF_LOADING_MESSAGE,
} from "../../../Constants";
import {
  checkForErrors,
  getPersonName,
  formatDate,
  formatPersonExportFilename,
} from "../../../Utils";

// Create Document Component
export const NoticeOfAcceptanceDocument = ({ person }) => {
  return (
    <Document title="Notice of Acceptance">
      <Page size="Letter" style={layout.page}>
        <EnrollmentHeader />
        <View style={layout.container}>
          <Text style={[common.h1, p.bottom20, common.textCenter]}>
            NOTICE OF ACCEPTANCE
          </Text>
          <Text style={common.title}>{getPersonName(person)}</Text>
          <Text style={[common.h2, common.textCenter]}>
            {person.membershipNumber}
          </Text>
          <Text style={[common.h2, common.textCenter]}>
            Born: {formatDate(person.bornAt)}
          </Text>
          <View style={[layout.row, p.bottom30]}>
            <View style={[common.lead, common.bold, p[20]]}>
              <Text style={[p[10], common.uppercase]}>
                HAS BEEN ACCEPTED FOR ENROLLMENT INTO THE {TRIBE_NAME} TRIBE.
                ENROLLMENT IDENTIFICATION NO. IS {person.membershipNumber}
              </Text>
              <Text style={p[10]}>
                THE ENROLLMENT IS EFFECTIVE AS OF THE DATE OF THIS LETTER.
                PLEASE KEEP THIS NOTICE FOR YOUR RECORDS AS PROOF OF MEMBERSHIP.
              </Text>
              <Text style={[p[10], common.uppercase]}>
                THIS PERSON IS ENTITLED TO ALL THE RIGHTS AND PRIVILEGES
                ACCORDING TO THE {TRIBE_NAME} TRIBAL CONSTITUTION AND BY-LAWS,
                THE LAW AND ORDER CODE AND THOSE RIGHTS AND PRIVILEGES HELD IN
                TRUST BY THE DEPARTMENT OF THE INTERIOR, BUREAU OF INDIAN
                AFFAIRS.
              </Text>
            </View>
          </View>
          <View style={[layout.row, layout.spaceBetween, p.top30]}>
            <View style={[common.blankLine, p.right20, p.top5]}>
              <Text>{ENROLLMENT_DIRECTOR}, Enrollment Director</Text>
              <Text>{TRIBE_NAME} Tribe</Text>
            </View>
            <View style={[common.blankLine, p.right140, p.top5]}>
              <Text>Date</Text>
            </View>
          </View>
          <ViewSeal />
        </View>
      </Page>
    </Document>
  );
};

class NoticeOfAcceptance extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    const { type } = this.props;

    return (
      <Query
        query={PERSON.NOTICE_OF_ACCEPTANCE_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "Notice of Acceptance"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;
          const renderDocument = <NoticeOfAcceptanceDocument person={person} />;

          if (type === "viewer") {
            return (
              <PageReports>
                <PDFViewer
                  style={{
                    width: "100%",
                    height: "100%",
                    border: 0,
                    maxHeight: "100%",
                    position: "absolute",
                  }}
                >
                  {renderDocument}
                </PDFViewer>
              </PageReports>
            );
          }
          return (
            <BlobProvider document={renderDocument}>
              {({ url, loading: blobLoading }) =>
                blobLoading ? (
                  PDF_LOADING_MESSAGE
                ) : (
                  <a
                    className="button"
                    href={url}
                    download={formatPersonExportFilename(person, "NOA")}
                  >
                    Notice of Acceptance
                  </a>
                )
              }
            </BlobProvider>
          );
        }}
      </Query>
    );
  }
}

export default NoticeOfAcceptance;
