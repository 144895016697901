import { gql } from "@selfdetermine/graphql";

export const IdFirstLastName = gql`
  fragment IdFirstLastName on Person {
    id
    firstName
    lastName
  }
`;

export const IdFirstLastNameQuantum = gql`
  fragment IdFirstLastNameQuantum on Person {
    id
    firstName
    lastName
    enrolledBloodQuantum
    otherBloodQuantum
    totalBloodQuantum
  }
`;

export const Required = gql`
  fragment RequiredParts on Person {
    first_name: firstName
    last_name: lastName
    born_at: bornAt
    sex
  }
`;

export const NameParts = gql`
  fragment NameParts on Person {
    firstName
    middleName
    lastName
    suffix
  }
`;

export const PhysicalAttributes = gql`
  fragment PhysicalAttributes on Person {
    height
    weight
    sex
    hairColor
    eyeColor
  }
`;

export const LifeDetails = gql`
  fragment LifeDetails on Person {
    age
    pre1900BornAt
    bornAt
    birthplace
    deceasedAt
  }
`;

export const DelicateInformation = gql`
  fragment DelicateInformation on Person {
    ssn
  }
`;

export const ServiceInformation = gql`
  fragment ServiceInformation on Person {
    veteran
    serviceStartedAt
    serviceEndedAt
    branchOfService
    yearsOfService
  }
`;

export const MembershipInformation = gql`
  fragment MembershipInformation on Person {
    membershipNumber
    memberStatus
    resolutionNumber
    enrolledAt
    appliedAt
    cardIssuedAt
    guardianName
    guardianContactInfo
    deceasedAt
  }
`;

export const Address = gql`
  fragment Address on Residence {
    id
    kind
    active
    street1
    street2
    city
    state
    zipCode
    createdOn
    modifiedOn
    lastModifiedBy {
      email
    }
  }
`;

export const BloodQuantum = gql`
  fragment BloodQuantum on BloodQuantum {
    id
    numerator
    denominator
    tribe {
      id
      name
    }
  }
`;

export const BloodQuanta = gql`
  fragment BloodQuanta on Person {
    blood_quanta: bloodQuanta {
      ...BloodQuantum
    }
    enrolledBloodQuantum
    otherBloodQuantum
    totalBloodQuantum
  }
  ${BloodQuantum}
`;

export const PersonIdentification = gql`
  fragment PersonIdentification on Person {
    ...NameParts
    maidenName
    maritalStatus
    headOfHousehold
    isMinor
  }
  ${NameParts}
`;

export const ContactInformation = gql`
  fragment ContactInformation on Person {
    email
    mobile
    phone1
    phone2
    fax
  }
`;

export const AddressInformation = gql`
  fragment AddressInformation on Person {
    addresses: residences {
      ...Address
    }
  }
  ${Address}
`;

export const PdfInfo = gql`
  fragment PdfInfo on Person {
    id
    firstName
    middleName
    lastName
    suffix
    membershipNumber
    bornAt
  }
`;
