import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Description, Status, StatusData } from "../../../../components/index";
import { DEFAULT_SERVER_DATETIME_FORMAT, EN } from "../../../../Constants";

export const MattersRow = ({ matter, person }) => {
  return (
    <Link
      className="listing__item"
      key={`department-summary-${matter.id}`}
      to={`/people/${person.id}/matters/${matter.id}`}
    >
      <div className="listing__item-data">
        <Moment
          date={matter.createdAt}
          format="MM/DD/YYYY"
          parse={DEFAULT_SERVER_DATETIME_FORMAT}
        />
      </div>
      <div className="listing__item-data">
        {EN.MATTER.CATEGORY_OPTIONS[matter.category]}
      </div>
      <div className="listing__item-data">
        {EN.MATTER.REPRESENTATION_OPTIONS[matter.representation]}
      </div>
      <Description className="listing__item-data">
        {matter.description}
      </Description>
      <StatusData className="listing__item-data">
        <Status>{matter.status}</Status>
      </StatusData>
    </Link>
  );
};
