import { Font, StyleSheet } from "@react-pdf/renderer";
import MerriweatherItalic from "../asset/fonts/Merriweather/Merriweather-Italic.ttf";
import MerriweatherRegular from "../asset/fonts/Merriweather/Merriweather-Regular.ttf";
import MerriweatherBold from "../asset/fonts/Merriweather/Merriweather-Bold.ttf";

// Register font
Font.register({
  family: "Merriweather",
  fonts: [
    { src: MerriweatherBold, fontWeight: "bold" },
    { src: MerriweatherRegular },
  ],
});

Font.register({
  family: "Merriweather Italic",
  fonts: [{ src: MerriweatherItalic, fontStyle: "italic" }],
});

export const common = StyleSheet.create({
  title: {
    fontSize: 26,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 10,
    color: "#1769FF",
  },
  h1: {
    fontSize: 24,
    fontWeight: "bold",
    paddingBottom: 10,
  },
  h2: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: 5,
  },
  h3: {
    fontSize: 13,
    fontWeight: "bold",
    paddingTop: 5,
    paddingBottom: 5,
  },
  h4: {
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  lead: {
    fontSize: 11,
  },
  small: {
    fontSize: 11,
    lineHeight: 1,
  },
  large: {
    fontSize: 16,
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontFamily: "Merriweather Italic",
    fontStyle: "italic",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  address: {
    textAlign: "left",
    fontSize: 11,
  },
  textLeft: {
    textAlign: "left",
  },
  textRight: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  blankLine: {
    borderTopStyle: "solid",
    borderTopColor: "#000000",
    borderTopWidth: "2pt",
    fontSize: 11,
  },
});

export const layout = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    fontFamily: "Merriweather",
  },
  container: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  row: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  col: {
    flexDirection: "column",
  },
  colMd1: {
    width: "8.33%",
  },
  colMd2: {
    width: "16.667%",
  },
  colMd3: {
    width: "25%",
  },
  colMd4: {
    width: "33.33%",
  },
  colMd5: {
    width: "41.667%",
  },
  colMd6: {
    width: "50%",
  },
  colMd7: {
    width: "58.33%",
  },
  colMd8: {
    width: "67.667%",
  },
  colMd9: {
    width: "75%",
  },
  colMd10: {
    width: "83.33%",
  },
  colMd11: {
    width: "91.667%",
  },
  colMd12: {
    width: "100%",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
});

export const table = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  headerCol: {
    display: "flex",
    flexDirection: "column",
  },
  headerCell: {
    fontSize: 10,
    padding: 4,
  },
  row: {
    flexDirection: "row",
    alignItems: "stretch",
    width: "100%",
  },
  col: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
  },
  zebra: {
    backgroundColor: "#eeeeee",
  },
  cell: {
    fontSize: 11,
    lineHeight: 1,
    padding: 3,
    margin: 0,
  },
  highlight: {
    backgroundColor: "#eeeeee",
  },
  footerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    borderTop: "2pt solid #dddddd",
  },
  footerCol: {
    display: "flex",
    flexDirection: "column",
  },
  footerCell: {
    fontSize: 11,
    padding: 4,
  },
});

export const colors = StyleSheet.create({
  primary: {
    color: "#1769FF",
  },
});

const padding = () => {
  const styles = {};
  for (let i = 0; i <= 40; i += 1) {
    const size = i * 5;
    styles[size] = {
      padding: size,
    };
    styles[`top${size}`] = {
      paddingTop: size,
    };
    styles[`right${size}`] = {
      paddingRight: size,
    };
    styles[`bottom${size}`] = {
      paddingBottom: size,
    };
    styles[`left${size}`] = {
      paddingLeft: size,
    };
    styles[`x${size}`] = {
      paddingLeft: size,
      paddingRight: size,
    };
    styles[`y${size}`] = {
      paddingTop: size,
      paddingBottom: size,
    };
  }
  return styles;
};

export const p = StyleSheet.create(padding());

const width = () => {
  const styles = {};
  for (let i = 0; i <= 100; i += 1) {
    const size = i;
    styles[`${size}%`] = {
      width: `${size}%`,
    };
    styles[`${size * 10}px`] = {
      paddingTop: size * 10,
    };
  }
  return styles;
};

export const w = StyleSheet.create(width());
