import React from "react";
import PropTypes from "prop-types";
import TableRow from "./TableRow";
import { ROLES } from "../Constants";
import { Can, TableSort } from "../Utils";

const TableList = ({ People, sortBy, direction, handleSort, ...rest }) => (
  <div className="listing">
    <div className="listing__header">
      <div className="listing__header-label">
        <TableSort
          label="First"
          name="first_name"
          sortBy={sortBy}
          direction={direction}
          handleSort={handleSort}
        />
      </div>
      <Can grantRoles={[ROLES.ENROLLMENT]}>
        <div className="listing__header-label">
          <TableSort
            label="Middle"
            name="middle_name"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
      </Can>
      <div className="listing__header-label">
        <TableSort
          label="Last"
          name="last_name"
          sortBy={sortBy}
          direction={direction}
          handleSort={handleSort}
        />
      </div>
      <Can grantRoles={[ROLES.ENROLLMENT, ROLES.CIVIL_ATTORNEY]}>
        <div className="listing__header-label">
          <TableSort
            label="Suffix"
            name="suffix"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
        <div className="listing__header-label">
          <TableSort
            label="Enrollment #"
            name="membership_number"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
      </Can>
      <Can grantRoles={[ROLES.REENTRY]}>
        <div className="listing__header-label">
          <TableSort
            label="Sex"
            name="sex"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
      </Can>
      <div className="listing__header-label">
        <TableSort
          label="Birth Date"
          name="born_on"
          sortBy={sortBy}
          direction={direction}
          handleSort={handleSort}
        />
      </div>
      <Can grantRoles={[ROLES.ENROLLMENT]}>
        <div className="listing__header-label">
          <TableSort
            label="Deceased Date"
            name="deceased_at"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </div>
      </Can>
      <Can grantRoles={[ROLES.ENROLLMENT]}>
        <div className="listing__header-label" />
      </Can>
    </div>
    {People.map((person) => (
      <TableRow key={person.id} {...rest} person={person} />
    ))}
  </div>
);

TableList.propTypes = {
  People: PropTypes.array.isRequired,
};

export default TableList;
