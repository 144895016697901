import { Mutation } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { MutationSubmit } from "../Utils";
import { ActionButton } from "./index";

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  _toggleModal = () => {
    this.setState((currentState) => ({ modal: !currentState.modal }));
  };

  render() {
    const {
      title,
      message,
      buttonText,
      mutation,
      mutationVars,
      successMessage,
      refetchQuery,
      refetchVars,
      size,
      className,
    } = this.props;

    const { modal } = this.state;

    return (
      <>
        <ActionButton
          id="modal-btn"
          onClick={this._toggleModal}
          className="case-status-btn"
          size="sm"
          color="secondary"
        >
          {buttonText}
        </ActionButton>
        <Modal
          isOpen={modal}
          toggle={this._toggleModal}
          className={className}
          size={size || "sm"}
        >
          <ModalHeader toggle={this._toggleModal}>{title}</ModalHeader>
          <ModalBody>{message}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
            <Mutation
              mutation={mutation}
              variables={mutationVars}
              onCompleted={() => {
                Toasts.success(successMessage);
                this._toggleModal();
              }}
              onError={(error) =>
                Toasts.danger({
                  title: "Looks like there was a problem",
                  error,
                })
              }
              refetchQueries={[{ query: refetchQuery, variables: refetchVars }]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  id="submit-btn"
                  color="danger"
                  label={buttonText}
                  loadingLabel="loading..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  mutation: PropTypes.shape.isRequired,
  mutationVars: PropTypes.shape.isRequired,
  successMessage: PropTypes.string.isRequired,
};

ConfirmationModal.defaultProps = {};

export default ConfirmationModal;
