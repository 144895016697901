import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  Page,
  PageHeader,
  PageHeaderSection,
  PageTitle,
} from "../../components";
import AccountDropdown from "../../Account/AccountDropdown";
import Layout from "../../Layout";

import { PageReports } from "../components";

import AgeBreakdown from "./AgeBreakdown";

const ReportsContainer = (props) => (
  <Layout>
    <Page>
      <PageHeader>
        <PageHeaderSection />
        <PageHeaderSection>
          <PageTitle>Reports</PageTitle>
        </PageHeaderSection>
        <PageHeaderSection>
          <AccountDropdown />
        </PageHeaderSection>
      </PageHeader>
      <PageReports>
        <Switch>
          <Route
            exact
            path="/reports/age-breakdown"
            render={(routeProps) => <AgeBreakdown {...props} {...routeProps} />}
          />
        </Switch>
      </PageReports>
    </Page>
  </Layout>
);

export default ReportsContainer;
