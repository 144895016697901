import React, { Component } from "react";
import {
  PDFViewer,
  BlobProvider,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image as RenderImage,
} from "@react-pdf/renderer";
import { Query } from "@apollo/client/react/components";
import queryString from "query-string";
import EnrollmentHeader from "./EnrollmentHeader";
import { PageReports } from "../../components";
import { ViewSeal } from "./ViewPartials";
import { common, layout, colors, p } from "../../styles";
import { PERSON } from "../graphql";
import {
  DEFAULT_QUERY_ERROR_POLICY,
  TRIBE_NAME,
  ENROLLMENT_DIRECTOR,
  PDF_LOADING_MESSAGE,
} from "../../../Constants";
import {
  checkForErrors,
  getPersonName,
  imageToBase64,
  formatDateTime,
  formatPersonExportFilename,
} from "../../../Utils";

// Create styles
const styles = StyleSheet.create({
  portrait: {
    width: 150,
    height: 150,
    padding: 20,
    position: "absolute",
  },
});

// Create Document Component
export const LetterOfDescendancyDocument = ({
  person,
  portrait,
  descendant,
}) => {
  return (
    <Document title="Letter of Descendancy">
      <Page size="Letter" style={layout.page}>
        <EnrollmentHeader />
        <View style={layout.container}>
          <View style={[layout.row, common.textCenter]}>
            <Text style={[common.h1]}>LETTER OF DESCENDANCY</Text>
          </View>
          <View style={[layout.row, common.textCenter, p.bottom50]}>
            <Text style={common.small}>{formatDateTime(new Date())}</Text>
          </View>
          <View style={[layout.row]}>
            <View style={[layout.colMd9, common.uppercase]}>
              <View style={[layout.row, p.bottom20]}>
                <Text style={[common.lead, common.bold]}>
                  TO WHOM IT MAY CONCERN:
                </Text>
              </View>
              <View style={[layout.row, p.bottom20]}>
                <Text style={[common.large, common.italic, colors.primary]}>
                  {descendant}
                </Text>
              </View>
              <View style={[layout.row]}>
                <Text style={[common.lead, common.bold, common.uppercase]}>
                  IS NOT AN ENROLLED CITIZEN OF THE {TRIBE_NAME}
                </Text>
              </View>
              <View style={[layout.row]}>
                <Text style={[common.lead, common.bold]}>
                  TRIBE. BUT IS A CHILD, GRANDCHILD, OR GREAT-
                </Text>
              </View>
              <View style={[layout.row, p.bottom20]}>
                <Text style={[common.lead, common.bold, common.uppercase]}>
                  GRANDCHILD OF {TRIBE_NAME} TRIBAL MEMBER:
                </Text>
              </View>
            </View>
            <View style={layout.colMd3}>
              <RenderImage src={portrait} style={styles.portrait} />
            </View>
          </View>
          <View style={[layout.row]}>
            <Text style={[common.lead, common.bold]}>
              {getPersonName(person)}
            </Text>
          </View>
          <View style={[layout.row, p.bottom20]}>
            <Text style={[common.lead, common.bold]}>
              {person.membershipNumber}
            </Text>
          </View>
          <View style={[layout.row, p.bottom50, common.uppercase]}>
            <Text style={[common.lead, common.bold, common.uppercase]}>
              I HEREBY CERTIFY, THAT THIS IS A TRUE AND CORRECT COPY OF OUR
              RECORDS HERE ON FILE WITH THE {TRIBE_NAME} TRIBAL ENROLLMENT
              DEPARTMENT, TRIBAL HEADQUARTERS, 850 A STREET, P.O. BOX 408,
              PLUMMER, IDAHO, 83851.
            </Text>
          </View>
          <View style={[layout.row, layout.spaceBetween, p.top50]}>
            <View style={[common.blankLine, p.right20, p.top5]}>
              <Text>{ENROLLMENT_DIRECTOR}, Enrollment Director</Text>
              <Text>{TRIBE_NAME} Tribe</Text>
            </View>
          </View>
          <ViewSeal />
        </View>
      </Page>
    </Document>
  );
};

class LetterOfDescendancy extends Component {
  constructor(props) {
    super(props);
    const search = queryString.parse(props.location.search);

    this.state = {
      descendant: search.descendant ? search.descendant : "",
      portrait: null,
    };
  }

  componentDidMount() {
    const { person } = this._getQueryVariables();
    const url = `${
      process.env.REACT_APP_IMAGE_URL
    }/${person}.jpg?v=${Date.now()}`;

    imageToBase64(url, (data) => this.setState({ portrait: data }));
  }

  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    const { type } = this.props;
    const { portrait, descendant } = this.state;

    if (!portrait || !descendant) {
      return null;
    }

    return (
      <Query
        query={PERSON.LETTER_OF_DESCENDANCY_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "Letter of Descendancy"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;
          const renderDocument = (
            <LetterOfDescendancyDocument
              person={person}
              portrait={portrait}
              descendant={descendant}
            />
          );

          if (type === "viewer") {
            return (
              <PageReports>
                <PDFViewer
                  style={{
                    width: "100%",
                    height: "100%",
                    border: 0,
                    maxHeight: "100%",
                    position: "absolute",
                  }}
                >
                  {renderDocument}
                </PDFViewer>
              </PageReports>
            );
          }
          return (
            <BlobProvider document={renderDocument}>
              {({ url, loading: blobLoading }) =>
                blobLoading ? (
                  PDF_LOADING_MESSAGE
                ) : (
                  <a
                    className="button"
                    href={url}
                    download={formatPersonExportFilename(person, "LOD")}
                  >
                    Letter of Descendancy
                  </a>
                )
              }
            </BlobProvider>
          );
        }}
      </Query>
    );
  }
}

export default LetterOfDescendancy;
