import React from "react";
import { ListGroup, ListGroupItem, FormGroup, Input, Label } from "reactstrap";
import { useAuthenticationContext } from "@selfdetermine/react-authentication";
import {
  PanelSingle,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Page,
  PageContent,
  PageHeader,
  PageTitle,
  PageHeaderSection,
} from "../components";
import Layout from "../Layout";
import { Can } from "../Utils";
import AccountDropdown from "./AccountDropdown";

export const Account = () => {
  const { user } = useAuthenticationContext();

  return (
    <Layout>
      <Page>
        <PageHeader>
          <PageHeaderSection />
          <PageHeaderSection>
            <PageTitle>Your Account</PageTitle>
          </PageHeaderSection>
          <PageHeaderSection>
            <AccountDropdown />
          </PageHeaderSection>
        </PageHeader>
        <PageContent>
          <PanelSingle>
            <PanelHeader>
              <PanelTitle>Your Email</PanelTitle>
            </PanelHeader>
            <PanelBody>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  disabled
                  placeholder={user.email}
                />
              </FormGroup>
            </PanelBody>
          </PanelSingle>

          <PanelSingle>
            <PanelHeader>
              <PanelTitle>Your Roles</PanelTitle>
            </PanelHeader>
            <PanelBody>
              <ListGroup>
                <Can>
                  <ListGroupItem>Administrator</ListGroupItem>
                </Can>
                {user.roles &&
                  user.roles.map((role) => (
                    <ListGroupItem key={role.name}>{role.name}</ListGroupItem>
                  ))}
              </ListGroup>
            </PanelBody>
          </PanelSingle>
        </PageContent>
      </Page>
    </Layout>
  );
};

export default Account;
