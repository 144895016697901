import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout";
import Index from "./Index.jsx";
import Show from "./Person/Show";
import New from "./New";

const PeopleContainer = (props) => (
  <Layout>
    <Switch>
      <Route
        exact
        path="/people/new"
        render={(routeProps) => <New {...props} {...routeProps} />}
      />
      <Route
        exact
        path="/people"
        render={(routeProps) => <Index {...props} {...routeProps} />}
      />
      <Route
        path="/people/:person"
        render={(routeProps) => <Show {...props} {...routeProps} />}
      />
    </Switch>
  </Layout>
);

export default PeopleContainer;
