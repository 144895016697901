import { gql } from "@selfdetermine/graphql";

export const CREATE_CASE_MUTATION = gql`
  mutation AddReentryCase($person: ID!, $reentryCase: NewReentryCaseInput!) {
    addReentryCase(person: $person, reentryCase: $reentryCase) {
      id
    }
  }
`;

export const UPDATE_REENTRY_CASE_MUTATION = gql`
  mutation UpdateReentryCase(
    $person: ID!
    $reentryCase: UpdateReentryCaseInput!
  ) {
    updateReentryCase(person: $person, reentryCase: $reentryCase) {
      id
    }
  }
`;

export const FIRST24_CASE_MUTATION = gql`
  mutation first24Case($person: ID!, $case: ID!) {
    first24Case(person: $person, case: $case) {
      id
    }
  }
`;

export const PHASE1_CASE_MUTATION = gql`
  mutation phase1Case($person: ID!, $case: ID!) {
    phase1Case(person: $person, case: $case) {
      id
    }
  }
`;

export const PHASE2_CASE_MUTATION = gql`
  mutation phase2Case($person: ID!, $case: ID!) {
    phase2Case(person: $person, case: $case) {
      id
    }
  }
`;

export const PHASE3_CASE_MUTATION = gql`
  mutation phase3Case($person: ID!, $case: ID!) {
    phase3Case(person: $person, case: $case) {
      id
    }
  }
`;

export const PHASE4_CASE_MUTATION = gql`
  mutation phase4Case($person: ID!, $case: ID!) {
    phase4Case(person: $person, case: $case) {
      id
    }
  }
`;

export const PHASE5_CASE_MUTATION = gql`
  mutation phase5Case($person: ID!, $case: ID!) {
    phase5Case(person: $person, case: $case) {
      id
    }
  }
`;

export const PHASE6_CASE_MUTATION = gql`
  mutation phase6Case($person: ID!, $case: ID!) {
    phase6Case(person: $person, case: $case) {
      id
    }
  }
`;

export const WRAPUP_CASE_MUTATION = gql`
  mutation wrapUpCase($person: ID!, $case: ID!) {
    wrapUpCase(person: $person, case: $case) {
      id
    }
  }
`;

export const GRADUATE_CASE_MUTATION = gql`
  mutation graduateCase($person: ID!, $case: ID!) {
    graduateCase(person: $person, case: $case) {
      id
    }
  }
`;

export const CREATE_REENTRY_NOTE = gql`
  mutation AddNoteToReentryCase($case: ID!, $body: String!) {
    addNoteToReentryCase(case: $case, body: $body) {
      id
    }
  }
`;

// Reentry case
export const REENTRY_CASE = {
  DETAILS_QUERY: gql`
    query Case($parent: ID!, $id: ID!) {
      viewer {
        reentryCase(parent: $parent, id: $id) {
          id
          attendedOutpatientTherapy
          currentMatPatient
          needDetox
          treatmentPastYear
          treatmentYearPlus
          referringEntity {
            id
            name
          }
          selfReferral
          violent
          drugAlcoholRelated
          mentalHealth
          description
          person {
            id
            hasInsurance
          }
          releasedAt
        }
      }
    }
  `,
};
