import { gql } from "@selfdetermine/graphql";

// Create mutation

export const CREATE_DEPARTMENT_MUTATION = gql`
  mutation addDepartment($department: NewDepartmentInput!) {
    addDepartment(department: $department) {
      id
    }
  }
`;

// Departments
export const DEPARTMENTS = {
  QUERY: gql`
    query Departments {
      viewer {
        departments {
          id
          name
          phoneNumber
          street1
          street2
          city
          state
          zipCode
        }
      }
    }
  `,

  SELECT_QUERY: gql`
    query Departments {
      viewer {
        departments {
          value: id
          label: name
          referrable
          email
        }
      }
    }
  `,
};
