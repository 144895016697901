import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout";
import Tribes from "./Tribes";
import { ROLES } from "../Constants";
import { withUserRole } from "../Utils";

const TribesContainer = (props) => (
  <Layout>
    <Switch>
      <Route
        exact
        path="/tribes"
        render={(routeProps) => <Tribes {...props} {...routeProps} />}
      />
    </Switch>
  </Layout>
);

const wrappedWithUserRole = withUserRole(TribesContainer, [
  ROLES.ENROLLMENT,
  ROLES.ENROLLMENT_DIRECTOR,
]);

export default wrappedWithUserRole;
