export const DEFAULT_ADDRESS_INPUT = {
  street1: null,
  street2: null,
  city: null,
  state: null,
  zipCode: null,
  kind: null,
  active: null,
  person: null,
};
