import React, { Component } from "react";
import * as d3 from "d3";

const STRING_LENGTH = 15;
const truncateName = (name) => {
  const truncated = name.slice(0, STRING_LENGTH);
  return truncated === name ? truncated : truncated.concat("...");
};

const checkForNull = (item) => item || "unauthorized";

class Sunburst extends Component {
  componentDidMount() {
    this.renderSunburst();
  }

  renderSunburst() {
    const { handleClick } = this.props;

    const width = 400;
    const height = 400;
    const radius = Math.min(width, height) / 1.1;
    const color = d3
      .scaleOrdinal()
      .range([
        "#98abc5",
        "#8a89a6",
        "#7b6888",
        "#6b486b",
        "#a05d56",
        "#d0743c",
        "#ff8c00",
      ]);

    const svg = d3.select("#sunburst").append("svg");

    // Create primary <g> element
    const zoomG = svg.attr("width", "100%").attr("height", "100%").append("g");

    const g = zoomG
      .append("g")
      .attr("transform", `translate(${width * 1.5}, ${height})`);

    svg.call(
      d3.zoom().on("zoom", () => zoomG.attr("transform", d3.event.transform))
    );

    // Data structure
    // 1) Creates a new partition layout
    // 2) Sets this partition layout’s size to the
    // specified two-element array of numbers [width, height] and returns this partition layout
    const partition = d3.partition().size([2 * Math.PI, radius]);

    // Constructs a root node from the specified hierarchical data
    const root = d3.hierarchy(this.props.data).sum((d) => (d.children ? 0 : 1));

    // Define the div for the tooltip
    const toolTip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0.9);

    // Size arcs
    partition(root);
    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .innerRadius((d) => d.y0)
      .outerRadius((d) => d.y1);

    // Drawing the chart
    g.selectAll("path")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .append("path")
      .attr("class", "tool-tip-hover")
      .style("cursor", "pointer")
      .attr("display", (d) => (d.depth ? null : "none"))
      .attr("d", arc)
      .style("stroke", "#fff")
      .style("fill", (d) => {
        if (d.data.name === "unknown") {
          return "#a4abb5";
        }
        if (d.parent === null) {
          return color(d.data.name);
        }
        return color((d.children ? d : d.parent).data.name);
      })
      .on("click", (d) => {
        toolTip.transition().duration(50).style("opacity", 0);

        handleClick(d);
      });

    g.selectAll(".node")
      .append("text")
      .style("cursor", "pointer")
      .style("text-anchor", "middle") // center text horizontally
      .attr("class", "tool-tip-hover")
      .attr("font-size", (d) => `${1 - (d.depth ? d.depth / 8 : 1)}1em`)
      .text((d) => truncateName(d.data.name))
      .on("click", (d) => {
        toolTip.transition().duration(50).style("opacity", 0);

        handleClick(d);
      })
      .attr("transform", (d) => {
        let angle = ((d.x0 + d.x1) / Math.PI) * 90;
        const centroid = arc.centroid(d);

        // Avoid upside-down labels
        if (angle > 120 && angle < 270) {
          angle += 180;
        }

        // moving the center text into place
        if (d.depth === 0) {
          centroid[1] -= 30;
        }

        return `translate(${centroid})rotate(${angle})`;
      });

    d3.selectAll(".tool-tip-hover")
      .on("mouseover", (d) => {
        const html =
          d.data.name !== "unknown"
            ? `${checkForNull(d.data.name)}<br/>
            enrolled: ${checkForNull(d.data.enrolledBloodQuantum)}<br/>
            other: ${checkForNull(d.data.otherBloodQuantum)}<br/>
            total: ${checkForNull(d.data.totalBloodQuantum)}`
            : d.data.name;

        toolTip.html(html).transition().duration(200).style("opacity", 0.9);
      })
      .on("mouseout", () =>
        toolTip.transition().duration(50).style("opacity", 0)
      )
      .on("mousemove", () =>
        toolTip
          .style("left", `${d3.event.pageX}px`)
          .style("top", `${d3.event.pageY}px`)
      );

    return null;
  }

  render() {
    return <div id="sunburst" />;
  }
}

export default Sunburst;
