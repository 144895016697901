import { Query } from "@apollo/client/react/components";
import React, { Component } from "react";

import {
  EmptyListing,
  Panel,
  PanelHeader,
  PanelTitle,
} from "../../../components";
import { checkForErrors } from "../../../Utils";

import CaseTableRow from "./CaseTableRow";
import { CASES_QUERY } from "./graphql";
import NewCase from "./New";

class CasesListing extends Component {
  _getQueryVariables = () => {
    return {
      id: this.props.match.params.person,
    };
  };

  render() {
    return (
      <Query query={CASES_QUERY} variables={this._getQueryVariables()}>
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(loading, error, data, "cases");
          if (loadingOrError) {
            return loadingOrError;
          }

          const { reentryCases } = data.viewer.person;

          return (
            <Panel id="overview">
              <PanelHeader>
                <PanelTitle>Reentry</PanelTitle>
                <NewCase
                  caseType="Reentry"
                  person={this.props.person}
                  getQueryVars={this._getQueryVariables}
                />
              </PanelHeader>
              <div style={{ background: "rgb(253,253,253)", padding: 0 }}>
                <div className="listing">
                  <div className="listing__header">
                    <div className="listing__header-label">Case #</div>
                    <div className="listing__header-label">Description</div>
                    <div className="listing__header-label">
                      Referring Department
                    </div>
                    <div className="listing__header-label">Released Date</div>
                    <div className="listing__header-label">Self Referral</div>
                    <div className="listing__header-label">Status</div>
                  </div>
                  {reentryCases.map((reentry) => (
                    <CaseTableRow
                      key={`department-summary-${reentry.id}`}
                      reentry={reentry}
                      person={this.props.person}
                    />
                  ))}
                </div>
                {reentryCases.length < 1 ? (
                  <EmptyListing {...this.props}>No Cases</EmptyListing>
                ) : null}
              </div>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

export default CasesListing;
