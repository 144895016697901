import { Query } from "@apollo/client/react/components";
import React from "react";

import {
  CardActions,
  CardTable,
  EmptyListing,
  Panel,
  PanelHeader,
  PanelTitle,
  TableRow,
} from "../components";
import { checkForErrors } from "../Utils";

import { REFERRALS_QUERY } from "./graphql";
import NewReferral from "./NewReferral";

const getQueryVars = (props) => {
  return {
    parent: props.match.params.person,
    id: props.match.params.id,
  };
};

const Referrals = (props) => (
  <Query query={REFERRALS_QUERY} variables={getQueryVars(props)}>
    {({ loading, error, data }) => {
      const loadingOrError = checkForErrors(loading, error, data, "overview");
      if (loadingOrError) {
        return loadingOrError;
      }

      const kase = data.viewer.case;

      return (
        <Panel id="case-referrals">
          <PanelHeader>
            <PanelTitle>Referrals</PanelTitle>
            <CardActions>
              <NewReferral
                caseId={kase.id}
                refetchQuery={REFERRALS_QUERY}
                refetchVars={() => getQueryVars(props)}
              />
            </CardActions>
          </PanelHeader>
          <CardTable
            className="mb-0 index-table"
            fluid="true"
            hover
            striped
            responsive
          >
            <thead className="small">
              <tr>
                <th>Referred Department</th>
                <th>Referring Department</th>
                <th>Created By</th>
                <th>ID</th>
                <th>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {kase.referrals.map((referral) => (
                <TableRow
                  href={`/people/${kase.person.id}/${
                    kase.kind === "reentry" ? "reentry" : "matters"
                  }/${kase.id}/referrals/${referral.id}`}
                >
                  <td>{referral.referred.name}</td>
                  <td>{referral.referring.name}</td>
                  <td>{referral.createdBy.email}</td>
                  <td>{referral.id}</td>
                  <td>{referral.status}</td>
                  <td style={{ textAlign: "left" }}>{referral.description}</td>
                </TableRow>
              ))}
            </tbody>
          </CardTable>
          {kase.referrals.length < 1 ? (
            <EmptyListing>No Referrals</EmptyListing>
          ) : null}
        </Panel>
      );
    }}
  </Query>
);
export default Referrals;
