import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Table, Row, Col, FormGroup, Label, CardBody } from "reactstrap";

import {
  Panel,
  PanelHeader,
  PanelTitle,
  CardActions,
} from "../../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES, ui } from "../../../Constants";
import { Can, checkForErrors } from "../../../Utils";

import { PERSON } from "../graphql";

import BloodQuantumModalForm from "./BloodQuantumModalForm";
import DeleteBloodQuantumModalForm from "./DeleteBloodQuantumModalForm";

class BloodQuanta extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.BLOOD_QUANTA_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "blood quanta"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          const bloodQuantum = {
            person: person.id,
          };

          return (
            <Panel id="blood-quanta">
              <PanelHeader>
                <PanelTitle>Blood Quantum</PanelTitle>
                <Can grantRoles={[ROLES.ENROLLMENT]}>
                  <CardActions>
                    <BloodQuantumModalForm blood_quantum={bloodQuantum}>
                      {ui.icon.plus} Add Blood Quantum
                    </BloodQuantumModalForm>
                  </CardActions>
                </Can>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label>Enrolled</Label>
                      <strong>{person.enrolledBloodQuantum}</strong>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label>Other</Label>
                      <strong>{person.otherBloodQuantum}</strong>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4}>
                    <FormGroup>
                      <Label>Total</Label>
                      <strong>{person.totalBloodQuantum}</strong>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <Table className="mb-0" fluid="true" hover striped responsive>
                <tbody>
                  {person.blood_quanta &&
                    person.blood_quanta.map((bq) => (
                      <tr key={bq.id} id={`blood_quantum-${bq.id}`}>
                        <td>{`${bq.numerator}/${bq.denominator}`}</td>
                        <td className="w-100">
                          {bq.tribe ? bq.tribe.name : null}
                        </td>
                        <Can grantRoles={[ROLES.ENROLLMENT]}>
                          <td>
                            <DeleteBloodQuantumModalForm
                              person={person}
                              bloodQuantum={bq}
                              right
                            >
                              {ui.icon.trash}
                            </DeleteBloodQuantumModalForm>
                          </td>
                        </Can>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

BloodQuanta.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

BloodQuanta.defaultProps = {};

export default BloodQuanta;
