import { Query } from "@apollo/client/react/components";
import queryString from "query-string";
import React, { Component } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { Redirect, Switch, Route, NavLink } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import AccountDropdown from "../../Account/AccountDropdown";
import {
  Page,
  PageTitle,
  BackLink,
  UserDropdownToggle,
  PageHeader,
  PageHeaderSection,
  PersonContent,
  ButtonIcn,
  PersonMain,
  PersonTabBar,
  PersonTabs,
  PersonTabContent,
  PersonNavItem,
  PersonNavLink,
} from "../../components";
import { DEFAULT_QUERY_ERROR_POLICY, ROLES, ui } from "../../Constants";
import { PERSON } from "./graphql";
import { Can, checkForErrors, PageNotFound, withUserRole } from "../../Utils";

import ReentryCases from "./Cases/Reentry";
import Contact from "./Contact";
import Details from "./Details";
import Enrollment from "./Enrollment";
import Family from "./Family";
import GetDescendantNameModal from "./GetDescendantNameModal";
import GetNotesModal from "./GetNotesModal";
import History from "./History";
import Information from "./Information";
import Matters from "./Cases/Matters";
import Notes from "./Notes";
import {
  EnrollmentVerification,
  NoticeOfAcceptance,
  CertificationOfIndianBlood,
  LetterOfDescendancy,
  AncestryChart,
} from "./PDFs";

class Show extends Component {
  constructor(props) {
    super(props);
    const search = queryString.parse(props.location.search);

    this.state = {
      activeTab: search.tabs ? search.tabs : "information",
      lodModal: false,
      levModal: false,
    };
  }

  _toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });

      this.props.history.push({
        search: `?${new URLSearchParams({ tabs: tab }).toString()}`,
      });
    }
  };

  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  _handleClickPdf = (e, pdf) => {
    const { person } = this.props.match.params;
    window.location.href = `/people/${person}/${pdf}`;
  };

  _toggleLODClick = () => {
    this.setState((currentState) => ({ lodModal: !currentState.lodModal }));
  };

  _toggleLEVClick = () => {
    this.setState((currentState) => ({ levModal: !currentState.levModal }));
  };

  render() {
    const { person: personId } = this.props.match.params;
    if (this.props.location.pathname === `/people/${personId}`) {
      return <Redirect to={`/people/${personId}/information`} />;
    }

    return (
      <Query
        query={PERSON.QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "person details"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          const tabs = [
            {
              id: "information",
              label: "Information",
              hasTab: true,
              roles: [
                ROLES.ENROLLMENT,
                ROLES.REENTRY,
                ROLES.SELF,
                ROLES.CIVIL_ATTORNEY,
                ROLES.FINANCE,
              ],
              component: <Information person={person} {...this.props} />,
            },
            {
              id: "contact",
              label: "Contact",
              hasTab: true,
              roles: [
                ROLES.REENTRY,
                ROLES.ENROLLMENT,
                ROLES.SELF,
                ROLES.CIVIL_ATTORNEY,
                ROLES.FINANCE,
              ],
              component: <Contact person={person} {...this.props} />,
            },
            {
              id: "enrollment",
              label: "Enrollment",
              hasTab: true,
              roles: [ROLES.ENROLLMENT, ROLES.SELF],
              component: <Enrollment person={person} {...this.props} />,
            },
            {
              id: "family",
              label: "Family",
              hasTab: true,
              roles: [ROLES.ENROLLMENT, ROLES.CIVIL_ATTORNEY],
              component: <Family person={person} {...this.props} />,
            },
            {
              id: "reentry",
              label: "Reentry Cases",
              hasTab: true,
              roles: [ROLES.REENTRY, ROLES.REFERRED],
              component: <ReentryCases person={person} {...this.props} />,
            },
            {
              id: "matters",
              label: "Civil Matters",
              hasTab: true,
              roles: [ROLES.CIVIL_ATTORNEY],
              component: <Matters person={person} {...this.props} />,
            },
            {
              id: "history",
              label: "History",
              hasTab: true,
              roles: [ROLES.ENROLLMENT],
              component: <History person={person} {...this.props} />,
            },
            {
              id: "notes",
              label: "Notes",
              hasTab: true,
              roles: [ROLES.ENROLLMENT, ROLES.SELF],
              component: <Notes person={person} {...this.props} />,
            },
            {
              id: "enrollment-verification",
              hasTab: false,
              roles: [ROLES.ENROLLMENT],
              component: (
                <EnrollmentVerification
                  type="viewer"
                  person={person}
                  {...this.props}
                />
              ),
            },
            {
              id: "notice-of-acceptance",
              hasTab: false,
              roles: [ROLES.ENROLLMENT],
              component: (
                <NoticeOfAcceptance
                  type="viewer"
                  person={person}
                  {...this.props}
                />
              ),
            },
            {
              id: "certification-of-indian-blood",
              hasTab: false,
              roles: [ROLES.ENROLLMENT],
              component: (
                <CertificationOfIndianBlood
                  type="viewer"
                  person={person}
                  {...this.props}
                />
              ),
            },
            {
              id: "letter-of-descendancy",
              hasTab: false,
              roles: [ROLES.ENROLLMENT],
              component: (
                <LetterOfDescendancy
                  type="viewer"
                  person={person}
                  {...this.props}
                />
              ),
            },
            {
              id: "ancestry-chart",
              hasTab: false,
              roles: [ROLES.ENROLLMENT],
              component: (
                <AncestryChart type="viewer" person={person} {...this.props} />
              ),
            },
          ];

          return (
            <Page>
              <PageHeader>
                <PageHeaderSection>
                  <Can
                    grantRoles={[
                      ROLES.ENROLLMENT,
                      ROLES.FINANCE,
                      ROLES.REENTRY,
                      ROLES.REFERRED,
                      ROLES.CIVIL_ATTORNEY,
                    ]}
                  >
                    <NavLink to="/people" href="/people">
                      <BackLink>
                        <ButtonIcn>{ui.icon.chevron_left}</ButtonIcn>All People
                      </BackLink>
                    </NavLink>
                  </Can>
                </PageHeaderSection>

                <PageHeaderSection>
                  <PageTitle>
                    {person.first_name} {person.last_name}
                  </PageTitle>
                </PageHeaderSection>

                <PageHeaderSection>
                  <Can grantRoles={[ROLES.ENROLLMENT]}>
                    <UncontrolledButtonDropdown>
                      <UserDropdownToggle size="sm" caret>
                        <ButtonIcn icon="file-download" />
                        <span>Export</span>
                      </UserDropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => this._toggleLEVClick()}>
                          Enrollment Verification
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            this._handleClickPdf(e, "notice-of-acceptance")
                          }
                        >
                          Notice of Acceptance
                        </DropdownItem>
                        <DropdownItem onClick={() => this._toggleLODClick()}>
                          Letter of Descendancy
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            this._handleClickPdf(
                              e,
                              "certification-of-indian-blood"
                            )
                          }
                        >
                          Certification of Indian Blood
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            this._handleClickPdf(e, "ancestry-chart")
                          }
                        >
                          Ancestry Chart
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Can>
                  <AccountDropdown />
                </PageHeaderSection>
              </PageHeader>

              <PersonContent>
                <GetDescendantNameModal
                  isOpen={this.state.lodModal}
                  toggle={this._toggleLODClick}
                  handleClickPdf={this._handleClickPdf}
                  person={this.props.match.params.person}
                />
                <GetNotesModal
                  isOpen={this.state.levModal}
                  toggle={this._toggleLEVClick}
                  handleClickPdf={this._handleClickPdf}
                  person={this.props.match.params.person}
                />

                <Details person={person} {...this.props} />

                <PersonMain>
                  <PersonTabBar>
                    <PersonTabs>
                      {tabs.map((tab) =>
                        tab.hasTab ? (
                          <Can key={tab.id} grantRoles={tab.roles}>
                            <PersonNavItem>
                              <NavLink
                                to={`/people/${person.id}/${tab.id}`}
                                href={`/people/${person.id}/${tab.id}`}
                                activeStyle={{
                                  display: "block",
                                  backgroundColor: "#515e6a",
                                  color: "white",
                                  textDecoration: "none",
                                  borderRadius: "0.25rem",
                                }}
                                id={`${tab.id}-tab`}
                              >
                                <PersonNavLink>{tab.label}</PersonNavLink>
                              </NavLink>
                            </PersonNavItem>
                          </Can>
                        ) : null
                      )}
                    </PersonTabs>
                  </PersonTabBar>

                  <PersonTabContent>
                    <Switch>
                      {tabs.map((tab) => (
                        <Route
                          key={tab.id}
                          path={`/people/:person/${tab.id}`}
                          component={() => tab.component}
                        />
                      ))}
                      <Route component={PageNotFound} />
                    </Switch>
                  </PersonTabContent>
                </PersonMain>
              </PersonContent>
            </Page>
          );
        }}
      </Query>
    );
  }
}

Show.defaultProps = {};

const wrappedWithUserRole = withUserRole(Show, [
  ROLES.ENROLLMENT,
  ROLES.FINANCE,
  ROLES.REENTRY,
  ROLES.REFERRED,
  ROLES.SELF,
  ROLES.CIVIL_ATTORNEY,
]);
export default withApollo(wrappedWithUserRole);
