import { Mutation } from "@apollo/client/react/components";
import { pick } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { ActionButton } from "../../../components";
import { ui } from "../../../Constants";
import { MutationSubmit } from "../../../Utils";

import { PERSON, UPDATE_PERSON_MUTATION } from "../graphql";

import MembershipInput from "./MembershipInput";

class MembershipModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      person: { ...props.person },
    };
  }

  _toggleModal = () => {
    this.setState((prevState) => {
      const updated = !prevState.modal;
      const newState = Object.assign(prevState, { modal: updated });

      return { newState };
    });
  };

  _handleChange = (e, n, v) => {
    const { target } = e;
    let name = n;
    let value = v;

    if (!name) {
      name = target.name;
      value = target.type === "checkbox" ? target.checked : target.value;
    }

    const state = this.state.person;
    state[name] = value;
    this.setState(state);
  };

  render() {
    const { person } = this.state;
    const { title, children, right } = this.props;

    const input = pick(person, [
      "id",
      "memberStatus",
      "membershipNumber",
      "resolutionNumber",
      "enrolledAt",
      "appliedAt",
      "cardIssuedAt",
    ]);

    return (
      <>
        <ActionButton
          onClick={this._toggleModal}
          className={`btn-secondary ${right ? "float-right" : ""}`}
          size="sm"
        >
          {children || <>{ui.icon.plus} New</>}
        </ActionButton>
        <Modal
          isOpen={this.state.modal}
          toggle={this._toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this._toggleModal}>
            {title || "Edit General Information"}
          </ModalHeader>
          <ModalBody>
            <MembershipInput
              person={person}
              handleChange={this._handleChange}
            />
          </ModalBody>
          <ModalFooter>
            <Mutation
              mutation={UPDATE_PERSON_MUTATION}
              variables={{ person: input }}
              onCompleted={() => {
                Toasts.success("Membership updated");
                this._toggleModal();
              }}
              onError={(error) =>
                Toasts.danger("Unable to update membership", error)
              }
              refetchQueries={[
                {
                  query: PERSON.MEMBERSHIP_QUERY,
                  variables: { person: input.id },
                },
              ]}
            >
              {(callMutation, { loading }) => (
                <MutationSubmit
                  color="warning"
                  label="Save"
                  loadingLabel="Saving..."
                  onClick={callMutation}
                  loading={loading}
                />
              )}
            </Mutation>
            <Button color="secondary" onClick={this._toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

MembershipModalForm.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

MembershipModalForm.defaultProps = {};

export default MembershipModalForm;
