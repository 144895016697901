import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import DepartmentTableRow from "./DepartmentTableRow";
import { TableSort } from "../Utils";

const DepartmentTableList = ({
  Departments,
  sortBy,
  direction,
  handleSort,
  ...rest
}) => (
  <Table className="mb-0 index-table" fluid="true" hover striped responsive>
    <thead>
      <tr>
        <th>
          ID{" "}
          <TableSort
            name="firstName"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </th>
        <th>
          Name{" "}
          <TableSort
            name="middleName"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </th>
        <th>
          Phone #{" "}
          <TableSort
            name="kind"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </th>
        <th>
          Address{" "}
          <TableSort
            name="lastName"
            sortBy={sortBy}
            direction={direction}
            handleSort={handleSort}
          />
        </th>
      </tr>
    </thead>
    <tbody>
      {Departments.map((department) => (
        <DepartmentTableRow
          key={`department-summary-${department.id}`}
          {...rest}
          department={department}
        />
      ))}
    </tbody>
  </Table>
);

DepartmentTableList.propTypes = {
  Departments: PropTypes.array.isRequired,
};

export default DepartmentTableList;
