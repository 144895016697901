import PropTypes from "prop-types";
import React from "react";

import { ROLES } from "../../../Constants";
import { withUserRole } from "../../../Utils";

import Addresses from "./Addresses";
import ContactInfo from "./ContactInfo";

const Contact = (props) => {
  return (
    <>
      <ContactInfo {...props} />
      <Addresses {...props} />
    </>
  );
};

Contact.propTypes = {
  person: PropTypes.object.isRequired,
};

Contact.defaultProps = {};

const wrappedWithUserRole = withUserRole(Contact, [
  ROLES.CIVIL_ATTORNEY,
  ROLES.ENROLLMENT,
  ROLES.FINANCE,
  ROLES.REENTRY,
  ROLES.SELF,
]);

export default wrappedWithUserRole;
