import React, { Component } from "react";
import download from "downloadjs";
import queryString from "query-string";
import { pickBy, identity } from "lodash";
import { Page, PageRow } from "../components";
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_MEMBER_STATUS,
  ROLES,
} from "../Constants";
import HeaderBar from "./HeaderBar";
import List from "./List";
import MapView from "./MapView";
import { withUserRole, routes } from "../Utils";

// class Index
class Index extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search);

    this.state = {
      open: true,
      listType: "table",
      filters: {
        search: query.search ? query.search : null,
        memberStatus: query.memberStatus
          ? query.memberStatus
          : DEFAULT_MEMBER_STATUS,
        sex: query.sex ? query.sex : null,
        onRez: query.onRez ? query.onRez : false,
        offRez: query.offRez ? query.offRez : false,
        birthdateStart: query.birthdateStart ? query.birthdateStart : null,
        birthdateEnd: query.birthdateEnd ? query.birthdateEnd : null,
        hasReentryCases: query.hasReentryCases ? query.hasReentryCases : false,
        hasActiveReentryCases: query.hasActiveReentryCases
          ? query.hasActiveReentryCases
          : false,
        hasMatters: query.hasNotes ? query.hasNotes : false,
        hasNilTribe: query.hasNilTribe ? query.hasNilTribe : false,
        hasNoBloodQuanta: query.hasNoBloodQuanta
          ? query.hasNoBloodQuanta
          : false,
        noParents: query.noParents ? query.noParents : false,
        hasNoBloodQuantaByTribe: query.hasNoBloodQuantaByTribe
          ? parseInt(query.hasNoBloodQuantaByTribe, 10)
          : null,
        tribe: query.tribe ? parseInt(query.tribe, 10) : null,
        hasNotes: query.hasNotes ? query.hasNotes : false,
        hasNoActiveMailingResidence: query.hasNoActiveMailingResidence
          ? query.hasNoActiveMailingResidence
          : false,
      },
      sort: query.sort
        ? query.sort
        : {
            column: "last_name",
            direction: "ASC",
          },
      first: query.first ? query.first : DEFAULT_ITEMS_PER_PAGE,
    };

    this.refetch = null;
  }

  streamFetch = () => {
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/reports.csv?${this._getExportQueryString()}&num=dd6d3b5b972df71467af8187f7c8c5ab`
    )
      .then(function (response) {
        return response.text();
      })
      .then(function (text) {
        download(text, "people.csv", "text/plain");
      });
  };

  exportAgeBreakdown = () => {
    window.location.href = "/reports/age-breakdown";
  };

  _getQueryVariables = () => {
    const { first, filters, sort } = this.state;
    return {
      filters,
      sort,
      first,
    };
  };

  _getExportQueryString = () => {
    const { sort, filters } = this.state;
    const params = pickBy({ ...sort, ...filters }, identity);
    return Object.keys(params)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
  };

  _handleCloseFilters = () => {
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  };

  _handleChangeFilters = (filters) => {
    const variables = { ...this._getQueryVariables() };

    const state = {
      filters: { ...variables.filters, ...filters },
    };

    this._updateQuery(state);
  };

  _handleGlossary = (e, letter) => {
    const variables = { ...this._getQueryVariables() };
    variables.filters.glossary = letter;
    this._updateQuery(variables);
  };

  _handleListType = (e, type) => {
    this._updateQuery({ listType: type });
  };

  _handleSort = (e, sortBy, direction) => {
    this._updateQuery({
      sort: {
        column: sortBy,
        direction,
      },
    });
  };

  _updateQuery = (state) => {
    this.setState(state);
    if (this.refetch) {
      this.refetch();
    }
  };

  _setRefetch = (refetch) => {
    this.refetch = refetch;
  };

  render() {
    const { listType } = this.state;

    const query = queryString.parse(this.props.location.search);
    let renderedView;

    if (query.view === "map") {
      renderedView = (
        <MapView
          filters={this.state.filters}
          open={this.state.open}
          handleChangeFilters={this._handleChangeFilters}
          handleCloseFilters={this._handleCloseFilters}
        />
      );
    } else {
      renderedView = (
        <List
          {...this.props}
          filters={this.state.filters}
          listType={listType}
          open={this.state.open}
          handleChangeFilters={this._handleChangeFilters}
          handleCloseFilters={this._handleCloseFilters}
          getQueryVariables={this._getQueryVariables}
          sort={this.state.sort}
          handleSort={this._handleSort}
          handleGlossary={this._handleGlossary}
          setRefetch={this._setRefetch}
        />
      );
    }

    return (
      <Page>
        <HeaderBar
          handleListType={this._handleListType}
          listType={listType}
          streamFetch={this.streamFetch}
          exportAgeBreakdown={this.exportAgeBreakdown}
        />
        <PageRow className="IndexPage">{renderedView}</PageRow>
      </Page>
    );
  }
}

List.propTypes = {};

const wrappedWithUserRole = withUserRole(
  Index,
  [
    ROLES.ENROLLMENT,
    ROLES.FINANCE,
    ROLES.MEMBERSHIP,
    ROLES.REENTRY,
    ROLES.ENROLLMENT_DIRECTOR,
    ROLES.CIVIL_ATTORNEY,
  ],
  { fallbackTo: routes.account() }
);
export default wrappedWithUserRole;
