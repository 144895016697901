import { gql } from "@selfdetermine/graphql";

export const ACCEPT_CASE_MUTATION = gql`
  mutation acceptCase($person: ID!, $case: ID!) {
    acceptCase(person: $person, case: $case) {
      id
    }
  }
`;

export const CLOSE_CASE_MUTATION = gql`
  mutation closeCase($person: ID!, $case: ID!) {
    closeCase(person: $person, case: $case) {
      id
    }
  }
`;

// TODO: all case notes are added through CREATE_MATTER_NOTE, CREATE_CASE_NOTE is unused
export const CREATE_CASE_NOTE = gql`
  mutation AddNoteToCase($case: ID!, $body: String!) {
    addNoteToCase(case: $case, body: $body) {
      id
    }
  }
`;

// TODO: Adding as a placeholder to resolve linter errors
export const UPDATE_CASE_MUTATION = gql`
  mutation updateCase($case: ID!, $body: String!) {
    updateCAse(case: $case, body: $body) {
      id
    }
  }
`;

// Cases
export const CASES_QUERY = gql`
  query Cases($id: ID!) {
    viewer {
      person(id: $id) {
        id
        reentryCases {
          id
          kind
          referringEntity {
            name
            id
          }
          releasedAt
          status
          description
          selfReferral
        }
      }
    }
  }
`;

// Case
export const CASE = {
  KIND_QUERY: gql`
    query Case($parent: ID!, $id: ID!) {
      viewer {
        case(parent: $parent, id: $id) {
          kind
        }
      }
    }
  `,
  SHARED_DETAILS: gql`
    query Case($parent: ID!, $id: ID!) {
      viewer {
        case(parent: $parent, id: $id) {
          id
          description
          createdAt
          closedAt
          openedOn
          referringEntity {
            id
            name
          }
          selfReferral
        }
      }
    }
  `,
  STATUS_QUERY: gql`
    query Case($parent: ID!, $id: ID!) {
      viewer {
        case(parent: $parent, id: $id) {
          id
          status
          person {
            id
          }
          permittedStates
        }
      }
    }
  `,
  NOTES_QUERY: gql`
    query Case($parent: ID!, $id: ID!) {
      viewer {
        case(parent: $parent, id: $id) {
          id
          kind
          notes {
            id
            createdAt
            createdBy {
              email
            }
            body
          }
        }
      }
    }
  `,
};
