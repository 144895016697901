import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Badge, ListGroupItem } from "reactstrap";
import { EmailAddress, PhoneNumber } from "../Utils";

class GroupItem extends Component {
  _handleClick = (e, person) => {
    window.open(`/people/${person.id}`, "_blank");
  };

  render() {
    const { person } = this.props;

    const statusColor = {
      enrolled: "success",
      inactive: "primary",
      deceased: "danger",
      relinquished: "danger",
      disenrolled: "warning",
      other_indian: "secondary",
    };

    return (
      <ListGroupItem
        onClick={(e) => this._handleClick(e, person)}
        className="clickable"
        action
      >
        <Row>
          <Col sm="auto">
            <img
              className="img-fluid person__photo"
              alt={`${person.first_name} ${person.middle_name} ${person.last_name}`}
              src="https://via.placeholder.com/100x100"
            />
          </Col>
          <Col sm={4}>
            <Badge color={statusColor[person.member_status]} size="sm">
              {person.member_status}
            </Badge>
            <h3>{person.full_name}</h3>
            <div>{person.membership_number}</div>
          </Col>
          <Col sm={4}>
            <div>
              <EmailAddress email={person.email} />
            </div>
            <div>
              <PhoneNumber phone={person.mobile} />
            </div>
            <div>
              <PhoneNumber phone={person.phone1} />
            </div>
            <div>
              <PhoneNumber phone={person.phone2} />
            </div>
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
}

GroupItem.propTypes = {
  person: PropTypes.shape.isRequired,
};

export default GroupItem;
