import React from "react";
import { Nav } from "reactstrap";
import { NavLink } from "react-router-dom";
import {
  AppNavGroup,
  AppNavHeading,
  AppNavIcn,
  AppNavList,
  AppNavListItem,
  AppNavItem,
} from "../components";
import { ROLES, ui } from "../Constants";
import { Can } from "../Utils";

export const AppNav = () => (
  <Nav vertical>
    <AppNavGroup>
      <AppNavHeading>Menu</AppNavHeading>
      <AppNavList>
        <Can grantRoles={[ROLES.REENTRY, ROLES.ENROLLMENT]}>
          <AppNavListItem>
            <NavLink
              to="/dashboard"
              href="/dashboard"
              activeStyle={{ backgroundColor: "#515e6a" }}
            >
              <AppNavItem>
                <AppNavIcn>{ui.icon.dashboard}</AppNavIcn>
                Dashboard
              </AppNavItem>
            </NavLink>
          </AppNavListItem>
        </Can>

        <Can
          grantRoles={[
            ROLES.REENTRY,
            ROLES.ENROLLMENT,
            ROLES.FINANCE,
            ROLES.MEMBERSHIP,
            ROLES.CIVIL_ATTORNEY,
            ROLES.FINANCE,
          ]}
        >
          <AppNavListItem>
            <NavLink
              to="/people"
              href="/people"
              activeStyle={{ backgroundColor: "#515e6a" }}
            >
              <AppNavItem>
                <AppNavIcn>{ui.icon.people}</AppNavIcn>
                People
              </AppNavItem>
            </NavLink>
          </AppNavListItem>
        </Can>
        <Can grantRoles={[ROLES.REENTRY, ROLES.CIVIL_ATTORNEY]}>
          <AppNavListItem>
            <NavLink
              to="/departments"
              href="/departments"
              activeClassName="active"
              activeStyle={{ backgroundColor: "#515e6a" }}
            >
              <AppNavItem>
                <AppNavIcn>{ui.icon.org}</AppNavIcn>
                Departments
              </AppNavItem>
            </NavLink>
          </AppNavListItem>
        </Can>

        <Can grantRoles={[ROLES.ENROLLMENT_DIRECTOR, ROLES.ENROLLMENT]}>
          <AppNavListItem>
            <NavLink
              to="/tribes"
              href="/tribes"
              activeStyle={{ backgroundColor: "#515e6a" }}
            >
              <AppNavItem>
                <AppNavIcn>{ui.icon.tribe}</AppNavIcn>
                Tribes
              </AppNavItem>
            </NavLink>
          </AppNavListItem>
        </Can>
      </AppNavList>
    </AppNavGroup>
  </Nav>
);
