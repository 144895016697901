import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*
 * Default Items per Page
 */
export const DEFAULT_ITEMS_PER_PAGE = 50;

/*
 * Default New Window Options
 */
export const DEFAULT_NEW_WINDOW_FEATURES = {
  width: 1400,
  height: 1000,
  resizable: 1,
  scrollbar: 1,
  location: 1,
  status: 1,
  menubar: 1,
  toolbar: 1,
  personalbar: 1,
  titlebar: 1,
};

/*
 * Default Format for Phone Numbers
 */
export const DEFAULT_PHONE_NUMBER_FORMAT = "(###) ###-####";

/*
 * Default Format for Social Security Numbers
 */
export const DEFAULT_SOCIAL_SECURITY_FORMAT = "###-##-####";

/*
 * Default Server Date Time Format
 */
export const DEFAULT_SERVER_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

/*
 * Default Server Date Format
 */
export const DEFAULT_SERVER_DATE_FORMAT = "YYYY-MM-DD";

/*
 * Default Query Error Policy
 */
export const DEFAULT_QUERY_ERROR_POLICY = "all";

/*
 * Default Country List
 */
export const DEFAULT_COUNTRIES = {
  USA: "United State of America",
  CA: "Canada",
};

/*
 * Default Province List
 */
export const DEFAULT_PROVINCES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  BC: "British Columbia",
};

/*
 * Default Member Status
 */
export const DEFAULT_MEMBER_STATUS = "enrolled";

/*
 * Default Member Statuses
 */
export const DEFAULT_MEMBER_STATUSES = {
  enrolled: "Enrolled",
  applying: "Applying",
  deceased: "Deceased",
  relinquished: "Relinquished",
  disenrolled: "Disenrolled",
  other_indian: "Other Indian",
};

/*
 * Default Member Statuses with Inactive
 */
export const DEFAULT_MEMBER_STATUSES_WITH_INACTIVE = {
  enrolled: "Enrolled",
  applying: "Applying",
  inactive: "Inactive",
  deceased: "Deceased",
  relinquished: "Relinquished",
  disenrolled: "Disenrolled",
  other_indian: "Other Indian",
};

/*
 * Default Member Statuses
 */
export const DEFAULT_CASE_STATUSES = {
  accepted: "accepted",
  first_24: "first 24",
  phase_1: "phase 1",
  phase_2: "phase 2",
  phase_3: "phase 3",
  phase_4: "phase 4",
  phase_5: "phase 5",
  phase_6: "phase 6",
  wrapped_up: "wrapped up",
  closed: "closed",
  graduated: "graduated",
};

/*
 * Default Colors for Member Status
 */
export const DEFAULT_STATUS_COLORS = {
  enrolled: "success",
  inactive: "primary",
  deceased: "danger",
  relinquished: "danger",
  disenrolled: "warning",
  other_indian: "secondary",
  none: "secondary",
};

/*
 * Default Branches of Service
 */
export const DEFAULT_SERVICE_BRANCH = {
  1: "Army",
  2: "Navy",
  3: "Marines",
  4: "Coast Guard",
  5: "Air Force",
};

export const CASE_STATUS_CHANGE_MESSAGE = "case status updated";

export const REFERRAL_CANCEL_MESSAGE = "referral cancelled";
export const REFERRAL_CLOSE_MESSAGE = "referral closed";
export const REFERRAL_REQUEST_CLOSE_MESSAGE = "referral requested for closing";

export const TRIBE_NAME = "Coeur d'Alene";
export const ENROLLMENT_DIRECTOR = "Charlotte Nilson";
export const PDF_LOADING_MESSAGE = "Loading document...";

// roles
export const ROLES = {
  MEMBERSHIP: "membership",
  REENTRY: "reentry_staff",
  REFERRED: "referred",
  ENROLLMENT: "enrollment_staff",
  FINANCE: "finance",
  ENROLLMENT_DIRECTOR: "enrollment_director",
  CIVIL_ATTORNEY: "civil_attorney_staff",
  SELF: "self",
};

// strings
export const APP_NAME = "SelfDetermine";
export const PASSWORD_RESET_MESSAGE =
  "Check your email to reset your password.";

export const NO_ACTIVE_MAILING_ADDRESS = "No Active Mailing Address";

export const EN = {
  MATTER: {
    OUTSIDE_IDENTIFIER: "Case Number",
    REPRESENTATION_OPTIONS: {
      advocacy: "Advocacy",
      legal_representation: "Civil Case - Legal Representation",
      court_spokesman: "Civil Case - Tribal Court Spokesman",
      completing_forms: "Completing Forms",
      explain_papers: "Explaining Court Papers",
      legal_advice: "Legal Advice",
      legal_drafting: "Legal Drafting",
      legal_information: "Legal Information",
      mediation: "Mediation",
      referral: "Referral/Resources",
      ssi_program: "Tribal SSI Program",
    },
    CATEGORY_OPTIONS: {
      child_support: "Child Support",
      child_custody: "Child Custody",
      collections: "Collections",
      consumer_protection: "Consumer Protection",
      divorce: "Divorce",
      education_schools: "Education/Schools",
      employment: "Employment",
      estate_planning:
        "Estate Planning (Will/Healthcare Directive/Power of Attorney)",
      guardianship: "Guardianship",
      landlord_tenant: "Landlord/Tenant",
      medicaid_medicare: "Medicaid/Medicare",
      name_change: "Name Change",
      probate: "Probate (BIA/Tribal Court)",
      protection_order: "Protection Order",
      restraining_order: "Restraining Order",
      social_security: "Social Security",
      small_claims: "Small Claims",
      tax: "Tax",
    },
  },
};

// User interface color, shadow options
export const ui = {
  color: {
    brand_orange: "#FF5C00",
    brand_blue: "#28629B",
    shade_100: "#FFFFFF",
    shade_200: "#F8FAFC",
    shade_300: "#F1F5F8",
    shade_400: "#DAE1E7",
    shade_500: "#B8C2CC",
    shade_600: "#8795A1",
    shade_700: "#606F7B",
    shade_800: "#3D4852",
    shade_900: "#22292F",
  },
  shadow: {
    low: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);",
  },
  // Icon Set via FontAwesome https://fontawesome.com/icons?d=gallery
  icon: {
    angle_down: <FontAwesomeIcon icon="angle-down" />,
    angle_left: <FontAwesomeIcon icon="angle-left" />,
    angle_right: <FontAwesomeIcon icon="angle-right" />,
    angle_up: <FontAwesomeIcon icon="angle-up" />,
    arrow_down: <FontAwesomeIcon icon="arrow-down" />,
    arrow_left: <FontAwesomeIcon icon="arrow-left" />,
    arrow_right: <FontAwesomeIcon icon="arrow-right" />,
    arrow_up: <FontAwesomeIcon icon="arrow-up" />,
    attach: <FontAwesomeIcon icon="paperclip" />,
    badge: <FontAwesomeIcon icon="id-badge" />,
    camera: <FontAwesomeIcon icon="camera" />,
    chart_bar: <FontAwesomeIcon icon="chart-bar" />,
    chart_diagram: <FontAwesomeIcon icon="project-diagram" />,
    chevron_down: <FontAwesomeIcon icon="chevron-down" />,
    chevron_left: <FontAwesomeIcon icon="chevron-left" />,
    chevron_right: <FontAwesomeIcon icon="chevron-right" />,
    chevron_up: <FontAwesomeIcon icon="chevron-up" />,
    circle_check: <FontAwesomeIcon icon="check-circle" />,
    circle_minus: <FontAwesomeIcon icon="minus-circle" />,
    close: <FontAwesomeIcon icon="times" />,
    cog: <FontAwesomeIcon icon="cog" />,
    copy: <FontAwesomeIcon icon="copy" />,
    dashboard: <FontAwesomeIcon icon="tachometer-alt" />,
    download: <FontAwesomeIcon icon="download" />,
    edit: <FontAwesomeIcon icon="edit" />,
    eye_no: <FontAwesomeIcon icon="eye-slash" />,
    eye: <FontAwesomeIcon icon="eye" />,
    file_contract: <FontAwesomeIcon icon="file-contract" />,
    grid: <FontAwesomeIcon icon="th" />,
    list: <FontAwesomeIcon icon="th-list" />,
    map: <FontAwesomeIcon icon="map-marked-alt" />,
    org: <FontAwesomeIcon icon="users" />,
    people: <FontAwesomeIcon icon="user" />,
    plus: <FontAwesomeIcon icon="plus" />,
    popup: <FontAwesomeIcon icon="external-link-alt" />,
    print: <FontAwesomeIcon icon="print" />,
    question: <FontAwesomeIcon icon="question-circle" />,
    reset: <FontAwesomeIcon icon="undo-alt" />,
    search: <FontAwesomeIcon icon="search" />,
    sort_down: <FontAwesomeIcon icon="sort-down" />,
    sort_up: <FontAwesomeIcon icon="sort-up" />,
    sort: <FontAwesomeIcon icon="sort" />,
    square_check: <FontAwesomeIcon icon="check-square" />,
    square_plus: <FontAwesomeIcon icon="plus-square" />,
    square: <FontAwesomeIcon icon="square" />,
    table: <FontAwesomeIcon icon="table" />,
    trash: <FontAwesomeIcon icon="trash" />,
    time: <FontAwesomeIcon icon="clock" />,
    tribe: <FontAwesomeIcon icon="campground" />,
    user: <FontAwesomeIcon icon="user-circle" />,
  },
};
