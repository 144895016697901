import { gql } from "@selfdetermine/graphql";

export const HISTORY_QUERY = gql`
  query History($id: ID!) {
    viewer {
      person(id: $id) {
        id
        history {
          changes
          createdAt
          event
          user {
            email
          }
        }
      }
    }
  }
`;
