import { gql } from "@selfdetermine/graphql";

export const CREATE_PERSON_ADDRESS_MUTATION = gql`
  mutation CreatePersonAddress($address: ResidenceInput) {
    addResidence(residence: $address) {
      id
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteResidence($person: ID!, $residence: ID!) {
    deleteResidence(person: $person, residence: $residence) {
      id
    }
  }
`;

export const INACTIVATE_ADDRESS = gql`
  mutation InactivateResidence($person: ID!) {
    inactivateResidence(person: $person) {
      id
    }
  }
`;
