import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
import React, { Component } from "react";

import SunBurst from "../../../Charts/Sunburst";
import { DEFAULT_QUERY_ERROR_POLICY } from "../../../Constants";
import { getPersonName } from "../../../Utils";

import { PERSON } from "./graphql";

const addParent = (data, depth) => {
  const parsedData = {};
  parsedData.id = data.id;
  parsedData.name = getPersonName(data);
  parsedData.enrolledBloodQuantum = data.enrolledBloodQuantum;
  parsedData.otherBloodQuantum = data.otherBloodQuantum;
  parsedData.totalBloodQuantum = data.totalBloodQuantum;

  // if this isn't a leaf (depth === 0), add children
  if (depth > 0) {
    parsedData.children = [];

    if (data.father) {
      parsedData.children.push(addParent(data.father, depth - 1));
    } else {
      parsedData.children.push(addParent({ firstName: "unknown" }, depth - 1));
    }

    if (data.mother) {
      parsedData.children.push(addParent(data.mother, depth - 1));
    } else {
      parsedData.children.push(addParent({ firstName: "unknown" }, depth - 1));
    }
  }

  return parsedData;
};

const getTreeDepth = (data) => {
  // no father and no mother
  if (!data.father && !data.mother) {
    return 0;
  }

  const motherDepth = data.mother && getTreeDepth(data.mother);
  const fatherDepth = data.father && getTreeDepth(data.father);

  return 1 + Math.max(motherDepth, fatherDepth);
};

class FanChart extends Component {
  _handleClick = (d) => {
    if (d.data.id) {
      this.props.history.push(`/people/${d.data.id}?tabs=enrollment`);
    }
  };

  render() {
    const { person } = this.props;

    return (
      <Query
        query={PERSON.ANCESTOR_QUERY}
        variables={{ id: person.id }}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, data }) => {
          if (loading) return "Loading...";

          const depth = getTreeDepth(data.viewer.person);
          const parsedData = addParent(data.viewer.person, depth);

          return <SunBurst data={parsedData} handleClick={this._handleClick} />;
        }}
      </Query>
    );
  }
}

FanChart.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

FanChart.defaultProps = {};

export default FanChart;
