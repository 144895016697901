export { ApolloProvider as ClientProvider, useLazyQuery, useMutation, useQuery } from '@apollo/client';
export { useDebounce } from 'use-debounce';
import React from 'react';

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

/**
 * Hook for invoking a callback after a specific key is pressed.
 *
 * Based on the useKeyPress hook from https://usehooks.com/useKeyPress/
 * @param {string} targetKey - Name of key to listen for
 * @param {function} callback - Function to invoke after key is pressed.
 * @param {array} [dependencyArray=[]] - List of dependency variables. Works the same as `React.useEffect` dependencies.
 */

var useKeyPress = function useKeyPress(targetKey, callback) {
  var dependencyArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  // State for keeping track of whether key is pressed
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      keyPressed = _React$useState2[0],
      setKeyPressed = _React$useState2[1]; // If pressed key is our target key then set to true


  var downHandler = function downHandler(_ref) {
    var key = _ref.key;

    if (key === targetKey) {
      setKeyPressed(true);

      if (typeof callback === "function") {
        callback();
      }
    }
  }; // If released key is our target key then set to false


  var upHandler = function upHandler(_ref2) {
    var key = _ref2.key;

    if (key === targetKey) {
      setKeyPressed(false);
    }
  }; // Add event listeners


  React.useEffect(function () {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler); // Remove event listeners on cleanup

    return function () {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, dependencyArray);
  return keyPressed;
};

export { useKeyPress };
