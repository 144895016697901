import PropTypes from "prop-types";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { DEFAULT_SERVER_DATETIME_FORMAT } from "../../../Constants";

const CaseTableRow = ({ reentry, person }) => (
  <Link
    className="listing__item"
    to={{
      pathname: `/people/${person.id}/reentry/${reentry.id}`,
      state: { kind: reentry.kind },
    }}
  >
    <div className="listing__item-data">{reentry.id}</div>
    <div className="listing__item-data">{reentry.description}</div>
    <div className="listing__item-data">
      {reentry.referringEntity && reentry.referringEntity.name}
    </div>
    <div className="listing__item-data">
      {reentry.releasedAt ? (
        <Moment
          date={reentry.releasedAt}
          format="MM/DD/YYYY"
          parse={DEFAULT_SERVER_DATETIME_FORMAT}
        />
      ) : (
        ""
      )}
    </div>
    <div className="listing__item-data">
      {reentry.selfReferral ? "yes" : "no"}
    </div>
    <div className="listing__item-data">{reentry.status}</div>
  </Link>
);

CaseTableRow.propTypes = {
  reentry: PropTypes.shape.isRequired,
  person: PropTypes.shape.isRequired,
};

export default CaseTableRow;
