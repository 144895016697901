import { Query } from "@apollo/client/react/components";
import React from "react";
import { Link } from "react-router-dom";

import { Panel, PanelHeader, PanelTitle } from "../../../components";
import Referrals from "../../../Referrals";
import { checkForErrors } from "../../../Utils";

import Overview from "./Overview";
import CaseNotes from "./CaseNotes";
import CaseStatus from "./CaseStatus";

import { CASE } from "./graphql";

const CaseDetail = ({ person, ...props }) => {
  const personId = props.match.params.person;
  const caseId = props.match.params.id;

  const getQueryVars = () => ({
    parent: personId,
    id: caseId,
  });

  return (
    <Query query={CASE.KIND_QUERY} variables={getQueryVars()}>
      {({ loading, error, data }) => {
        const loadingOrError = checkForErrors(loading, error, data, "overview");
        if (loadingOrError) {
          return loadingOrError;
        }

        const _case = data.viewer.case;

        return (
          <>
            <Panel>
              <PanelHeader>
                <PanelTitle>
                  <Link to={`/people/${personId}/reentry`}>Reentry Cases</Link>
                  {` / #${caseId}`}
                </PanelTitle>
                <div>
                  <a style={{ margin: "0 .5rem" }} href="#case-status">
                    Status
                  </a>
                  <a style={{ margin: "0 .5rem" }} href="#case-notes">
                    Notes
                  </a>
                  <a style={{ margin: "0 .5rem" }} href="#case-referrals">
                    Referrals
                  </a>
                </div>
              </PanelHeader>
              <Overview _case={_case} getQueryVars={getQueryVars} {...props} />
            </Panel>
            <CaseStatus _case={_case} getQueryVars={getQueryVars} {...props} />
            <CaseNotes
              _case={_case}
              getQueryVars={getQueryVars}
              person={person}
              {...props}
            />
            <Referrals _case={_case} getQueryVars={getQueryVars} {...props} />
          </>
        );
      }}
    </Query>
  );
};

export default CaseDetail;
