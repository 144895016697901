import React, { Component } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { Error } from "../components";
import { Loading } from "../Utils";

class ForceDirected extends Component {
  _renderTree() {
    const { id, data, handleClick } = this.props;
    const graph = data;

    const boxHeight = 50;
    const boxWidth = 150;

    // Set the dimensions and margins of the diagram
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const width = 1200 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    d3.select(`#${id}`).selectAll("svg").remove();
    d3.select(`#${id}`).selectAll("g").remove();

    // moves the 'group' element to the top left margin
    const svg = d3
      .select(`#${id}`)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMinYMin")
      .append("g")
      .attr("transform", `translate(${0},${0})`);

    // -creating the force simulation
    const simulation = d3
      .forceSimulation()
      .force(
        "link",
        d3
          .forceLink()
          .id((link) => link.id)
          .distance((link) => link.distance)
      )
      .force("charge", d3.forceManyBody().strength(-120))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // -creating the links
    const outerLink = svg
      .append("g")
      .selectAll("line")
      .data(graph.links)
      .enter()
      .append("line");

    // -creating the nodes
    const outerNode = svg
      .append("g")
      .selectAll("rect")
      .data(graph.nodes)
      .enter()
      .append("rect")
      .attr("width", boxWidth)
      .attr("height", boxHeight)
      .attr("class", (node) => node.sex) // blue or red
      .on("click", handleClick);

    const outerTextElements = svg
      .append("g")
      .selectAll("text")
      .data(graph.nodes)
      .enter()
      .append("text")
      .text((node) => `${node.name}`)
      .attr("dy", "-1em")
      .attr("class", "name")
      .on("click", handleClick);

    const outerTextIds = svg
      .append("g")
      .selectAll("text")
      .data(graph.nodes)
      .enter()
      .append("text")
      .text((node) => `${node.membershipNumber ? node.membershipNumber : ""}`)
      .on("click", handleClick);

    const outerTextRoles = svg
      .append("g")
      .selectAll("text")
      .data(graph.nodes)
      .enter()
      .append("text")
      .text((node) => `${node.role ? node.role : ""}`)
      .attr("dy", "1em")
      .on("click", handleClick);

    function ticked() {
      outerLink
        .attr("x1", (link) => link.source.x)
        .attr("y1", (link) => link.source.y)
        .attr("x2", (link) => link.target.x)
        .attr("y2", (link) => link.target.y);
      outerNode
        .attr("x", (node) => node.x - boxWidth / 2)
        .attr("y", (node) => node.y - boxHeight / 2);
      outerTextElements
        .attr("x", (textElements) => textElements.x)
        .attr("y", (textElements) => textElements.y);
      outerTextIds
        .attr("x", (textIds) => textIds.x)
        .attr("y", (textIds) => textIds.y);
      outerTextRoles
        .attr("x", (textRoles) => textRoles.x)
        .attr("y", (textRoles) => textRoles.y);
    }

    simulation.nodes(graph.nodes).on("tick", ticked);

    simulation.force("link").links(graph.links);
  }

  render() {
    const { id, data, error, loading } = this.props;

    if (data) {
      setTimeout(() => this._renderTree(), 500);
    }

    return (
      <div id={id} className="force-directed">
        {loading && <Loading>Loading chart data...</Loading>}

        {error && (
          <Error>
            <h3>Error:</h3>
            <p>{error}</p>
          </Error>
        )}
      </div>
    );
  }
}

ForceDirected.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ForceDirected;
