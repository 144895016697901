import React from "react";
import PropTypes from 'prop-types';
import { useQuery } from "@apollo/client";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { PageContent, PageColumn } from "../components";
import { ROLES, DEFAULT_QUERY_ERROR_POLICY } from "../Constants";
import { PERSON } from "./Person/graphql";
import { PEOPLE } from "./graphql";
import { Avatar, AddressFormat, PhoneNumber, withUserRole, checkForErrors } from "../Utils";
import background from '../asset/bg_page_header.jpg';

/*-----------------------------------------------------------------------------
 * styled-components & Style Objects
 *------------------------------------------------------------------------------
 *
 * https://styled-components.com/
 *
 * These styled-components setup the elements that appear inside the
 * React-Leaflet Popup component. The person elements are styled to match
 * the feel of the full person profile page. Regular objects follow Reacts
 * normal 'style' prop convention.
 *
 */

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  min-height: 260px;
`;

const PopupContentHeader = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: none;
  min-height: 60px;
  border-radius: 6px;
  color: white;
`;

const PopupContentBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PopupContentFooter = styled.div`
  margin-top: auto;
`;

const PersonName = styled.span`
  margin: 1rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
`;

const PersonKeyValue = styled.span`
  display: flex;
  margin: 0.25rem 0;
`;

const PersonKey = styled.span`
  width: 120px;
`;

const PersonValue = styled.span``;

const PersonProfileLinkWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #f7f7f7;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
    :hover {
      text-decoration: none;
      background: #e4e8ec;
    }
  }
`;

const PersonNotFound = styled.div`
  text-align: center;
  margin-top: 6rem;
`;

const avatarStyles = {
  top: "1rem",
  left: "1rem",
}

const addressStyles = {
  fontStyle: "oblique"
}

const mapStyles = {
  height: "100vh"
}

/*-----------------------------------------------------------------------------
 * Popup Component
 *------------------------------------------------------------------------------
 *
 * Uses the React Leaflet Popup component. The query for a person matching
 * the ID is ran when the React Leaflet Marker component is clicked. If the
 * person is found their profile is shown, otherwise an note is displayed.
 *
 */

function PopupComponent({ personId }) {
  const { loading, error, data } = useQuery(PERSON.MAP_QUERY, {
    variables: { person: `${personId}` },
    skip: !personId,
    errorPolicy: DEFAULT_QUERY_ERROR_POLICY,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Safely get person, assuming data might be null or undefined
  const person = data?.viewer?.person || null;

  return( 
    <Popup>
      {person ? (
        <PopupContent>
          <PopupContentHeader>
            <Avatar id={person.id} sex={person.sex} firstName={person.firstName} lastName={person.lastName} styles={avatarStyles} />
          </PopupContentHeader>

          <PopupContentBody>
              <PersonName>{person.firstName} {person.lastName}</PersonName>

              <PersonKeyValue>
                  <PersonKey>Phone</PersonKey>
                  <PersonValue><a href={`tel:${person.phone1}`}>{person.phone1 && ( <PhoneNumber phone={person.phone1} /> )}</a></PersonValue>
              </PersonKeyValue>

              <PersonKeyValue>
                  <PersonKey>Email</PersonKey>
                  <PersonValue><a href={`mailto:${person.email}`}>{person.email}</a></PersonValue>
              </PersonKeyValue>

              <PersonKeyValue>
                  <PersonKey>Mailing Address</PersonKey>
                  <PersonValue>{person.activeMailingAddress && ( <AddressFormat styles={addressStyles} address={person.activeMailingAddress} /> )}</PersonValue>
              </PersonKeyValue>
          </PopupContentBody>

          <PopupContentFooter>
            <PersonProfileLinkWrapper>
              <Link to={`/people/${person.id}`}>
                Open Profile
              </Link>
            </PersonProfileLinkWrapper>
          </PopupContentFooter>
        </PopupContent>
      ) : (
        <PopupContent>
          <PopupContentBody>
            <PersonNotFound>
              Nobody was found associated with this location.
            </PersonNotFound>
          </PopupContentBody>
        </PopupContent>
      )}
    </Popup>
  )
}

PopupComponent.propTypes = {
  personId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

/*-----------------------------------------------------------------------------
 * Enrollment Map Page
 *------------------------------------------------------------------------------
 *
 * This map displays the specific locations of active mailing addresses by
 * fetching a list of the first 50?? active mailing addresses, and then using
 * the person ID to get the profile of any person found at that location.
 *
 */

function MapViewLayout() {
  const { loading, error, data } = useQuery(PEOPLE.MAP_QUERY, {
    variables: { first: 50 },
    fetchPolicy: "network-only",
    errorPolicy: DEFAULT_QUERY_ERROR_POLICY,
  });

  const loadingOrError = checkForErrors(loading, error, data, "map data");
  if (loadingOrError) {
    return loadingOrError;
  }

  const { residences } = data.viewer;

  return (
    <PageColumn>
      <PageContent>
        <Map style={mapStyles} center={[47.253136, -116.763011]} zoom={10}>
          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>, &copy; <a href='https://carto.com/attribution'>CARTO</a>" />
          {residences.map((residence) => {
            if (residence.latitude && residence.longitude) {
              return (
                <Marker position={[residence.latitude, residence.longitude]} key={residence.personId} >
                  <PopupComponent personId={residence.personId} />
                </Marker>
              );
            }
            return null;
          })}
        </Map>
      </PageContent>
    </PageColumn>
  );
};

export default withUserRole(MapViewLayout, [ROLES.ENROLLMENT], { fallbackTo: '/people' });

