import { Mutation, Query } from "@apollo/client/react/components";
import { gql } from "@selfdetermine/graphql";
import React, { Component } from "react";
import {
  Button,
  CustomInput,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { DEPARTMENTS } from "../../../../Departments/graphql";
import { checkForErrors, MutationSubmit, QuerySelect } from "../../../../Utils";

import { MatterFields } from "./Fields";
import { UPDATE_MATTER_MUTATION } from "./graphql";

export const MATTER_QUERY = gql`
  query Case($parent: ID!, $id: ID!) {
    viewer {
      matter(parent: $parent, id: $id) {
        id
        description
        referringEntity {
          id
          name
        }
        selfReferral
        outsideIdentifier
        representation
        category
        adverseParty {
          id
          name
        }
        regardingParty {
          id
          name
        }
        opposingCounsel {
          id
          name
        }
        primaryCounsel {
          id
          name
        }
      }
    }
  }
`;

class EditMatter extends Component {
  constructor(props) {
    super(props);

    const {
      id,
      description,
      selfReferral,
      outsideIdentifier,
      representation,
      category,
    } = props.matter;
    this.state = {
      kase: {
        id,
        description,
        selfReferral,
        outsideIdentifier,
        representation,
        category,
      },
    };
  }

  _getMutationVars = () => {
    const { kase } = this.state;

    return {
      person: this.props.match.params.person,
      matter: { ...kase },
    };
  };

  _handleChange = (e, n, v) => {
    const { target } = e;
    let name = n;
    let value = v;

    if (!name) {
      name = target.name;
      value = target.type === "checkbox" ? target.checked : target.value;
    }

    this.setState((prevState) => {
      const state = prevState.kase;
      state[name] = value;

      return { need: state };
    });
  };

  _handleSelectChange = (name, select) => {
    this.setState((prevState) => {
      const state = prevState.kase;
      state[name] = select ? select.value : select;

      return { kase: state };
    });
  };

  render() {
    const { person, id } = this.props.match.params;
    if (this.state.submitted) {
      this.props.history.push(`/people/${person}/matters/${id}`);
    }

    return (
      <div
        style={{
          maxWidth: "500px",
          marginLeft: "50px",
          marginTop: "25px",
          marginBottom: "100px",
        }}
      >
        <h4 style={{ color: "rgba(0,0,0,.65)" }}>Edit Matter</h4>
        <SharedFields
          handleChange={this._handleChange}
          handleSelectChange={this._handleSelectChange}
          defaultValue={this.props.matter}
          kase={this.state.kase}
        />
        <MatterFields
          handleChange={this._handleChange}
          handleSelectChange={this._handleSelectChange}
          kase={this.state.kase}
          defaultValue={this.props.matter}
          values={this.state.kase}
          person={this.props.person}
        />
        <Mutation
          mutation={UPDATE_MATTER_MUTATION}
          variables={this._getMutationVars()}
          onCompleted={() => {
            Toasts.success("Case updated");
            this.setState({ submitted: true });
          }}
          onError={(error) => Toasts.danger("Unable to update case", error)}
        >
          {(callMutation, { loading }) => (
            <MutationSubmit
              color="warning"
              label="Save"
              loadingLabel="Saving..."
              onClick={callMutation}
              loading={loading}
            />
          )}
        </Mutation>
        <span style={{ display: "inline-block", width: "1rem" }} />
        <Button
          color="secondary"
          onClick={() => {
            this.props.history.push(`/people/${person}/matters/${id}`);
          }}
        >
          Cancel
        </Button>
      </div>
    );
  }
}

const SharedFields = ({
  handleChange,
  handleSelectChange,
  kase,
  defaultValue,
}) => {
  const department = defaultValue.referringEntity
    ? {
        value: defaultValue.referringEntity.id,
        label: defaultValue.referringEntity.name,
      }
    : null;

  return (
    <>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              style={{ height: "6rem" }}
              required
              type="textarea"
              name="description"
              placeholder="Description"
              value={kase.description}
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <Label for="referringEntity">Referring Entity</Label>
            <QuerySelect
              id="referringEntity"
              value="referringEntity"
              query={DEPARTMENTS.SELECT_QUERY}
              viewer="departments"
              isSearchable
              isClearable
              placeholder="Search Department..."
              defaultValue={department}
              handleChange={handleSelectChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="selfReferral"
              name="selfReferral"
              label="Self Referral?"
              checked={kase.selfReferral}
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

const QueryMatterDetails = (props) => {
  const getQueryVars = () => {
    return {
      parent: props.match.params.person,
      id: props.match.params.id,
    };
  };

  return (
    <Query
      query={MATTER_QUERY}
      variables={getQueryVars()}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        const loadingOrError = checkForErrors(loading, error, data, "overview");
        if (loadingOrError) {
          return loadingOrError;
        }

        const { matter } = data.viewer;

        return (
          <EditMatter matter={matter} {...props} getQueryVars={getQueryVars} />
        );
      }}
    </Query>
  );
};

export default QueryMatterDetails;
