import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { common, layout, p, table } from "../../styles";
import { TRIBE_NAME } from "../../../Constants";

export const ViewTable = (rows) => {
  return (
    <View style={table.table}>
      {rows.map((g, index) => {
        let rowStyle = table.row;
        if (index % 2 === 0) {
          rowStyle = [table.row, table.zebra];
        }
        return (
          <View key={g.label} style={rowStyle}>
            <Text style={[layout.colMd6, common.textLeft]}>
              <Text style={table.cell}>{g.label}:</Text>
            </Text>
            <Text style={[layout.colMd6, common.textRight]}>
              <Text style={table.cell}>{g.value}</Text>
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export const ViewAddress = (address) => {
  return (
    <View style={common.address}>
      {address.street1 ? <Text>{address.street1}</Text> : null}
      {address.street2 ? <Text>{address.street2}</Text> : null}
      <Text>{`${address.city}, ${address.state} ${address.zipCode}`}</Text>
    </View>
  );
};

export const ViewSeal = () => {
  return (
    <View style={[common.small, { position: "fixed", bottom: -6 }]}>
      <View style={[layout.row, common.textCenter, p[0]]}>
        <Text>The</Text>
      </View>
      <View style={[layout.row, common.textCenter, p[0]]}>
        <Text>Great</Text>
      </View>
      <View style={[layout.row, common.textCenter, p[0]]}>
        <Text>Seal of the</Text>
      </View>
      <View style={[layout.row, common.textCenter, p[0]]}>
        <Text>{TRIBE_NAME}</Text>
      </View>
      <View style={[layout.row, common.textCenter, p[0]]}>
        <Text>Tribe</Text>
      </View>
    </View>
  );
};
