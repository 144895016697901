import PropTypes from "prop-types";
import React from "react";

import Actions from "./Actions";
import Relatives from "./Relatives";
import Relationships from "./Relationships";

const FamilyOverview = (props) => {
  return (
    <>
      <Actions {...props} />
      <Relatives {...props} />
      <Relationships {...props} />
    </>
  );
};

FamilyOverview.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

FamilyOverview.defaultProps = {};

export default FamilyOverview;
