import { Query } from "@apollo/client/react/components";
import React, { Component } from "react";

import AccountDropdown from "../Account/AccountDropdown";
import {
  EmptyListing,
  Page,
  PageHeader,
  PageTitle,
  PageContent,
  ActionsCtrls,
  VerticalDivider,
  PageHeaderSection,
} from "../components";
import { ROLES } from "../Constants";
import { DEPARTMENTS } from "./graphql";
import { checkForErrors, withUserRole } from "../Utils";
import NewDepartment from "./NewDepartment";
import DepartmentTableList from "./DepartmentTableList";

class DepartmentListing extends Component {
  render() {
    const departmentType = "departments";
    const query = DEPARTMENTS.QUERY;
    return (
      <Page>
        <PageHeader>
          <PageHeaderSection>
            <PageTitle />
          </PageHeaderSection>
          <PageHeaderSection>
            <PageTitle>Departments</PageTitle>
          </PageHeaderSection>
          <PageHeaderSection>
            <div className="action-group">
              <ActionsCtrls>
                <NewDepartment
                  departmentType={departmentType}
                  className="user-button"
                  person={{ member_status: "applying" }}
                />{" "}
                <VerticalDivider className="d-none" />
              </ActionsCtrls>
            </div>
            <AccountDropdown />
          </PageHeaderSection>
        </PageHeader>

        <PageContent>
          <Query query={query} variables={null}>
            {({ loading, error, data }) => {
              const loadingOrError = checkForErrors(
                loading,
                error,
                data,
                `${departmentType} list`
              );
              if (loadingOrError) {
                return loadingOrError;
              }
              const departments = data.viewer[departmentType];

              if (departments.length < 1)
                return (
                  <EmptyListing {...this.props}>
                    No {departmentType}
                  </EmptyListing>
                );

              return (
                <DepartmentTableList
                  Departments={departments}
                  {...this.props}
                  departmentType={departmentType}
                  handleSort={this._handleSort}
                />
              );
            }}
          </Query>
        </PageContent>
      </Page>
    );
  }
}

const wrappedWithUserRole = withUserRole(DepartmentListing, [
  ROLES.REENTRY,
  ROLES.CIVIL_ATTORNEY,
]);
export default wrappedWithUserRole;
