import { Query } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, Label, CardBody } from "reactstrap";

import { Panel, PanelHeader, PanelTitle } from "../../../components";
import {
  DEFAULT_NEW_WINDOW_FEATURES,
  DEFAULT_QUERY_ERROR_POLICY,
} from "../../../Constants";
import {
  checkForErrors,
  convertObjToKeyValue,
  getPersonName,
} from "../../../Utils";

import { PERSON } from "./graphql";

const _handleClick = (e, person) => {
  e.preventDefault();
  window.open(
    `/people/${person.id}/family/overview`,
    "_blank",
    convertObjToKeyValue(DEFAULT_NEW_WINDOW_FEATURES)
  );
};

class Relatives extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    return (
      <Query
        query={PERSON.FAMILY_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "family info"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          const grandChildren = person.children
            ? person.children
                .map((currentChild) => currentChild.children)
                .flat()
            : null;

          return (
            <Panel id="family-relationship-fields">
              <PanelHeader>
                <PanelTitle>Relatives</PanelTitle>
              </PanelHeader>
              <PanelHeader>
                <PanelTitle>Grandparents</PanelTitle>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <PersonLink
                      person={person.mother ? person.mother.mother : null}
                      label="Yaya"
                    />
                  </Col>
                  <Col sm={4}>
                    <PersonLink
                      person={person.mother ? person.mother.father : null}
                      label="Mother's Father"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <PersonLink
                      person={person.father ? person.father.mother : null}
                      label="Father's Mother"
                    />
                  </Col>
                  <Col sm={4}>
                    <PersonLink
                      person={person.father ? person.father.father : null}
                      label="Father's Father"
                    />
                  </Col>
                </Row>
              </CardBody>

              <PanelHeader>
                <PanelTitle>Parents</PanelTitle>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <PersonLink person={person.father} label="Father" />
                  </Col>
                  <Col sm={4}>
                    <PersonLink person={person.mother} label="Mother" />
                  </Col>
                </Row>
              </CardBody>

              <PanelHeader>
                <PanelTitle>Siblings</PanelTitle>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <DisplayPeople
                      people={person.full_siblings}
                      label="Siblings"
                    />
                  </Col>
                  <Col sm={4}>
                    <DisplayPeople
                      people={person.father_only_siblings}
                      label="Father-Only Siblings"
                    />
                  </Col>
                  <Col sm={4}>
                    <DisplayPeople
                      people={person.mother_only_siblings}
                      label="Mother-Only Siblings"
                    />
                  </Col>
                </Row>
              </CardBody>

              <PanelHeader>
                <PanelTitle>Children</PanelTitle>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <PersonLink person={person.spouse} label="Co-parent" />
                  </Col>
                  <Col sm={4}>
                    <DisplayPeople people={person.children} label="Children" />
                  </Col>
                </Row>
              </CardBody>

              <PanelHeader>
                <PanelTitle>
                  Grandchildren
                  <span style={{ color: "#444444", fontWeight: 400 }}>
                    {`(${grandChildren ? grandChildren.length : 0})`}
                  </span>
                </PanelTitle>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <DisplayPeople people={grandChildren} label="" />
                  </Col>
                </Row>
              </CardBody>

              <PanelHeader>
                <PanelTitle>
                  Cousins
                  <span style={{ color: "#444444", fontWeight: 400 }}>
                    {`(${
                      person.firstCousins ? person.firstCousins.length : 0
                    })`}
                  </span>
                </PanelTitle>
              </PanelHeader>
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <DisplayPeople people={person.firstCousins} label="" />
                  </Col>
                </Row>
              </CardBody>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

const PersonLink = ({ person, label }) => (
  <>
    <Label>{label}</Label>
    {person ? (
      <strong>
        <a href={`/people/${person.id}/family/overview`}>
          {getPersonName(person)}
        </a>{" "}
        <FontAwesomeIcon
          className="clickable"
          icon="external-link-alt"
          onClick={(e) => _handleClick(e, person)}
        />
      </strong>
    ) : (
      <strong>Not Enrolled</strong>
    )}
  </>
);

const DisplayPeople = ({ people, label }) => (
  <>
    <Label>{label}</Label>
    {!!people &&
      people.map(
        (person) =>
          person && (
            <div key={person.id}>
              <strong key={person.id}>
                <a href={`/people/${person.id}/family/overview`}>
                  {getPersonName(person)}
                </a>{" "}
                <FontAwesomeIcon
                  className="clickable"
                  icon="external-link-alt"
                  onClick={(e) => _handleClick(e, person)}
                />
              </strong>
            </div>
          )
      )}
  </>
);

Relatives.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

Relatives.defaultProps = {};

export default Relatives;
