import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

const COLOR = "#6b486b";

const CustomizedLabel = ({ x, y, value, viewBox }) => (
  <text
    x={x + viewBox.width / 2}
    y={y - 4}
    dy={0}
    fontSize="16"
    fontFamily="sans-serif"
    textAnchor="middle"
  >
    {value}
  </text>
);

const CustomBarChart = ({ data, total }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      <ResponsiveContainer aspect={1.2}>
        <BarChart data={data}>
          <XAxis dataKey="label" />
          <YAxis allowDecimals={false} />
          <Bar
            dataKey="value"
            label={<CustomizedLabel />}
            isAnimationActive={false}
          >
            {data.map((entry) => (
              <Cell cursor="pointer" fill={COLOR} key={`cell-${entry.id}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <h5 className="center-text">{`Total: ${total}`}</h5>
    </>
  );
};

export default CustomBarChart;
