import { gql } from "@selfdetermine/graphql";
import {
  IdFirstLastName,
  Required,
  ContactInformation,
  AddressInformation,
  LifeDetails,
  MembershipInformation,
  PersonIdentification,
  DelicateInformation,
  PhysicalAttributes,
  ServiceInformation,
  BloodQuanta,
  PdfInfo,
} from "./graphql-fragments";

// Update mutation
export const UPDATE_PERSON_MUTATION = gql`
  mutation UpdatePerson($person: UpdatePersonInput) {
    updatePerson(person: $person) {
      id
    }
  }
`;

// Person
export const PERSON = {
  ADDRESSES_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...AddressInformation
        }
      }
    }
    ${AddressInformation}
  `,
  BLOOD_QUANTA_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...BloodQuanta
        }
      }
    }
    ${BloodQuanta}
  `,
  GENERAL_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...PersonIdentification
          ...DelicateInformation
          ...LifeDetails
          ...PhysicalAttributes
          ...ServiceInformation
        }
      }
    }
    ${PersonIdentification}
    ${DelicateInformation}
    ${LifeDetails}
    ${PhysicalAttributes}
    ${ServiceInformation}
  `,
  CONTACT_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...ContactInformation
        }
      }
    }
    ${ContactInformation}
  `,
  QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          ...IdFirstLastName
          ...RequiredParts
          email
          phone1
          phone2
          mobile
          member_status: memberStatus
          membership_number: membershipNumber
          age
          enrolledBloodQuantum
          otherBloodQuantum
          totalBloodQuantum
          activeMailingAddress {
            street1
            street2
            city
            state
            zipCode
          }
          residences {
            active
            kind
            street1
            street2
            city
            state
            zipCode
          }
        }
      }
    }
    ${IdFirstLastName}
    ${Required}
  `,
  MEDICAL_RECORD_NUMBER_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          medicalRecordNumber
        }
      }
    }
  `,
  MEMBERSHIP_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...MembershipInformation
        }
      }
    }
    ${MembershipInformation}
  `,
  CERTIFICATION_OF_INDIAN_BLOOD_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          ...PdfInfo
        }
      }
    }
    ${PdfInfo}
  `,
  ENROLLMENT_VERIFICATION_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          id
          ...PersonIdentification
          ...PhysicalAttributes
          ...LifeDetails
          ...MembershipInformation
          onReservation
          residences {
            active
            kind
            street1
            street2
            city
            state
            zipCode
          }
        }
      }
    }
    ${PersonIdentification}
    ${PhysicalAttributes}
    ${LifeDetails}
    ${MembershipInformation}
  `,
  LETTER_OF_DESCENDANCY_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          ...PdfInfo
        }
      }
    }
    ${PdfInfo}
  `,
  NOTICE_OF_ACCEPTANCE_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          ...PdfInfo
        }
      }
    }
    ${PdfInfo}
  `,
  MAP_QUERY: gql`
    query Person($person: ID!) {
      viewer {
        person(id: $person) {
          activeMailingAddress {
            street1
            street2
            city
            state
            zipCode
          }
          id
          firstName
          lastName
          sex
          phone1
          email
          residences {
            kind
            active
            street1
            city
            state
            zipCode
          }
        }
      }
    }
  `,
};
