import { Query } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { Panel, PanelHeader, PanelTitle } from "../../../components";
import {
  DEFAULT_NEW_WINDOW_FEATURES,
  DEFAULT_QUERY_ERROR_POLICY,
  ui,
} from "../../../Constants";
import {
  checkForErrors,
  convertObjToKeyValue,
  getPersonName,
} from "../../../Utils";

import { PERSON } from "./graphql";

class Relationships extends Component {
  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  _handleClick = (e, person) => {
    e.preventDefault();
    window.open(
      `/people/${person.id}/enrollment`,
      "_blank",
      convertObjToKeyValue(DEFAULT_NEW_WINDOW_FEATURES)
    );
  };

  render() {
    return (
      <Query
        query={PERSON.PERSONAL_RELATIONSHIPS_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "relationship info"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;

          return (
            <Panel id="relationships">
              <PanelHeader>
                <PanelTitle>Relationships</PanelTitle>
              </PanelHeader>
              <table className="table is-striped is-hoverable">
                <tbody>
                  {person.personal_relationships &&
                    person.personal_relationships.map((relationship) => (
                      <tr key={relationship?.id}>
                        <td colSpan="4">{relationship?.personRole.name}</td>
                        <td>{ui.icon.arrow_right}</td>
                        <td colSpan="4">{relationship?.relatedRole.name}</td>
                        <td>
                          <a
                            href={`/people/${relationship?.related.id}/enrollment`}
                          >
                            {relationship &&
                              getPersonName(relationship?.related)}
                          </a>{" "}
                          <FontAwesomeIcon
                            className="clickable"
                            icon="external-link-alt"
                            onClick={(e) =>
                              this._handleClick(e, relationship?.related)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Panel>
          );
        }}
      </Query>
    );
  }
}

Relationships.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    mobile: PropTypes.string,
    member_status: PropTypes.string,
    membership_number: PropTypes.string,
    age: PropTypes.number,
    enrolledBloodQuantum: PropTypes.string,
    otherBloodQuantum: PropTypes.string,
    totalBloodQuantum: PropTypes.string,
    activeMailingAddress: PropTypes.shape({
      street1: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    residences: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        kind: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }).isRequired,
};

Relationships.defaultProps = {};

export default Relationships;
