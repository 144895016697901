import React, { Component } from "react";
import {
  PDFViewer,
  BlobProvider,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image as RenderImage,
} from "@react-pdf/renderer";
import { Query } from "@apollo/client/react/components";
import queryString from "query-string";
import EnrollmentHeader from "./EnrollmentHeader";
import { PageReports } from "../../components";
import { ViewTable, ViewAddress } from "./ViewPartials";
import { common, layout } from "../../styles";
import { PERSON } from "../graphql";
import {
  DEFAULT_QUERY_ERROR_POLICY,
  PDF_LOADING_MESSAGE,
} from "../../../Constants";
import {
  checkForErrors,
  imageToBase64,
  capitalize,
  formatDate,
  formatPersonExportFilename,
} from "../../../Utils";

// Create styles
const styles = StyleSheet.create({
  portrait: {
    width: 200,
    height: 200,
    padding: 40,
  },
});

// Create Document Component
export const EnrollmentVerificationDocument = ({ person, portrait, note }) => {
  const general = [
    { label: "First Name", value: person.firstName },
    { label: "Middle Name", value: person.middleName },
    { label: "Last Name", value: person.lastName },
    { label: "Suffix", value: person.suffix },
    { label: "Gender", value: person.sex ? capitalize(person.sex) : null },
    {
      label: "Date of Birth",
      value: person.bornAt ? formatDate(person.bornAt) : null,
    },
    { label: "Place of Birth", value: person.birthplace },
    {
      label: "Head of Household",
      value: person.headOfHousehold ? "Yes" : "No",
    },
    {
      label: "Marital Status",
      value: person.maritalStatus ? capitalize(person.maritalStatus) : null,
    },
    { label: "Height", value: person.height },
    { label: "Weight", value: person.weight },
    {
      label: "Eyes",
      value: person.eyeColor ? capitalize(person.eyeColor) : null,
    },
    {
      label: "Hair",
      value: person.hairColor ? capitalize(person.hairColor) : null,
    },
  ];

  const enrollment = [
    {
      label: "Status",
      value: person.memberStatus ? capitalize(person.memberStatus) : null,
    },
    { label: "Enrollment ID", value: person.membershipNumber },
    {
      label: "Date of Enrollment",
      value: person.enrolledAt ? formatDate(person.enrolledAt) : null,
    },
    {
      label: "Date of Application",
      value: person.appliedAt ? formatDate(person.appliedAt) : null,
    },
    { label: "Resolution Number", value: person.resolutionNumber },
    { label: "Residency", value: person.onReservation },
    { label: "Note", value: note },
  ];

  const activeMailing = person.residences
    .filter((address) => address.kind === "mailing")
    .filter((address) => address.active === true);

  return (
    <Document title="Enrollment Verification">
      <Page size="Letter" style={layout.page}>
        <EnrollmentHeader />
        <View style={layout.container}>
          <Text style={common.h1}>ENROLLMENT VERIFICATION</Text>
          <View style={layout.row}>
            <View style={layout.colMd6}>
              <Text style={common.h2}>Member Details</Text>
              <Text style={common.h3}>General Information</Text>
              {ViewTable(general)}
              <Text style={common.h3}>Enrollment Information</Text>
              {ViewTable(enrollment)}
              <Text style={common.h3}>Mailing Address</Text>
              {activeMailing.length ? ViewAddress(activeMailing[0]) : null}
            </View>
            <View style={layout.colMd6}>
              <RenderImage src={portrait} style={styles.portrait} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

class EnrollmentVerification extends Component {
  constructor(props) {
    super(props);
    const search = queryString.parse(props.location.search);

    this.state = {
      note: search.note ? search.note : "",
      portrait: null,
    };
  }

  componentDidMount() {
    const { person } = this._getQueryVariables();
    const url = `${
      process.env.REACT_APP_IMAGE_URL
    }/${person}.jpg?v=${Date.now()}`;
    imageToBase64(url, (data) => this.setState({ portrait: data }));
  }

  _getQueryVariables = () => {
    const { match } = this.props;
    let person = null;

    if (match) {
      person = match.params.person;
    }

    return { person };
  };

  render() {
    const { type } = this.props;
    const { portrait, note } = this.state;

    if (!portrait) {
      return null;
    }

    return (
      <Query
        query={PERSON.ENROLLMENT_VERIFICATION_QUERY}
        variables={this._getQueryVariables()}
        errorPolicy={DEFAULT_QUERY_ERROR_POLICY}
      >
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(
            loading,
            error,
            data,
            "enrollment-verification"
          );
          if (loadingOrError) {
            return loadingOrError;
          }

          const { person } = data.viewer;
          const renderDocument = (
            <EnrollmentVerificationDocument
              person={person}
              portrait={portrait}
              note={note}
            />
          );

          if (type === "viewer") {
            return (
              <PageReports>
                <PDFViewer
                  style={{
                    width: "100%",
                    height: "100%",
                    border: 0,
                    maxHeight: "100%",
                    position: "absolute",
                  }}
                >
                  {renderDocument}
                </PDFViewer>
              </PageReports>
            );
          }
          return (
            <BlobProvider document={renderDocument}>
              {({ url, loading: blobLoading }) =>
                blobLoading ? (
                  PDF_LOADING_MESSAGE
                ) : (
                  <a
                    className="button"
                    href={url}
                    download={formatPersonExportFilename(person, "EV")}
                  >
                    Enrollment Verification
                  </a>
                )
              }
            </BlobProvider>
          );
        }}
      </Query>
    );
  }
}

export default EnrollmentVerification;
