import { Query, Mutation } from "@apollo/client/react/components";
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Toasts } from "@selfdetermine/react-toasts";
import { Select } from "@selfdetermine/react-elements";
import { ActionButton } from "../components";
import { ui } from "../Constants";
import { DEPARTMENTS } from "../Departments/graphql";
import { MutationSubmit, RequiredField } from "../Utils";

import { CREATE_REFERRAL_MUTATION } from "./graphql";

class NewReferral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      need: {},
      modal: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((currentState) => ({
      need: { ...currentState.need, [name]: value },
    }));
  };

  _toggleModal = () => {
    this.setState((currentState) => ({ modal: !currentState.modal }));
  };

  _getQueryVariables = () => {
    const { need } = this.state;
    const { caseId } = this.props;

    return {
      case: caseId,
      referred: need.referred,
      referring: process.env.REACT_APP_REENTRY_USER_ID,
      description: need.description,
    };
  };

  render() {
    const {
      children,
      right,
      className,
      refetchQuery,
      refetchVars,
    } = this.props;
    const { need, modal } = this.state;

    return (
      <Query query={DEPARTMENTS.SELECT_QUERY}>
        {({ data, error, loading }) => {
          if (error || loading) return null;
          return (
            <>
              <ActionButton
                onClick={this._toggleModal}
                className={`${right ? "float-right" : ""}`}
                size="sm"
              >
                {children || <>{ui.icon.plus} New</>}
              </ActionButton>
              <Modal
                isOpen={modal}
                toggle={this._toggleModal}
                className={className}
              >
                <ModalHeader toggle={this._toggleModal}>
                  Add Referral
                </ModalHeader>

                <ModalBody>
                  <Row form>
                    <Col sm={12}>
                      <FormGroup>
                        <Label for="description">
                          Need <RequiredField />
                        </Label>
                        <Input
                          required
                          type="textarea"
                          name="description"
                          id="description"
                          placeholder="Description"
                          defaultValue={need.need}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col sm={12}>
                      <FormGroup>
                        <Select
                          label={
                            <>
                              Referred <RequiredField />
                            </>
                          }
                          name="referred"
                          onChange={(e) => this.handleChange(e)}
                          isOptionDisabled={(value) => {
                            if (value.default) return false;
                            if (!value.referrable) {
                              return true;
                            }

                            return false;
                          }}
                          options={[
                            {
                              value: "",
                              label: "please select",
                              default: true,
                            },
                            ...data.viewer.departments.map(
                              ({ label, email, ...rest }) => {
                                if (rest.referrable) {
                                  return {
                                    ...rest,
                                    label: `${label} (${email})`,
                                  };
                                }
                                return { label, ...rest };
                              }
                            ),
                          ]}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Mutation
                    mutation={CREATE_REFERRAL_MUTATION}
                    variables={this._getQueryVariables()}
                    onCompleted={() => {
                      Toasts.success("Added new referral");
                      this._toggleModal();
                    }}
                    onError={(createReferralError) =>
                      Toasts.danger(
                        "Unable to add new referral",
                        createReferralError
                      )
                    }
                    refetchQueries={[
                      {
                        query: refetchQuery,
                        variables: refetchVars(),
                      },
                    ]}
                  >
                    {(callMutation, createReferralMeta) => (
                      <MutationSubmit
                        color="warning"
                        label="Add Referral"
                        loadingLabel="Adding..."
                        onClick={callMutation}
                        loading={createReferralMeta.loading}
                      />
                    )}
                  </Mutation>
                  <Button color="secondary" onClick={this._toggleModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </>
          );
        }}
      </Query>
    );
  }
}

NewReferral.defaultProps = {};

export default NewReferral;
