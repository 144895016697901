import React, { Component } from "react";
import { Col } from "reactstrap";
import { Query } from "@apollo/client/react/components";
import {
  Page,
  PageHeader,
  PageTitle,
  PageFilter,
  PageContent,
  ActionsCtrls,
  SearchForm,
  SearchInput,
  EmptyListing,
  PageHeaderSection,
  DisplayTable,
  DisplayTableHead,
  DisplayTableBody,
  DisplayTableRow,
  DisplayTableCell,
} from "../components";
import NewTribe from "./NewTribe";
import AccountDropdown from "../Account/AccountDropdown";
import { ROLES } from "../Constants";
import { Can, checkForErrors } from "../Utils";

import { TRIBES } from "./graphql";

class Tribes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
  }

  _handleSearch = (e) => this.setState({ search: e.target.value });

  render() {
    const { search } = this.state;

    return (
      <Query query={TRIBES.QUERY} variables={{ search }}>
        {({ loading, error, data }) => {
          const loadingOrError = checkForErrors(loading, error, data, "tribes");
          if (loadingOrError) {
            return loadingOrError;
          }

          const { tribes } = data.viewer;

          return (
            <Page>
              <PageHeader>
                <PageHeaderSection />
                <PageHeaderSection>
                  <PageTitle>Tribes</PageTitle>
                </PageHeaderSection>
                <PageHeaderSection>
                  <div>
                    <ActionsCtrls>
                      <Can grantRoles={[ROLES.ENROLLMENT_DIRECTOR]}>
                        <NewTribe
                          className="user-button"
                          person={{ member_status: "applying" }}
                        />{" "}
                      </Can>
                    </ActionsCtrls>
                  </div>
                  <AccountDropdown />
                </PageHeaderSection>
              </PageHeader>

              <PageFilter>
                <SearchForm>
                  <div className="form-row">
                    <Col>
                      <SearchInput
                        type="text"
                        name="search"
                        id="searchTribes"
                        placeholder="Search Tribes..."
                        onChange={this._handleSearch}
                        bsSize="sm"
                      />
                    </Col>
                  </div>
                </SearchForm>
              </PageFilter>

              {tribes.length < 1 ? (
                <EmptyListing>No Tribes</EmptyListing>
              ) : (
                <PageContent>
                  <DisplayTable>
                    <DisplayTableHead>
                      <DisplayTableRow>
                        <DisplayTableCell>ID</DisplayTableCell>
                        <DisplayTableCell>Name</DisplayTableCell>
                      </DisplayTableRow>
                    </DisplayTableHead>
                    <DisplayTableBody>
                      {tribes.map((tribe) => (
                        <DisplayTableRow key={tribe.id}>
                          <DisplayTableCell width="2rem">
                            <div>{tribe.id}</div>
                          </DisplayTableCell>
                          <DisplayTableCell>{tribe.name}</DisplayTableCell>
                        </DisplayTableRow>
                      ))}
                    </DisplayTableBody>
                  </DisplayTable>
                </PageContent>
              )}
            </Page>
          );
        }}
      </Query>
    );
  }
}

export default Tribes;
