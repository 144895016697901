import PropTypes from "prop-types";
import React from "react";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  ButtonGroup,
  Button,
} from "reactstrap";
import { CSVDownload } from "react-csv";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@selfdetermine/graphql";

import {
  PageHeader,
  PageTitle,
  PageHeaderSection,
  UserDropdownToggle,
  ButtonIcn,
} from "../components";
import New from "./New";
import { Can } from "../Utils";
import { ROLES, ui } from "../Constants";
import AccountDropdown from "../Account/AccountDropdown";

const REENTRY_CASES_CSV_QUERY = gql`
  query CSVReentryCases {
    viewer {
      reentryCases {
        person {
          firstName
          lastName
          bornAt
          sex
        }
        openedOn
        closedAt
        currentMatPatient
        needDetox
        drugAlcoholRelated
        mentalHealth
        violent
      }
    }
  }
`;

/**
 * Formats the response from the REENTRY_CASES_CSV_QUERY for use in a csv download.
 *
 * @param {object} data - Response data
 * @returns {object} csv formatted response
 */
const formatReentryDataForCSV = (data) =>
  data.viewer.reentryCases.map(({ person, ...reentryCase }) => ({
    ...person,
    ...reentryCase,
  }));

/**
 * Renders a button that when clicked, performs the specified query and then forces the browser
 * to download the results in csv format.
 *
 * @param {object} query - GraphQL query
 * @param {function} [formatData] Function that formats the results of the query for CSV consumption.
 */
const CsvDownloadButton = ({
  disabled: disabledProp,
  query,
  formatData = () => {},
  ...props
}) => {
  const [downloading, setDownloading] = React.useState(false);
  const [executeQuery, { data, loading }] = useLazyQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setDownloading(true);
    },
  });

  // We don't want the CSVDownload component to mount more than once, so to ensure this, we'll
  // immediately unmount it after it has mounted.
  React.useEffect(() => {
    if (downloading) {
      setDownloading(false);
    }
  }, [downloading]);

  return (
    <>
      {downloading && !loading && !!data && (
        <CSVDownload data={formatData(data)} target="_self" />
      )}
      <button
        type="button"
        {...props}
        onClick={() => executeQuery()}
        disabled={disabledProp || loading || downloading}
      />
    </>
  );
};

CsvDownloadButton.propTypes = {
  disabled: PropTypes.bool,
  formatData: PropTypes.func,
  query: PropTypes.shape({
    viewer: PropTypes.shape({
      reentryCases: PropTypes.arrayOf(
        PropTypes.shape({
          person: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            bornAt: PropTypes.string,
            sex: PropTypes.string,
          }),
          openedOn: PropTypes.string,
          closedAt: PropTypes.string,
          currentMatPatient: PropTypes.bool,
          needDetox: PropTypes.bool,
          drugAlcoholRelated: PropTypes.bool,
          mentalHealth: PropTypes.bool,
          violent: PropTypes.bool,
        })
      ),
    }),
  }),
};

const HeaderBar = ({ handleListType, streamFetch, exportAgeBreakdown }) => (
  <PageHeader>
    <PageHeaderSection>
      <Can grantRoles={[ROLES.ENROLLMENT]}>
        <ButtonGroup size="sm">
          <Button
            as="a"
            href="/people?view=table"
            onClick={(e) => handleListType(e, "table")}
          >
            <ButtonIcn>{ui.icon.table}</ButtonIcn>
            Table
          </Button>
          <Button
            as="a"
            href="/people?view=map"
            onClick={(e) => handleListType(e, "map")}
          >
            <ButtonIcn>{ui.icon.map}</ButtonIcn>
            Map
          </Button>
        </ButtonGroup>
      </Can>
    </PageHeaderSection>
    <PageHeaderSection>
      <PageTitle>People</PageTitle>
    </PageHeaderSection>
    <PageHeaderSection>
      <div className="action-group">
        <Can grantRoles={[ROLES.ENROLLMENT, ROLES.CIVIL_ATTORNEY]}>
          <New className="user-button" />
        </Can>
        <Can grantRoles={[ROLES.ENROLLMENT, ROLES.REENTRY]}>
          <UncontrolledButtonDropdown>
            <UserDropdownToggle size="sm" caret>
              <ButtonIcn icon="file-download" />
              <span>Export</span>
            </UserDropdownToggle>
            <DropdownMenu>
              <Can grantRoles={[ROLES.ENROLLMENT]}>
                <DropdownItem onClick={streamFetch}>
                  Export List to CSV
                </DropdownItem>
                <DropdownItem onClick={exportAgeBreakdown}>
                  Age Breakdown
                </DropdownItem>
              </Can>
              <Can grantRoles={[ROLES.REENTRY]}>
                <CsvDownloadButton
                  className="dropdown-item"
                  query={REENTRY_CASES_CSV_QUERY}
                  formatData={formatReentryDataForCSV}
                >
                  Export Reentry Cases to CSV
                </CsvDownloadButton>
              </Can>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Can>
        <AccountDropdown />
      </div>
    </PageHeaderSection>
  </PageHeader>
);

HeaderBar.propTypes = {
  exportAgeBreakdown: PropTypes.any,
  handleListType: PropTypes.func,
  streamFetch: PropTypes.any,
};

export default HeaderBar;
