import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql } from "@selfdetermine/graphql";
import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import { ConfirmationModal, TabPanel, PanelNavbar } from "../components";
import {
  REFERRAL_CANCEL_MESSAGE,
  REFERRAL_CLOSE_MESSAGE,
  REFERRAL_REQUEST_CLOSE_MESSAGE,
  ROLES,
} from "../Constants";
import { Can, checkForErrors } from "../Utils";

import EditReferralModalForm from "./EditReferralModalForm";

import { REFERRALS_QUERY } from "./graphql";

const REFERRAL_QUERY = gql`
  query Referral($parent: ID!, $id: ID!) {
    viewer {
      referral(parent: $parent, id: $id) {
        id
        description
        status
        referred {
          id
          name
        }
        referring {
          id
          name
        }
        createdBy {
          id
          email
        }
        permittedStates
        case {
          kind
        }
      }
    }
  }
`;

const CLOSE_REFERRAL = gql`
  mutation closeReferral($case: ID!, $referral: ID!) {
    closeReferral(case: $case, referral: $referral) {
      id
    }
  }
`;

const CANCEL_REFERRAL = gql`
  mutation cancelReferral($case: ID!, $referral: ID!) {
    cancelReferral(case: $case, referral: $referral) {
      id
    }
  }
`;

const REQUEST_CLOSE_REFERRAL = gql`
  mutation requestCloseReferral($case: ID!, $referral: ID!) {
    requestCloseReferral(case: $case, referral: $referral) {
      id
    }
  }
`;

const CaseButtons = (props) => {
  const { match, referral } = props;
  const { id, rid } = match.params;
  const { permittedStates } = referral;

  return (
    <div>
      {permittedStates.indexOf("close_requested") !== -1 && (
        <ConfirmationModal
          id="accept"
          title="Request Close Referral"
          message="Are you sure you want to close the referral?"
          buttonText="Request Close Referral"
          mutation={REQUEST_CLOSE_REFERRAL}
          mutationVars={{
            case: id,
            referral: rid,
          }}
          successMessage={REFERRAL_REQUEST_CLOSE_MESSAGE}
          refetchQuery={REFERRAL_QUERY}
          refetchVars={{
            parent: match.params.id,
            id: match.params.rid,
          }}
          size="lg"
        />
      )}
      {permittedStates.indexOf("closed") !== -1 && (
        <ConfirmationModal
          id="accept"
          title="Close Referral"
          message="Are you sure you want to close the referral?"
          buttonText="Close Referral"
          mutation={CLOSE_REFERRAL}
          mutationVars={{
            case: id,
            referral: rid,
          }}
          successMessage={REFERRAL_CLOSE_MESSAGE}
          refetchQuery={REFERRAL_QUERY}
          refetchVars={{
            parent: match.params.id,
            id: match.params.rid,
          }}
        />
      )}
      {permittedStates.indexOf("cancelled") !== -1 && (
        <ConfirmationModal
          id="accept"
          title="Cancel Referral"
          message="Are you sure you want to cancel the referral?"
          buttonText="Cancel Referral"
          mutation={CANCEL_REFERRAL}
          mutationVars={{
            case: id,
            referral: rid,
          }}
          successMessage={REFERRAL_CANCEL_MESSAGE}
          refetchQuery={REFERRAL_QUERY}
          refetchVars={{
            parent: match.params.id,
            id: match.params.rid,
          }}
        />
      )}
    </div>
  );
};

const ReferralDetails = (props) => {
  const { match } = props;
  const { loading, error, data } = useQuery(REFERRAL_QUERY, {
    variables: {
      parent: match.params.id,
      id: match.params.rid,
    },
  });
  const loadingOrError = checkForErrors(loading, error, data, "overview");
  if (loadingOrError) {
    return loadingOrError;
  }

  const { referral } = data.viewer;
  const { person, id } = match.params;
  const kind = referral.case && referral.case.kind;

  return (
    <TabPanel id="overview" className="p-3">
      <PanelNavbar
        expand="md"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Can grantRoles={[ROLES.REENTRY]}>
          <a
            href={`/people/${person}/${
              kind === "reentry" ? "reentry" : "matters"
            }/${id}#case-referrals`}
            style={{ fontSize: "1rem" }}
          >
            <FontAwesomeIcon className="backlink-icn" icon="chevron-left" />
            Case Details
          </a>
        </Can>
        <CaseButtons {...props} referral={referral} />
      </PanelNavbar>
      <Card>
        <CardHeader>
          Overview
          <span style={{ diplay: "inline-block", width: "1rem" }} />
          <EditReferralModalForm
            person={person}
            caseId={id}
            referral={referral}
            need={{
              referred: referral.referred.id,
              referredName: referral.referred.name,
              description: referral.description,
            }}
            refetchQuery={REFERRALS_QUERY}
            refetchVars={() => ({
              parent: match.params.person,
              id: match.params.id,
            })}
          >
            <FontAwesomeIcon icon="edit" className="text-danger" />
          </EditReferralModalForm>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Referral ID</Label>
                <strong>{referral.id}</strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Status</Label>
                <strong>{referral.status}</strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Description</Label>
                <strong>{referral.description}</strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Referred</Label>
                <strong>{referral.referred.name}</strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Referring</Label>
                <strong>{referral.referring.name}</strong>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Created By</Label>
                <strong>{referral.createdBy.email}</strong>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </TabPanel>
  );
};

export default ReferralDetails;
