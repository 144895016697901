import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";

import { EN } from "../../../../Constants";
import { DEPARTMENTS } from "../../../../Departments/graphql";
import { QuerySelect } from "../../../../Utils";

const getSelectOptions = (options) =>
  Object.keys(options).reduce((cum, acc) => {
    cum.push({
      value: acc,
      label: options[acc],
    });

    return cum;
  }, []);

const emptyObject = {};
const emptyObject2 = {};

export const MatterFields = ({
  handleChange,
  handleSelectChange,
  kase,
  defaultValue = emptyObject,
  values = emptyObject2,
}) => {
  const representation = values.representation
    ? { value: values.representation, label: values.representation }
    : null;
  const category = values.category
    ? { value: values.category, label: values.category }
    : null;
  const adverseParty = defaultValue.adverseParty
    ? {
        value: defaultValue.adverseParty.id,
        label: defaultValue.adverseParty.name,
      }
    : null;
  const regardingParty = defaultValue.regardingParty
    ? {
        value: defaultValue.regardingParty.id,
        label: defaultValue.regardingParty.name,
      }
    : null;
  const opposingCounsel = defaultValue.opposingCounsel
    ? {
        value: defaultValue.opposingCounsel.id,
        label: defaultValue.opposingCounsel.name,
      }
    : null;
  const primaryCounsel = defaultValue.primaryCounsel
    ? {
        value: defaultValue.primaryCounsel.id,
        label: defaultValue.primaryCounsel.name,
      }
    : null;

  return (
    <>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <Label for="representation">Type of Assistance</Label>
            <Select
              inputId="representation"
              name="representation"
              className="z-index-top"
              placeholder="Search assistance types"
              isClearable
              options={getSelectOptions(EN.MATTER.REPRESENTATION_OPTIONS)}
              onChange={(e) => handleSelectChange("representation", e)}
              defaultValue={representation}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <Label for="category">Type of Issue</Label>
            <Select
              inputId="category"
              name="category"
              className="z-index-top"
              placeholder="Search issue types"
              isClearable
              options={getSelectOptions(EN.MATTER.CATEGORY_OPTIONS)}
              onChange={(e) => handleSelectChange("category", e)}
              defaultValue={category}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <Label for="outsideIdentifier">
              {EN.MATTER.OUTSIDE_IDENTIFIER}
            </Label>
            <Input
              required
              id="outsideIdentifier"
              name="outsideIdentifier"
              placeholder={EN.MATTER.OUTSIDE_IDENTIFIER}
              value={kase ? kase.outsideIdentifier : ""}
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="primaryCounsel">Primary Counsel</Label>
            <QuerySelect
              id="primaryCounsel"
              name="primaryCounsel"
              value="primaryCounsel"
              query={DEPARTMENTS.SELECT_QUERY}
              viewer="departments"
              handleChange={handleSelectChange}
              placeholder="Search Departments"
              isSearchable
              isClearable
              defaultValue={primaryCounsel}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="opposingCounsel">Opposing Counsel</Label>
            <QuerySelect
              id="opposingCounsel"
              name="opposingCounsel"
              value="opposingCounsel"
              query={DEPARTMENTS.SELECT_QUERY}
              viewer="departments"
              handleChange={handleSelectChange}
              placeholder="Search Departments"
              isSearchable
              isClearable
              defaultValue={opposingCounsel}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="adverseParty">Adverse Party</Label>
            <QuerySelect
              id="adverseParty"
              name="adverseParty"
              value="adverseParty"
              query={DEPARTMENTS.SELECT_QUERY}
              viewer="departments"
              handleChange={handleSelectChange}
              placeholder="Search Departments"
              isSearchable
              isClearable
              defaultValue={adverseParty}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="regardingParty">Regarding Party</Label>
            <QuerySelect
              id="regardingParty"
              name="regardingParty"
              value="regardingParty"
              query={DEPARTMENTS.SELECT_QUERY}
              viewer="departments"
              handleChange={handleSelectChange}
              placeholder="Search Departments"
              isSearchable
              isClearable
              defaultValue={regardingParty}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
